import React from "react"
import PropTypes from "prop-types"

import { InstallApp } from "./InstallApp"

export const CalendarActionBar = props => {
  return (
    <div className={"calendar-action-bar"}>
      <InstallApp />
    </div>
  )
}

CalendarActionBar.propTypes = {}
