import React from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"

import { Button, Drawer } from "components"
import { AddAgencyForm } from "./AddAgencyForm"
import { useCountryOptions } from "hooks"
import { ValidationsUtils } from "utils"
import { agenciesAddAgency } from "store/actions"
import { useAgencyCreateTourContext } from "features/agency/contexts"

const AddAgencyComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const countryOptions = useCountryOptions({ visible: props.visible })

  const onDrawerClose = () => {
    props.onClose()
  }

  const handleCloseDrawer = () => {
    if (appTourActive) {
      interruptTour()
    }

    onDrawerClose()
  }

  const handleOpenDrawer = () => {
    props.onOpen()
  }

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    onDrawerClose()
    props.onAddSuccess()

    if (appTourActive) {
      stepComplete(1)
    }
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    dispatch(
      agenciesAddAgency.add({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }
  const onDestroy = () => {
    dispatch(agenciesAddAgency.cleanState())
  }

  const { targetsRefs, appTourActive, stepComplete, interruptTour } =
    useAgencyCreateTourContext()

  const onAdd = () => {
    if (appTourActive) {
      setTimeout(() => {
        stepComplete(0)
      }, 0)
    }

    handleOpenDrawer()
  }

  return (
    <React.Fragment>
      <Button
        leftIcon={"bx bx-plus"}
        title={"btn.add-new"}
        onClick={onAdd}
        innerRef={targetsRefs.createRef}
      />

      <Drawer
        title={t("add_new_agency")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <AddAgencyForm
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              countryOptions={countryOptions}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

AddAgencyComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onAddSuccess: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.agencies.panel.add
  return {
    loading,
    error,
  }
}

export const AddAgency = connect(mapStateToProps)(AddAgencyComponent)
