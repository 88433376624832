import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import * as HOC from "HOC"
import { useConfirmModal, useFetchImage, useSubmitHandler } from "hooks"
import { AvatarPicker } from "./AvatarPicker"
import { connect, useDispatch } from "react-redux"
import {
  modelGeneralRemoveAvatar,
  modelGeneralUpdateAvatar,
  modelGeneralUploadAvatar,
} from "store"
import { ConfirmPopup } from "components"
import { AvatarCropForm } from "./AvatarCropForm"
import { modelHelper } from "helpers"
import { useFashionModelUpdateTourContext } from "features/fashion-model/contexts"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as TOUR_STEP_ALIAS } from "features/fashion-model/consts/tour"

const DataShower = HOC.withDataShower()

export const AvatarManagerComponent = props => {
  const dispatch = useDispatch()
  const {
    fetchImage,
    state: imageState,
    cancelFetch,
    cleanState,
    setLocalImage,
  } = useFetchImage()
  const [newAvatarFile, setNewAvatarFile] = useState(null)
  const [isCropEditMode, setCropEditMode] = useState(false)

  useEffect(() => {
    //Reset state when new image was picked from list
    if (imageState.error) {
      cleanState()
    }
    if (isCropEditMode) {
      setCropEditMode(false)
    }
    return () => {
      dispatch(modelGeneralUploadAvatar.cleanState())
      dispatch(modelGeneralUpdateAvatar.cleanState())
      cancelFetch()
    }
  }, [props.model.avatar])

  useEffect(() => {
    if (props.notCroppedImage) {
      fetchImage(props.notCroppedImage.attachment.src)
      setCropEditMode(true)
    }
  }, [props.notCroppedImage])

  const handleChangeAvatar = ({ avatarDataURL, avatarFileObject }) => {
    setNewAvatarFile(avatarFileObject)
    setLocalImage(avatarDataURL)
    setCropEditMode(true)

    if (appTourActive) {
      stepComplete(TOUR_STEP_ALIAS.AVATAR_UPLOAD)
    }
  }
  const handleEditCropClick = () => {
    fetchImage(props.model.avatar)
    setCropEditMode(true)
  }
  const handleCancelCropForm = () => {
    setCropEditMode(false)
    setNewAvatarFile(null)
    cleanState()
    dispatch(modelGeneralUploadAvatar.cleanState())
    dispatch(modelGeneralUpdateAvatar.cleanState())

    if (props.notCroppedImage) {
      props.removeNotCroppedImage()
    }
  }
  const handleSuccess = () => {
    setCropEditMode(false)
    setNewAvatarFile(null)
    cleanState()

    props.onSuccess()

    if (props.notCroppedImage) {
      props.removeNotCroppedImage()
    }
  }

  const { closeModal, modalRef, showModal, handleConfirm } = useConfirmModal(
    () => {
      dispatch(modelGeneralRemoveAvatar.remove({ id: props.model.id }))
    },
    () => dispatch(modelGeneralRemoveAvatar.cleanState())
  )

  const { handleSubmit } = useSubmitHandler(
    ({ params, onSuccess, onError }) => {
      if (newAvatarFile) {
        dispatch(
          modelGeneralUploadAvatar.upload({
            id: props.model.id,
            params: {
              photo: newAvatarFile,
              is_avatar: true,
              ...params,
            },
            onSuccess,
            onError,
          })
        )
      } else {
        dispatch(
          modelGeneralUpdateAvatar.update({
            attachmentId: props.notCroppedImage
              ? props.notCroppedImage.id
              : props.model.avatar_photo_id,
            params: {
              ...params,
              is_avatar: true,
            },
            onSuccess,
            onError,
          })
        )
      }
    },
    () => handleSuccess()
  )

  const { targetsRefs, appTourActive, runStep, stepComplete } =
    useFashionModelUpdateTourContext()

  useEffect(() => {
    if (appTourActive) {
      runStep(TOUR_STEP_ALIAS.AVATAR_UPLOAD)
    }
  }, [appTourActive])

  return (
    <>
      {isCropEditMode ? (
        <DataShower
          isLoading={imageState.loading}
          isFailed={imageState.error}
          error={imageState.error}
        >
          <AvatarCropForm
            imageDataURL={imageState.imageDataURL}
            initialValues={
              newAvatarFile || props.notCroppedImage
                ? {}
                : modelHelper.photos.getCropFormInitialValues(props.model.crops)
            }
            loading={props.uploadLoading || props.updateLoading}
            error={props.uploadError || props.updateError}
            onSubmit={handleSubmit}
            onCancel={handleCancelCropForm}
            hasCrop={!(newAvatarFile || props.notCroppedImage)}
          />
        </DataShower>
      ) : (
        <div className={"d-flex"}>
          <div ref={targetsRefs.avatarUploadRef}>
            <AvatarPicker
              avatar={props.model.avatar}
              crops={props.model.crops}
              onChangeAvatar={handleChangeAvatar}
              onEditCropClick={handleEditCropClick}
              onRemoveAvatar={showModal}
            />
          </div>
        </div>
      )}
      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"remove-avatar.confirm"}
      />
    </>
  )
}

AvatarManagerComponent.propTypes = {
  model: PropTypes.object,
  uploadLoading: PropTypes.bool,
  uploadError: PropTypes.any,

  updateLoading: PropTypes.bool,
  updateError: PropTypes.any,

  notCroppedImage: PropTypes.object,
  removeNotCroppedImage: PropTypes.func,
  onSuccess: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading: uploadLoading, error: uploadError } =
    state.modelPanel.general.avatar.upload
  const { loading: updateLoading, error: updateError } =
    state.modelPanel.general.avatar.update
  return {
    model: data,
    uploadLoading,
    uploadError,
    updateLoading,
    updateError,
  }
}

export const AvatarManager = connect(mapStateToProps)(AvatarManagerComponent)
