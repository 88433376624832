import moment from "moment"
import "moment/locale/it"
import { langHelper } from "helpers/lang"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import it from "date-fns/locale/it"

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE

class DateService {
  constructor() {
    registerLocale("it", it)
  }

  setLocale = code => {
    let locale = DEFAULT_LANGUAGE_CODE
    const lang = langHelper.general.getLang(code)
    if (lang) {
      locale = lang?.code
    }
    moment.locale(locale)

    setDefaultLocale(code)
  }
}

export const DATE = new DateService()
