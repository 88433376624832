import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

export const IconButton = ({
  type = "button",
  name,
  bg,
  icon,
  color,
  bgColor,
  onClick,
  wrapClass,
  disabled,
  id,
  innerRef,
}) => {
  const handleClick = e => {
    onClick(e)
  }

  const config = () => {
    let data = {
      icon: "",
      color: "",
      bgColor: "",
    }
    switch (name) {
      case "remove":
        data = {
          icon: "bx bx-x",
          color: "text-white",
          bgColor: "bg-danger",
        }
        break

      case "edit":
        data = {
          icon: "bx bxs-pencil font-size-13",
          color: "text-white",
          bgColor: "bg-secondary",
        }
        break

      case "download":
        data = {
          icon: "bx bxs-download font-size-13",
          color: "text-white",
          bgColor: "bg-secondary",
        }
        break

      case "share":
        data = {
          icon: "bx bxs-share-alt font-size-13",
          color: "text-white",
          bgColor: "bg-dark",
        }
        break

      case "view":
        data = {
          icon: "mdi mdi-eye font-size-13",
          color: "text-dark",
          bgColor: "bg-white",
        }
        break

      case "copy":
        data = {
          icon: "fas fa-copy font-size-12",
          color: "text-dark",
          bgColor: "bg-white",
        }
        break

      case "cover":
        data = {
          icon: "bx bx-book-bookmark font-size-16",
          color: "text-secondary",
          bgColor: "bg-white",
        }
        break

      case "mail":
        data = {
          icon: "mdi mdi-email font-size-13",
          color: "text-white",
          bgColor: "bg-dark",
        }
        break

      case "add":
        data = {
          icon: "bx bx-plus font-size-16",
          color: "text-white",
          bgColor: "bg-secondary",
        }
        break

      case "menu":
        data = {
          icon: "bx bx-dots-vertical-rounded",
          color: "text-dark-slate-gray",
          bgColor: "bg-white",
        }
        break
      case "mark":
        data = {
          icon: "bx bxs-bookmark-star",
          color: "text-white",
          bgColor: "bg-primary",
        }
        break
      case "group":
        data = {
          icon: "bx bxs-group",
          color: "text-white",
          bgColor: "bg-secondary",
        }
        break
      case "crop":
        data = {
          icon: "bx bx-crop",
          color: "text-white",
          bgColor: "bg-secondary",
        }
        break

      case "settings":
        data = {
          icon: "bx bx-cog font-size-16",
          color: "text-white",
          bgColor: "bg-secondary",
        }
        break
      case "filter":
        data = {
          icon: "bx bx-filter",
          color: "text-dark-slate-gray",
          bgColor: "bg-white",
        }
        break

      default:
        data = { ...data }
        break
    }
    return data
  }

  return (
    <button
      id={id}
      className={classNames("icon-btn", {
        [color]: color,
        [wrapClass]: wrapClass,
        [bgColor]: bgColor,
        [`icon-btn_${config().color}`]: !color,
        [`icon-btn_${config().bgColor}`]: !bgColor && bg,
      })}
      type={type}
      onClick={handleClick}
      disabled={disabled}
      ref={innerRef}
    >
      <i
        className={classNames("align-middle", {
          [icon]: icon,
          [config().icon]: !icon,
        })}
      />
    </button>
  )
}

IconButton.propTypes = {
  type: PropTypes.string,
  name: PropTypes.oneOf([
    "remove",
    "edit",
    "download",
    "share",
    "view",
    "copy",
    "cover",
    "mail",
    "add",
    "menu",
    "mark",
    "group",
    "crop",
    "settings",
    "filter",
  ]),
  bg: PropTypes.bool,
  icon: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  wrapClass: PropTypes.string,
  bgColor: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  innerRef: PropTypes.any,
}
