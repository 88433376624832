import React from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import moment from "moment"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
  DateInputField,
  FilePickerField,
} from "components"
import { FileUtils, NormalizeUtils, ValidationsUtils } from "utils"
import { commonHelper } from "helpers"

const RATE_VALUES = {
  min: 0,
  max: 1000000,
}

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

const FILE_MAX_SIZE = 16777216 // 16 mb

export const WorkForm = props => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      number: "",
      date_send: null,
      date_sign: null,
      date_expiration: null,
      note: "",
      note_taxes: "",
      ...props.initialValues,
      file: props.initialValues?.attachment
        ? { attachment: props.initialValues.attachment }
        : null,
      reminder: props.initialValues?.reminder
        ? Number(props.initialValues?.reminder)
        : null,
      rate_hint: props.initialValues?.rate_hint
        ? (props.initialValues?.rate_hint).toString()
        : "",
      percentage_agency: props.initialValues?.percentage_agency
        ? (props.initialValues?.percentage_agency).toString()
        : "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        rate_hint: Yup.number()
          .min(RATE_VALUES.min, "field.error.rate_hint.between")
          .max(RATE_VALUES.max, "field.error.rate_hint.between")
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v)),
        percentage_agency: Yup.number()
          .min(
            PERCENT_VALUES.min,
            "field.error.percentage_mother_agency.between"
          )
          .max(
            PERCENT_VALUES.max,
            "field.error.percentage_mother_agency.between"
          )
          .nullable(true)
          .transform((v, o) => (o === "" ? null : v)),
        file: Yup.mixed()
          .test("maxSize", "field.error.file-size.max", value =>
            ValidationsUtils.fileMaxSize(value?.file, FILE_MAX_SIZE)
          ),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let result = {}
    Object.entries(values).forEach(entry => {
      const [key, value] = entry
      result[key] = value === null ? "" : value
    })

    const { file, date_send, date_sign, date_expiration, ...restValues } =
      result
    let params = {
      ...restValues,
      date_send: date_send ? moment(date_send).format("YYYY-MM-DD") : "",
      date_sign: date_sign ? moment(date_sign).format("YYYY-MM-DD") : "",
      date_expiration: date_expiration
        ? moment(date_expiration).format("YYYY-MM-DD")
        : "",
    }

    if (file?.file) {
      params.file = file.file
    } else if (file === "") {
      params.file = ""
    }

    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <h4 className={"mb-3"}>{t("contract")}</h4>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"number"}
              control={control}
              component={InputField}
              id={"number"}
              label={t("contract-number")}
              placeholder={t("contract-number")}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <ControllerHF
              name={"date_send"}
              control={control}
              component={DateInputField}
              id={"date_send"}
              label={t("date_send")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
          <Col md={3}>
            <ControllerHF
              name={"date_sign"}
              control={control}
              component={DateInputField}
              id={"date_sign"}
              label={t("date_sign")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
          <Col md={3}>
            <ControllerHF
              name={"date_expiration"}
              control={control}
              component={DateInputField}
              id={"date_expiration"}
              label={t("date_expiration")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
          <Col md={3}>
            <ControllerHF
              name={"reminder"}
              control={control}
              component={ComboboxFormField}
              id={"reminder"}
              label={t("reminder-date")}
              placeholder={t("reminder-date")}
              options={commonHelper.reminder.getReminderOptions()}
              selectProps={{
                isSearchable: false,
                isClearable: true,
              }}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"note"}
              control={control}
              component={InputField}
              id={"note"}
              label={t("note")}
              placeholder={t("note")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} lg={6} xl={3}>
            <ControllerHF
              name={"file"}
              control={control}
              component={FilePickerField}
              fileNamePlaceholderText={t(
                "file-picker.file-placeholder.contract"
              )}
              id={"file"}
              pickerLabelText={t("upload-contract")}
              onDownload={FileUtils.downloadFileByLink}
              accept={
                "image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              }
              translateParams={{
                text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
              }}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <h4 className={"pt-3 mb-3"}>{t("rates_and_commissions")}</h4>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"rate_hint"}
              control={control}
              component={InputField}
              id={"rate_hint"}
              label={t("rate_hint")}
              placeholder={t("rate_hint")}
              normalize={NormalizeUtils.onlyNumbers}
              translateParams={{
                min: RATE_VALUES.min,
                max: RATE_VALUES.max,
              }}
            />
          </Col>
          <Col md={3}>
            <ControllerHF
              name={"percentage_agency"}
              control={control}
              component={InputField}
              id={"percentage_agency"}
              label={t("percentage_mother_agency")}
              placeholder={t("percentage_mother_agency")}
              normalize={NormalizeUtils.onlyNumbers}
              translateParams={{
                min: PERCENT_VALUES.min,
                max: PERCENT_VALUES.max,
              }}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <h4 className={"pt-3 mb-3"}>{t("taxes_and_professional")}</h4>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"note_taxes"}
              control={control}
              component={InputField}
              id={"note_taxes"}
              label={t("note")}
              placeholder={t("note")}
              type={"textarea"}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

WorkForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
