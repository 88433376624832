import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Container } from "reactstrap"

export const PageContentLayout = ({
  children,
  sidebar = false,
  className,
  sidebarSection = null,
}) => {
  return (
    <React.Fragment>
      <div
        className={classNames("page-content", {
          [className]: className,
          ["page-content_sidebar"]: sidebar,
        })}
      >
        <div
          className={classNames({
            ["page-content__container"]: sidebar,
          })}
        >
          <Container fluid>{children}</Container>
        </div>

        {sidebar && sidebarSection && (
          <div className={"page-content__sidebar"}>{sidebarSection}</div>
        )}
      </div>
    </React.Fragment>
  )
}

PageContentLayout.propTypes = {
  children: PropTypes.object,
  sidebar: PropTypes.bool,
  className: PropTypes.string,
  sidebarSection: PropTypes.any,
}
