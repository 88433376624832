import React, { useState } from "react"
import i18n from "i18n"

// make with useReducer
export const useFetchData = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const fetchData = async options => {
    const { request, onSuccess, onError } = options
    setLoading(true)
    setError(null)

    try {
      const response = await request
      const { data } = response

      setData(data)
      setLoading(false)

      if (onSuccess) {
        onSuccess(data)
      }

      return data
    } catch (error) {
      const errors = error.response?.data?.errors || {}
      const message = error.response?.data?.message || i18n.t("error")

      setError({
        errors,
        message,
      })
      setLoading(false)

      if (onError) {
        onError(errors)
      }
    }
  }

  const clean = () => {
    setLoading(false)
    setData(null)
    setError(null)
  }

  return { loading, data, error, fetchData, clean }
}
