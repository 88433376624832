import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import moment from "moment"

import {
  modelStatementDataPaymentSubmit,
  modelStatementList,
} from "store/actions"
import { useConfirmModal, useDrawer, useModal } from "hooks"
import { StatementCard } from "./StatementCard"
import { PaymentPeriod } from "./PaymentPeriod"
import { ConfirmPopup, StatementPaymentDetails } from "components"
import { APP_ENTITY } from "consts"

const defaultPeriod = [
  moment().startOf("month").toDate(),
  moment().endOf("month").toDate(),
]

const StatementContainerComponent = props => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const [period, setPeriod] = useState(defaultPeriod)
  const [date_start, date_end] = period

  useEffect(() => {
    return () => {
      dispatch(modelStatementList.cleanState())
      dispatch(modelStatementDataPaymentSubmit.cleanState())
    }
  }, [])

  // Fetch data
  useEffect(() => {
    if (date_start && date_end) {
      fetchData()
    }
  }, [period])

  const fetchData = (params = {}) => {
    params = {
      ...params,
      date_start: moment(date_start).format("YYYY-MM-DD"),
      date_end: moment(date_end).format("YYYY-MM-DD"),
    }

    dispatch(modelStatementList.getList({ id: props.model.id, params }))
  }

  const onPeriodChange = value => {
    setPeriod(value)
  }
  // === //

  // Pay
  const paymentModal = useModal()
  // === //

  //======================= Statement (id) payment submit  ==============================//
  const statementPaymentModal = useConfirmModal(
    ({ id, is_paid }) => {
      dispatch(
        modelStatementDataPaymentSubmit.submit({
          id: props.model.id,
          statement: id,
          params: { is_paid: !is_paid },
          onSuccess: fetchData,
        })
      )
    },
    () => {}
  )
  // === //

  // Payment details
  const paymentDetailsDrawer = useDrawer()
  // === //

  const isLoading = useMemo(() => {
    return props.listLoading || props.loading
  }, [props.listLoading, props.loading])

  return (
    <>
      <StatementCard
        period={period}
        onPeriodChange={onPeriodChange}
        list={props.list}
        listLoading={isLoading}
        listError={props.listError}
        meta={props.meta}
        model={props.model}
        onPeriodPay={paymentModal.showModal}
        onStatementPayment={statementPaymentModal.showModal}
        onPaymentDetails={paymentDetailsDrawer.show}
      />

      <PaymentPeriod
        modalRef={paymentModal.modalRef}
        onCancel={paymentModal.closeModal}
        modelId={props.model.id}
        initialPeriod={period}
        onSuccess={fetchData}
      />

      <ConfirmPopup
        ref={statementPaymentModal.modalRef}
        onConfirm={statementPaymentModal.handleConfirm}
        onClosed={statementPaymentModal.closeModal}
        questionText={
          statementPaymentModal.item?.is_paid
            ? "sure_make_refund"
            : "sure_receive_payment"
        }
      />

      <StatementPaymentDetails
        data={paymentDetailsDrawer.data}
        visible={paymentDetailsDrawer.visible}
        onClose={paymentDetailsDrawer.hide}
        onDestroy={paymentDetailsDrawer.resetData}
        entity={APP_ENTITY.MODEL}
      />
    </>
  )
}

StatementContainerComponent.propTypes = {
  model: PropTypes.object,
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { data: model } = state.model.profile
  const { list, listLoading, listError, meta } = state.modelPanel.statement.list

  const { loading } = state.modelPanel.statement.data.payment.submit
  return {
    model,
    list,
    listLoading,
    listError,
    meta,
    loading,
  }
}

export const StatementContainer = connect(mapStateToProps)(
  StatementContainerComponent
)
