import React from "react"
import PropTypes from "prop-types"

import { usePanelLayout } from "hooks"
import { IntroVideoButton } from "./IntroVideoButton"

export const IntroVideo = props => {
  const { onIntroVideoVisible } = usePanelLayout()

  const onOpen = () => {
    onIntroVideoVisible(true)
  }

  return <IntroVideoButton onClick={onOpen} wrapClass={props.btnWrapClass} />
}

IntroVideo.propTypes = {
  btnWrapClass: PropTypes.string,
}
