export const GENERAL_CALENDAR_ENTITY = {
  MODEL: "fashion-model",
  AGENCY: "agency",
  CLIENT: "client",
}

export const EVENTS_CALENDAR_MODE = {
  ADMINISTRATION: "ADMINISTRATION",
}

export const EVENTS_CALENDAR_DEFAULT_CONFIG = {
  mode: null,
  eventsToolbar: true,
  gender: null,
  modelsGenders: null,
  eventFetchParams: null,
}

export const EVENTS_CALENDAR_DEFAULT_EVENTS_FETCH_PARAMS = {
  includes: [
    "times",
    "eventModels.fashionModel",
    "eventClient.client",
    "eventAgency.agency",
    "eventModelsTypes",
    "locations.country",
  ],
}

export const EVENTS_CALENDAR_DEFAULT_EVENT_FETCH_PARAMS = {
  includes: [
    "times",
    "eventModels",
    "eventModels.fashionModel",
    "eventClient",
    "eventClient.client",
    "eventAgency",
    "eventAgency.agency",
    "files",
    "files.attachment",
    "locations",
    "locations.country",
    "alerts",
    "expenses",
    "expenses.currency",
    "eventModelsTypes",
    "department",
    "user",
  ],
}
