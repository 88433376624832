import { takeEvery, fork, put, all } from "redux-saga/effects"

import { userNavigationSocketDataOnUpdate } from "./actions"

import { userNavigationData } from "features/user-navigation/store"

function* onUpdate({ payload: { data } }) {
  const result = data?.data ?? {}
  yield put(userNavigationData.updateData({ ...result }))
}

export function* watchOnUpdate() {
  yield takeEvery(userNavigationSocketDataOnUpdate, onUpdate)
}

function* dataSaga() {
  yield all([fork(watchOnUpdate)])
}

export default dataSaga
