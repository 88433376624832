import { all, fork } from "redux-saga/effects"

import LayoutSaga from "./layout/saga"
import authSagas from "./auth/sagas"
import userSagas from "./user/sagas"
import profileSagas from "./profile/sagas"
import modelsSagas from "./models/sagas"
import modelSagas from "./model/sagas"
import modelPanelSagas from "./modelPanel/sagas"
import agenciesSagas from "./agencies/sagas"
import agencySagas from "./agency/sagas"
import packageSagas from "./package/sagas"
import sharedEntitySagas from "./sharedEntity/sagas"
import clientsSagas from "./clients/sagas"
import clientSagas from "./client/sagas"
import calendarEventSagas from "./calendarEvent/sagas"
import expenseSagas from "./expense/sagas"
import staticSagas from "./static/sagas"
import analyticsSagas from "./analytics/sagas"
import multipleUploadSagas from "./multipleUpload/saga"
import billingSagas from "./billing/sagas"
import socketSagas from "./socket/sagas"
import chatSagas from "./chat/sagas"
import userIdeasSagas from "./userIdeas/sagas"
import socialCalendar from "./socialCalendar/sagas"
import panelSagas from "./panel/sagas"
import importSagas from "./import/sagas"
import departmentSagas from "./department/sagas"
import compcardSagas from "./compcard/sagas"
import iCalSaga from "features/ical/store/sagas"
import userNavigationSaga from "features/user-navigation/store/sagas"

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(authSagas),
    fork(userSagas),
    fork(profileSagas),
    fork(modelsSagas),
    fork(modelSagas),
    fork(modelPanelSagas),
    fork(agenciesSagas),
    fork(agencySagas),
    fork(packageSagas),
    fork(sharedEntitySagas),
    fork(clientsSagas),
    fork(clientSagas),
    fork(calendarEventSagas),
    fork(expenseSagas),
    fork(staticSagas),
    fork(analyticsSagas),
    fork(multipleUploadSagas),
    fork(billingSagas),
    fork(socketSagas),
    fork(chatSagas),
    fork(userIdeasSagas),
    fork(socialCalendar),
    fork(panelSagas),
    fork(importSagas),
    fork(departmentSagas),
    fork(compcardSagas),
    fork(iCalSaga),
    fork(userNavigationSaga),
  ])
}
