import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { createFilter } from "react-select"
import { useDeepCompareEffect } from "react-use"

import { SelectorCombobox } from "components"
import { useFetchData } from "hooks"
import { API } from "api"

export const ModelSelect = ({
  value,
  disabled,
  className,
  gendersValue,
  ...props
}) => {
  const { t } = useTranslation()

  const { fetchData, loading, data, clean } = useFetchData()

  const onFetch = async () => {
    const params = {
      pagination: false,
      filters: {
        genders: gendersValue,
      },
    }
    const request = API.model.getModelList(params)
    await fetchData({
      request,
    })
  }

  useDeepCompareEffect(() => {
    clean()
  }, [gendersValue])

  const filterConfig = {
    matchFrom: "start",
  }

  return (
    <Row>
      <Col md={12}>
        <SelectorCombobox
          value={value}
          options={data?.data}
          onSelect={props.onSelect}
          onFetchOptions={onFetch}
          id={"model-select"}
          label={t("select_models")}
          placeholder={t("select_models")}
          loading={loading}
          getOptionLabel={option =>
            option.name + (option.surname ? ` ${option.surname}` : "")
          }
          getOptionValue={option => `${option.id}`}
          filterOption={createFilter(filterConfig)}
        />
      </Col>
    </Row>
  )
}

ModelSelect.propTypes = {
  value: PropTypes.any,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  gendersValue: PropTypes.array,
}
