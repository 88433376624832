import React, { useEffect, useState } from "react"
import { Alert, Card, CardBody, Col, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { LoginForm } from "./components"
import { useQuery } from "hooks"

const LoginComponent = props => {
  let query = useQuery()
  const history = useHistory()
  const { t } = useTranslation()

  const [alert, setAlert] = useState(null)

  const setAlertMessage = (data = null) => {
    setAlert(data)
  }

  const historyReplace = () => {
    history.replace({
      ...history.location,
      search: "",
    })
  }

  useEffect(() => {
    if (query.get("verified") === "1") {
      setAlertMessage({
        message: t("email_verification.success"),
        type: "success",
      })

      historyReplace()
    }
  }, [])

  useEffect(() => {
    if (query.get("message")) {
      setAlertMessage({
        message: query.get("message"),
        type: query.get("messageType"),
      })

      historyReplace()
    }
  }, [history.location?.search])

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.login"} />

      <Card className="overflow-hidden">
        <div className="">
          <Row>
            <Col>
              <div className="text-white p-4 text-center">
                <h5 className="mb-0">{t("title.login")}</h5>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="p-2">
            {alert?.message && (
              <Alert color={alert?.type || "success"}>{alert.message}</Alert>
            )}

            <LoginForm />

            <div className="mt-4 text-center">
              <Link to="/forgot-password" className="text-muted">
                <i className="mdi mdi-lock me-1" />
                {t("forgot_your_password")}?
              </Link>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="mt-5 text-center">
        <p>
          {t("dont_have_account")}?{" "}
          <Link to="/register" className="fw-medium text-secondary">
            {" "}
            {t("signup_now")}{" "}
          </Link>{" "}
        </p>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent)
