import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"

const initialConfig = {
  introVideo: {
    visible: false,
  },
}

const PanelLayoutContext = createContext({
  config: initialConfig,
  setConfig: () => {},
  updateConfig: (data = {}) => {},
  resetConfig: () => null,
  updateIntroVideoConfig: (data = {}) => {},
})

const PanelLayoutProvider = ({ children }) => {
  const [config, setConfig] = useState(initialConfig)

  const updateConfig = (data = {}) => {
    setConfig(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const resetConfig = () => {
    setConfig(initialConfig)
  }

  const updateIntroVideoConfig = (data = {}) => {
    setConfig(prevState => ({
      ...prevState,
      introVideo: {
        ...data,
      },
    }))
  }

  return (
    <PanelLayoutContext.Provider
      value={{
        config,
        setConfig,
        updateConfig,
        resetConfig,
        updateIntroVideoConfig,
      }}
    >
      {children}
    </PanelLayoutContext.Provider>
  )
}

PanelLayoutProvider.propTypes = {
  children: PropTypes.any,
}

const usePanelLayoutContext = () => useContext(PanelLayoutContext)

export { PanelLayoutProvider, usePanelLayoutContext }
