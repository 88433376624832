import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { useEcho } from "hooks"
import {
  socketGeneral,
  socketOnNotificationReceive,
  socketOnSubscriptionUpdate,
  socketOnChatMessage,
  socketOnChatGroupCreated,
  socketOnChatGroupUserAdded,
  socketOnUserLogin,
  socketOnBillingInfoVatIdStatusUpdate,
  socketOnClientStatementAlert,
  socketOnSocialPostUpdate,
  socketOnImportDataUpdate,
} from "store/actions"
import { userNavigationSocketDataOnUpdate } from "features/user-navigation/store"
import { ECHO_EVENT } from "consts"

export const SocketMiddleware = props => {
  const echo = useEcho()

  const history = useHistory()

  const dispatch = useDispatch()
  const { data: user } = useSelector(state => state.user.profile)

  useEffect(() => {
    echo.create()

    if (user?.company?.id) {
      echo.privateChannelAddListeners(`company.${user?.company?.id}`, {
        [ECHO_EVENT.UPDATE_SUBSCRIPTION_EVENT]: data =>
          dispatch(socketOnSubscriptionUpdate(data)),

        // on billing vat id status changed
        [ECHO_EVENT.UPDATE_TAX_ID_EVENT]: data =>
          dispatch(socketOnBillingInfoVatIdStatusUpdate(data)),

        [ECHO_EVENT.CALENDAR_POST_EVENT]: data =>
          dispatch(socketOnSocialPostUpdate(data)),
      })

      echo.privateChannelAddListeners(`company.${user?.company?.id}`, {
        // on client statement alert received
        [ECHO_EVENT.CLIENT_STATEMENT_ALERT_EVENT]: data =>
          dispatch(socketOnClientStatementAlert(data)),
      })
    }
    if (user?.id) {
      echo.privateChannelAddListeners(`user.${user.id}`, {
        // on chat received message
        [ECHO_EVENT.CHAT_MESSAGE_EVENT]: data =>
          dispatch(socketOnChatMessage(data)),

        // on group chat created
        [ECHO_EVENT.CHAT_GROUP_CREATED_EVENT]: data =>
          dispatch(socketOnChatGroupCreated(data)),

        // on group chat user added
        [ECHO_EVENT.CHAT_GROUP_ADDED_FOR_USER_EVENT]: data =>
          dispatch(socketOnChatGroupUserAdded(data)),

        // when user session expired and this user login via other device
        [ECHO_EVENT.LOGIN_USER_EVENT]: data =>
          dispatch(socketOnUserLogin({ data, history })),

        // on import updated
        [ECHO_EVENT.UPDATE_IMPORTER_EVENT]: data =>
          dispatch(socketOnImportDataUpdate(data)),

        // on user navigation updated (completion steps)
        [ECHO_EVENT.UPDATE_USER_NAVIGATION_EVENT]: data =>
          dispatch(userNavigationSocketDataOnUpdate({ data })),
      })

      echo.privateChannelNotification(`user.${user?.id}`, data => {
        dispatch(socketOnNotificationReceive(data))
      })
    }

    return () => {
      echo.channelLeaveAll()

      echo.destroy()

      dispatch(socketGeneral.resetState({}))
    }
  }, [])

  return props.children
}
