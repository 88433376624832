import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import {
  BookPhotosCarouselShower,
  OverlayBlockLoading,
  PackageDataTabs,
} from "../../../../components"
import { BooksTab } from "./BooksTab"
import { VideosTab } from "./VideosTab"
import { DrawerAdd } from "./DrawerAdd"
import { AddBooksContainer } from "./AddBooksContainer"
import { AddVideosContainer } from "./AddVideosContainer"
import { usePackageDataPicker } from "../../../../hooks"
import { packageDataPickerCleanValue } from "store/actions"

const BOOKS_TAB = "books"
const VIDEOS_TAB = "videos"

const PackageDataPickerContainerComponent = props => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(BOOKS_TAB)
  const playersRef = useRef([])
  const [drawerAddVisible, setDrawerAddVisible] = useState(null)

  const { pickedBooks, onBookRemove, pickedVideos, onVideoRemove } =
    usePackageDataPicker()

  useEffect(() => {
    return () => {
      props.packageDataPickerCleanValue()
    }
  }, [])

  const onPickedBookRemove = book => {
    onBookRemove(book)
  }

  const onPickedVideoRemove = video => {
    onVideoRemove(video)
  }

  const onAdd = e => {
    e.preventDefault()
    if (activeTab === VIDEOS_TAB) {
      pausePlayers()
    }
    setDrawerAddVisible(true)
  }

  useEffect(() => {
    return () => {
      if (activeTab === VIDEOS_TAB) {
        pausePlayers()
      }
    }
  }, [activeTab])

  const pausePlayers = () => {
    if (playersRef.current) {
      playersRef.current.forEach(player => {
        if (player && player?.pause) {
          player.pause()
        }
      })
    }
  }

  // Show book photos
  const [photosBookId, setPhotosBookId] = useState(null)
  const showBookPhotos = item => {
    if (item?.pages_count) {
      setPhotosBookId(item.id)
    }
  }
  // --- //

  return (
    <div>
      <OverlayBlockLoading isLoading={props.bookDataLoading}>
        <PackageDataTabs
          activeTab={activeTab}
          onTabChange={tab => setActiveTab(tab)}
          booksTab={
            <BooksTab
              books={pickedBooks}
              onRemove={onPickedBookRemove}
              onCoverClick={showBookPhotos}
            />
          }
          videosTab={
            <VideosTab
              videos={pickedVideos}
              onRemove={onPickedVideoRemove}
              playersRef={playersRef}
            />
          }
          headerEnd={
            <Link to="#" className="text-secondary" onClick={onAdd}>
              {t(activeTab === BOOKS_TAB ? "btn.add-books" : "btn.add-videos")}
            </Link>
          }
        />
      </OverlayBlockLoading>

      <DrawerAdd
        title={t(activeTab === BOOKS_TAB ? "add-books" : "add-videos")}
        visible={drawerAddVisible}
        onClose={() => setDrawerAddVisible(null)}
      >
        {activeTab === BOOKS_TAB ? (
          <AddBooksContainer />
        ) : (
          <AddVideosContainer />
        )}
      </DrawerAdd>

      <BookPhotosCarouselShower
        id={photosBookId}
        onDestroy={() => setPhotosBookId(null)}
      />
    </div>
  )
}

PackageDataPickerContainerComponent.propTypes = {
  packageDataPickerCleanValue: PropTypes.func,

  bookDataLoading: PropTypes.bool,
}

const mapStateToProps = state => {
  return {
    // for book photos carousel
    bookDataLoading: state.modelPanel.book.common.book.loading,
  }
}

const mapDispatchToProps = {
  packageDataPickerCleanValue,
}

export const PackageDataPickerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageDataPickerContainerComponent)
