import React from "react"
import PropTypes from "prop-types"

import { Header } from "./Topbar"
import { Sidebar } from "./Sidebar"
import {
  PanelTopBarProvider,
  PanelSidebarProvider,
} from "../../../../../contexts"
import { PageContentLayout } from "components/Layouts"
import { usePagePanelLayoutContext } from "contexts"

export const ModelPanelLayout = props => {
  const { options } = usePagePanelLayoutContext()

  return (
    <PanelSidebarProvider>
      <PanelTopBarProvider>
        <PageContentLayout
          sidebar={options?.sidebar}
          sidebarSection={<Sidebar />}
        >
          <>
            <Header />

            <div>{props.children}</div>
          </>
        </PageContentLayout>
      </PanelTopBarProvider>
    </PanelSidebarProvider>
  )
}

ModelPanelLayout.propTypes = {
  children: PropTypes.any,
  topBarOptions: PropTypes.object,
}
