import axios from "axios"

class TranslationAPI {
  getTranslationsSingle = async params => {
    return axios.get(`api/translations/single`, {
      params,
    })
  }
}

export const translation = new TranslationAPI()
