import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import moment from "moment"
import {
  IconButton,
  InfoCard,
  InfoCardListItem,
  InTownBadge,
  Image,
} from "components"
import { commonHelper, modelHelper } from "helpers"
import { ModelUtils } from "utils"
import placeholder from "assets/images/dev/avatar-placeholder.png"
import { capitalize } from "lodash"
import { useMedia } from "react-use"
import placeholderSquare from "assets/images/dev/avatar-placeholder-square.svg"
import { IMAGE_CROP_TYPE } from "consts"
import { useFashionModelUpdateTourContext } from "features/fashion-model/contexts"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as TOUR_STEP_ALIAS } from "features/fashion-model/consts/tour"

const { RECTANGLE, SQUARE } = IMAGE_CROP_TYPE

export const ProfileInfoCard = props => {
  const { t } = useTranslation()
  const isSmallScreen = useMedia("(max-width: 575px)")
  const getWebsiteValue = value => {
    return (
      <a
        href={value}
        target={"_blank"}
        rel="noreferrer"
        className={"text-primary-green"}
      >
        {t("open")}
      </a>
    )
  }
  const getGroupsValue = value => {
    if (!value.length) return null
    return value.map(group => {
      return (
        <span className={"info-list__info_group"} key={group.id}>
          {group.name}
        </span>
      )
    })
  }

  const { targetsRefs, appTourActive, stepComplete } =
    useFashionModelUpdateTourContext()

  const onAvatarEdit = () => {
    if (appTourActive) {
      setTimeout(() => {
        stepComplete(TOUR_STEP_ALIAS.AVATAR_EDIT)
      }, 0)
    }

    props.onAvatarEdit()
  }

  const onEdit = () => {
    if (appTourActive) {
      setTimeout(() => {
        stepComplete(TOUR_STEP_ALIAS.PROFILE_EDIT)
      }, 0)
    }

    props.onEdit()
  }

  return (
    <InfoCard
      editInnerRef={targetsRefs.profileEditRef}
      onEdit={onEdit}
      editMode
      className={"h-100 model-general-info"}
    >
      <div className={"d-flex flex-wrap model-general-info__wrap"}>
        <div className="model-general-info__avatar-block">
          <Image
            src={
              props.data.avatar ||
              (isSmallScreen ? placeholderSquare : placeholder)
            }
            type={isSmallScreen ? SQUARE : RECTANGLE}
            crops={props.data.crops}
            alt={props.data.name}
          />
          <div className={"user-photo-avatar__edit-block"}>
            <IconButton
              name={"edit"}
              onClick={onAvatarEdit}
              bg
              innerRef={targetsRefs.avatarEditRef}
            />
          </div>
        </div>
        <div className="info-list__block">
          <InfoCardListItem value={props.data.name} label={t("name")} />
          <InfoCardListItem value={props.data.surname} label={t("surname")} />
          <InfoCardListItem
            value={props.data.gender}
            label={t("gender")}
            normalize={value => modelHelper.general.getGender(value).label}
          />
          <InfoCardListItem
            value={<InTownBadge status={props.data.is_in_town} labelMode />}
            label={t("status")}
          />
          <InfoCardListItem
            value={props.data.birthday}
            label={t("age")}
            normalize={value => {
              return `${ModelUtils.getAge(value)} ${t("years-symbol")}`
            }}
          />
          <InfoCardListItem
            value={props.data.birthday}
            label={t("birthday")}
            normalize={value => moment(value).format("DD/MM/YYYY")}
          />
          <InfoCardListItem
            value={props.data.birthdayplace}
            label={t("birthplace")}
          />
        </div>

        <div className="info-list__block">
          <InfoCardListItem
            value={props.data.nationality?.name}
            label={t("nationality")}
          />

          <InfoCardListItem
            value={props.data.is_eu}
            label={t("eu")}
            normalize={commonHelper.format.booleanToName}
          />
          <InfoCardListItem
            value={props.data.residence?.name}
            label={t("residence_country")}
          />
          <InfoCardListItem
            value={props.data?.ethnic}
            label={t("ethnic_category")}
            normalize={value =>
              modelHelper.general.getEthnicCategory(value)?.label
            }
          />
          <InfoCardListItem
            value={props.data.influencer}
            label={t("influencer")}
            normalize={commonHelper.format.booleanToName}
          />
          <InfoCardListItem
            value={props.data.plus_size}
            label={t("plus_size")}
            normalize={commonHelper.format.booleanToName}
          />
          <InfoCardListItem
            value={props.data.in_agency_since}
            label={t("in_agency_from")}
            normalize={value => moment(value).format("DD/MM/YYYY")}
          />
          <InfoCardListItem
            value={props.data.booker?.name}
            label={t("booker")}
          />
          {props.data.alert && (
            <InfoCardListItem
              value={props.data.alert}
              label={t("notes")}
              className={"info-list__item_red-highlight"}
            />
          )}
          {props.data.website && props.data.website !== "" && (
            <InfoCardListItem
              value={props.data.website}
              label={t("website")}
              normalize={getWebsiteValue}
            />
          )}
          <InfoCardListItem
            value={props.data?.groups}
            label={t("groups")}
            normalize={getGroupsValue}
          />
        </div>
        <div className="info-list__block">
          <InfoCardListItem
            value={props.data.bank_name}
            label={t("bank-name")}
          />
          <InfoCardListItem
            value={props.data.bank_account_number}
            label={t("bank-account-number")}
          />
          <InfoCardListItem
            value={props.data.swift_code}
            label={t("swift-code")}
          />
          <InfoCardListItem
            value={props.data.tax_residence}
            label={t("tax-residence")}
            normalize={value => capitalize(value)}
          />
          <InfoCardListItem value={props.data.vat} label={t("vat")} />
        </div>
      </div>
    </InfoCard>
  )
}

ProfileInfoCard.propTypes = {
  data: PropTypes.object,
  onEdit: PropTypes.func,
  onAvatarEdit: PropTypes.func,
}
