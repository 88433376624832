import React, { useMemo } from "react"
import { langHelper } from "helpers/lang"
import PropTypes from "prop-types"
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import classNames from "classnames"

export const LangPicker = ({
  value,
  onChange,
  className,
  btnProps,
  dropdownMenuProps,
  ...props
}) => {
  const languages = langHelper.general.getLanguages()

  const langData = useMemo(() => {
    return value ? langHelper.general.getLang(value) : null
  }, [value])

  const onLangChange = code => {
    if (code !== value) {
      onChange && onChange(code)
    }
  }

  return (
    <UncontrolledDropdown className="d-inline-block">
      <DropdownToggle className="btn" tag="button" type={"button"}>
        <img
          src={langData?.flag}
          alt={langData?.code}
          style={{ height: "16px" }}
          className="me-2"
        />

        <span className="text-uppercase">{langData?.code}</span>
      </DropdownToggle>

      <DropdownMenu
        className={classNames("language-switch", {
          [dropdownMenuProps?.className]: dropdownMenuProps?.className,
        })}
      >
        {languages.map(item => (
          <DropdownItem
            key={item.code}
            icon={
              <img src={item.flag} alt={item.code} style={{ height: "16px" }} />
            }
            onClick={() => onLangChange(item.code)}
            className={`notify-item ${item.code === value ? "active" : "none"}`}
          >
            {item.title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

LangPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  btnProps: PropTypes.any,
  dropdownMenuProps: PropTypes.any,
}
