import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

export const Information = props => {
  const { t } = useTranslation()

  const { contact_information, client_information, client_vat } = props.data

  return (
    <div className="invoice-card-info">
      <div className="invoice-card-info__contact-block">
        <div className="invoice-card-info__contact-info">
          {contact_information}
        </div>
      </div>
      <div className="invoice-card-info__client-block">
        <div className="invoice-card-info__client-info card-block">
          {client_information}
        </div>

        <div className="d-flex invoice-card-info__client-vat card-block">
          <div className={"me-1"}>{t("vat-c_f")}</div>
          <div>{client_vat}</div>
        </div>
      </div>
    </div>
  )
}

Information.propTypes = {
  data: PropTypes.object,
}
