import * as eventConfig from "./eventConfig"
import * as calendar from "./calendar"
import * as eventForm from "./eventForm"
import * as eventInfo from "./eventInfo"

export const eventsCalendarHelper = {
  eventConfig,
  calendar,
  eventForm,
  eventInfo,
}
