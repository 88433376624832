import React, { useEffect, useMemo, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ACTIONS, EVENTS } from "react-joyride"

import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { useAppTourState } from "features/app-tour/hooks"
import { useTour } from "features/tour-ui/hooks"
import { useTargets } from "./useTargets"
import { useSelector } from "react-redux"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as STEP_ALIAS } from "features/fashion-model/consts/tour"

const {
  AVATAR_EDIT,
  AVATAR_UPLOAD,
  PROFILE_EDIT,
  PROFILE_EDIT_FORM,
  CONTACTS_EDIT,
  CONTACTS_FORM_PHONE,
  CONTACTS_FORM_EMAIL,
  CONTACTS_FORM_SAVE,
  MEASUREMENTS_EDIT,
  MEASUREMENTS_FORM_PRIMARY_SECTION,
  MEASUREMENTS_FORM_SECONDARY_SECTION,
  MEASUREMENTS_FORM_SAVE,
  FINISHED,
} = STEP_ALIAS

const { FASHION_MODEL_UPDATE } = APP_TOUR_ALIAS
const alias = FASHION_MODEL_UPDATE

export const useFashionModelUpdateTour = ({}) => {
  const { t } = useTranslation()

  const { data: model } = useSelector(state => state.model.profile)

  const [stepsState, setStepState] = useState({
    step1: false,
  })

  const onStepsStateUpdate = (data = {}) => {
    setStepState(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const { finishTour, state: appTourState } = useAppTourState()

  const onFinishAppTour = () => {
    finishTour()
  }

  const tour = useTour({
    onFinished: onFinishAppTour,
    onSkipped: onFinishAppTour,
  })

  const { initTour, onSetSteps, state, onRun, onStop, onStepIndexChange } = tour

  const { stepIndex } = state

  const appTourActive = useMemo(() => {
    return appTourState.active && appTourState.alias === alias
  }, [appTourState.active, appTourState.alias])

  const { targets, targetsRefs } = useTargets({
    appTourActive,
    onStepsStateUpdate,
  })

  const callback = (data = {}) => {
    tour.handleCallback(data)

    const { action, type } = data

    const stepsLength = state.steps.length

    if ([EVENTS.STEP_AFTER].includes(type) && action === ACTIONS.NEXT) {
      if (stepIndex !== stepsLength - 1) {
        const nextStepIndex = stepIndex + 1

        onStepIndexChange(nextStepIndex)
      }
    } else if (action === ACTIONS.CLOSE && stepIndex === stepsLength - 1) {
      successCompleteTour()
    }
  }

  const defaultStepOptions = {
    disableBeacon: true,
    disableOverlayClose: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    hideBackButton: true,
    hideNextButton: true,
    hideCloseButton: true,
  }

  const defaultContactsStepOptions = {
    ...defaultStepOptions,
    title: t("contacts_&_social"),
    placementBeacon: "left",
  }

  const defaultMeasurementsStepOptions = {
    ...defaultStepOptions,
    title: t("measurements_and_features"),
    placementBeacon: "left",
  }

  const getInitialSteps = useCallback(() => {
    if (appTourActive) {
      return [
        {
          alias: AVATAR_EDIT,
          ...defaultStepOptions,
          title: t("avatar"),
          target: targets.avatarEditTargetRef.current || "body",
          content: t("app_tour.model_update.avatar_edit.content"),
          spotlightClicks: true,
          disableScrolling: true,
        },
        {
          alias: AVATAR_UPLOAD,
          ...defaultStepOptions,
          title: t("avatar"),
          target: targets.avatarUploadTargetRef.current || "body",
          content: t("app_tour.model_update.avatar_upload.content"),
          spotlightClicks: true,
          placement: "right",
        },
        {
          alias: PROFILE_EDIT,
          ...defaultStepOptions,
          title: t("profile"),
          target: targets.profileEditTargetRef.current || "body",
          content: t("app_tour.model_update.profile_edit.edit.content"),
          spotlightClicks: true,
          disableScrolling: true,
        },
        {
          alias: PROFILE_EDIT_FORM,
          ...defaultStepOptions,
          title: t("profile"),
          target: targets.profileEditFormTargetRef.current || "body",
          content: t("app_tour.model_update.profile_edit.form.content"),
          placement: undefined,
          disableBeacon: false,
          placementBeacon: "left",
          hideCloseButton: false,
          spotlightClicks: true,
        },
        {
          alias: CONTACTS_EDIT,
          ...defaultContactsStepOptions,
          target: targets.contactsEditTargetRef.current || "body",
          content: t("app_tour.model_update.contacts_edit.content"),
          spotlightClicks: true,
          disableScrolling: true,
        },
        {
          alias: CONTACTS_FORM_PHONE,
          ...defaultContactsStepOptions,
          target: targets.contactsFormPhoneTargetRef.current || "body",
          content: t("app_tour.model_update.contacts_form.phone.content"),
          disableBeacon: false,
          hideCloseButton: false,
          hideNextButton: false,
          spotlightClicks: true,
        },
        {
          alias: CONTACTS_FORM_EMAIL,
          ...defaultContactsStepOptions,
          target: targets.contactsFormEmailTargetRef.current || "body",
          content: t("app_tour.model_update.contacts_form.email.content"),
          disableBeacon: false,
          hideCloseButton: false,
          hideNextButton: false,
          spotlightClicks: true,
        },
        {
          alias: CONTACTS_FORM_SAVE,
          ...defaultContactsStepOptions,
          target: targets.contactsFormSaveTargetRef.current || "body",
          content: t("app_tour.model_update.contacts_form.save.content"),
          placement: "top",
          disableBeacon: false,
          hideCloseButton: false,
          spotlightClicks: true,
        },
        {
          alias: MEASUREMENTS_EDIT,
          ...defaultMeasurementsStepOptions,
          target: targets.measurementsEditTargetRef.current || "body",
          content: t("app_tour.model_update.measurements_edit.content"),
          spotlightClicks: true,
          disableScrolling: true,
        },
        {
          alias: MEASUREMENTS_FORM_PRIMARY_SECTION,
          ...defaultMeasurementsStepOptions,
          target:
            targets.measurementsPrimaryFormSectionTargetRef.current || "body",
          content: t(
            "app_tour.model_update.measurements_form.primary_section.content"
          ),
          disableBeacon: false,
          hideCloseButton: false,
          hideNextButton: false,
          spotlightClicks: true,
        },
        {
          alias: MEASUREMENTS_FORM_SECONDARY_SECTION,
          ...defaultMeasurementsStepOptions,
          target:
            targets.measurementsSecondaryFormSectionTargetRef.current || "body",
          content: t(
            "app_tour.model_update.measurements_form.secondary_section.content"
          ),
          disableBeacon: false,
          hideCloseButton: false,
          hideNextButton: false,
          spotlightClicks: true,
        },
        {
          alias: MEASUREMENTS_FORM_SAVE,
          ...defaultMeasurementsStepOptions,
          target: targets.measurementsFormSaveTargetRef.current || "body",
          content: t("app_tour.model_update.measurements_form.save.content"),
          disableBeacon: false,
          hideCloseButton: false,
          spotlightClicks: true,
        },
        {
          alias: FINISHED,
          ...defaultStepOptions,
          target: "body",
          title: t("model"),
          content: t("finished"),
          placement: "center",
          hideCloseButton: false,
        },
      ]
    }
  }, [appTourActive, stepsState])

  const withAvatar = useMemo(() => {
    return !!model?.avatar
  }, [model?.avatar])

  const getStepIndexByAlias = alias => {
    const steps = getInitialSteps()
    return steps.findIndex(item => item.alias === alias) || 0
  }

  // Init tour
  useEffect(() => {
    if (appTourActive && stepsState.step1) {
      const initialStep = getStepIndexByAlias(
        withAvatar ? PROFILE_EDIT : AVATAR_EDIT
      )

      initTour({
        steps: getInitialSteps(),
        stepIndex: initialStep,
      })
    }
  }, [appTourActive, stepsState.step1, withAvatar])

  useEffect(() => {
    return () => {
      if (appTourActive) {
        interruptTour()
      }
    }
  }, [appTourActive])

  const interruptTour = () => {
    tour.completeTour()

    onFinishAppTour()
  }

  const successCompleteTour = () => {
    tour.completeTour()

    onFinishAppTour()
  }

  const changeStep = alias => {
    const stepIndex = getStepIndexByAlias(alias)
    onStepIndexChange(stepIndex)
  }

  const stepComplete = alias => {
    if (alias === AVATAR_EDIT) {
      onStop()
      changeStep(AVATAR_UPLOAD)
    } else if (alias === AVATAR_UPLOAD) {
      onStop()
    } else if (alias === PROFILE_EDIT) {
      onStop()
      changeStep(PROFILE_EDIT_FORM)
    } else if (alias === PROFILE_EDIT_FORM) {
      onStop()
      changeStep(CONTACTS_EDIT)
      runStep(CONTACTS_EDIT)
    } else if (alias === CONTACTS_EDIT) {
      onStop()
      changeStep(CONTACTS_FORM_PHONE)
    } else if (alias === CONTACTS_FORM_SAVE) {
      onStop()
      changeStep(MEASUREMENTS_EDIT)
      runStep(MEASUREMENTS_EDIT)
    } else if (alias === MEASUREMENTS_EDIT) {
      onStop()
      changeStep(MEASUREMENTS_FORM_PRIMARY_SECTION)
    } else if (alias === MEASUREMENTS_FORM_SAVE) {
      changeStep(FINISHED)
    }
  }

  const runStep = alias => {
    if (appTourActive) {
      if (alias === AVATAR_UPLOAD) {
        onSetSteps(getInitialSteps())
        setTimeout(() => {
          onRun()
        }, 500)
      } else if (alias === PROFILE_EDIT) {
        setTimeout(() => {
          changeStep(PROFILE_EDIT)
          onRun()
        }, 500)
      }
      if (alias === PROFILE_EDIT_FORM) {
        onSetSteps(getInitialSteps())
        setTimeout(() => {
          onRun()
        }, 500)
      } else if (alias === CONTACTS_EDIT) {
        onRun()
      } else if (alias === CONTACTS_FORM_PHONE) {
        setTimeout(() => {
          onSetSteps(getInitialSteps())
          onRun()
        }, 500)
      } else if (alias === MEASUREMENTS_EDIT) {
        onRun()
      } else if (alias === MEASUREMENTS_FORM_PRIMARY_SECTION) {
        setTimeout(() => {
          onSetSteps(getInitialSteps())
          onRun()
        }, 500)
      }
    }
  }

  return {
    tour,
    targetsRefs,
    targets,
    callback,
    interruptTour,
    successCompleteTour,
    appTourActive,
    stepComplete,
    runStep,
  }
}

useFashionModelUpdateTour.propTypes = {}
