import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { IntroVideo as IntroVideoModal } from "features/panel/components/IntroVideo"
import { usePanelLayout } from "hooks"

export const IntroVideo = () => {
  const { t } = useTranslation()

  const { config, onIntroVideoVisible } = usePanelLayout()

  const { introVideo } = config

  const onClose = () => onIntroVideoVisible(false)

  return <IntroVideoModal visible={introVideo?.visible} onClose={onClose} />
}

IntroVideo.propTypes = {}
