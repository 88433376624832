import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { EVENT_TYPE_CASTING, EVENT_TYPE_JOB } from "consts"
import { eventsCalendarHelper } from "helpers"

export const Notes = () => {
  const { t } = useTranslation()

  const { getEventConfig } = eventsCalendarHelper.eventConfig

  const castingTitle = getEventConfig(EVENT_TYPE_CASTING).title
  const jobTitle = getEventConfig(EVENT_TYPE_JOB).title

  const items = [
    t("sync_to_calendar.notes.1", { text: `${castingTitle}, ${jobTitle}` }),
    t("sync_to_calendar.notes.2"),
    t("sync_to_calendar.notes.3"),
  ]

  return (
    <div>
      <ul>
        {items.map((item, index) => (
          <li className={"py-1"} key={index}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

Notes.propTypes = {}
