import React, { useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { MegaTagTitle, BookPhotosPicker } from "components"
import { usePagePanelLayoutEffect, usePanelSidebar } from "hooks"
import { MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR } from "consts"
import {
  PhotosContainer,
  BookForm,
  BookCopyData,
} from "../components/BookComponents"
import { ValidationsUtils } from "utils"
import {
  modelAddBook,
  modelAddBookCleanState,
  modelBookPhotosPickerCleanValue,
} from "store/actions"
import { useFashionModelBookTourContext } from "features/fashion-model/contexts"

const BookAddComponent = props => {
  const history = useHistory()
  const { id } = useParams()
  const location = useLocation()
  const { t } = useTranslation()

  const copyBookId = location.state?.copyBookId

  const pagePanelLayoutOptions = useMemo(() => {
    return {
      sidebar: true,
    }
  }, [])

  usePagePanelLayoutEffect(pagePanelLayoutOptions)

  const panelSideBarOptions = useMemo(() => {
    return {
      active: MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR,
      extra: { mode: "add" },
    }
  }, [])

  usePanelSidebar(panelSideBarOptions)

  useEffect(() => {
    return () => {
      props.modelAddBookCleanState()
      props.modelBookPhotosPickerCleanValue()
    }
  }, [])

  const { targets, interruptTour, appTourActive } =
    useFashionModelBookTourContext()

  useEffect(() => {
    return () => {
      if (appTourActive) {
        interruptTour()
      }
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()

    history.push(`/models/${id}/book`)
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelAddBook(
      props.model.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  const renderContent = book => {
    let initialValues = {}

    if (book) {
      initialValues = {
        title: `${props.book?.title} ${t("copy")}`,
        description: props.book?.description,
        published: props.book?.published,
      }
    }

    return (
      <>
        <Row>
          <Col>
            <BookForm
              initialValues={initialValues}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              picked={props.picked}
            />
          </Col>
        </Row>

        <Row className={"mt-4 pt-4"}>
          <div ref={targets.bookFormPhotosTargetRef}>
            <BookPhotosPicker component={PhotosContainer} />
          </div>
        </Row>
      </>
    )
  }

  return (
    <div className={"model-panel-book-add"}>
      <MegaTagTitle title={"title.model.book-add"} />

      {copyBookId ? (
        <BookCopyData id={copyBookId}>
          {props.book && renderContent(props.book)}
        </BookCopyData>
      ) : (
        renderContent()
      )}
    </div>
  )
}

BookAddComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  model: PropTypes.object,
  picked: PropTypes.array,
  book: PropTypes.object,

  modelAddBook: PropTypes.func,
  modelAddBookCleanState: PropTypes.func,
  modelBookPhotosPickerCleanValue: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error } = state.modelPanel.book.add
  const { picked } = state.modelPanel.book.photosPicker
  return {
    model: state.model.profile.data,
    loading,
    error,
    picked,
    book: state.modelPanel.book.details.data.data,
  }
}

const mapDispatchToProps = {
  modelAddBook,
  modelAddBookCleanState,
  modelBookPhotosPickerCleanValue,
}

export const BookAdd = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookAddComponent)
