import {
    PACKAGE_DATA_PICKER_SET_VALUE,
    PACKAGE_DATA_PICKER_CLEAN_VALUE,
    PACKAGE_DATA_PICKER_TOGGLE_BOOK,
    PACKAGE_DATA_PICKER_REMOVE_BOOK,
    PACKAGE_DATA_PICKER_TOGGLE_VIDEO,
    PACKAGE_DATA_PICKER_REMOVE_VIDEO,
} from './actionTypes';

const initialState = {
    books: [],
    videos: [],
};

const dataPicker = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGE_DATA_PICKER_SET_VALUE:
            state = {
                ...state,
                books: action.payload.books,
                videos: action.payload.videos,
            };
            break;

        case PACKAGE_DATA_PICKER_CLEAN_VALUE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case PACKAGE_DATA_PICKER_TOGGLE_BOOK:
            const book = action.payload;
            let pickedBooks;
            const filteredBooks = state.books.filter(item => (item.id !== book.id ) );
            if (state.books.length === filteredBooks.length) {
                pickedBooks = [...state.books, book];
            } else {
                pickedBooks = filteredBooks;
            }

            state = {
                ...state,
                books: [...pickedBooks],
            };
            break;

        case PACKAGE_DATA_PICKER_REMOVE_BOOK:
            let items = state.books.filter(item => item.id !== action.payload.id);

            state = {
                ...state,
                books: [...items],
            };
            break;

        case PACKAGE_DATA_PICKER_TOGGLE_VIDEO:
            const video = action.payload;
            let pickedVideos;
            const filteredVideos = state.videos.filter(item => (item.id !== video.id ) );
            if (state.videos.length === filteredVideos.length) {
                pickedVideos = [...state.videos, video];
            } else {
                pickedVideos = filteredVideos;
            }
            state = {
                ...state,
                videos: [...pickedVideos],
            };
            break;

        case PACKAGE_DATA_PICKER_REMOVE_VIDEO:
            let videosItems = state.videos.filter(item => item.id !== action.payload.id);

            state = {
                ...state,
                videos: [...videosItems],
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default dataPicker;