import React, { useState } from "react"
import { STATUS } from "react-joyride"
import PropTypes from "prop-types"

const defaultState = {
  run: false,
  stepIndex: 0,
  steps: [],
}

export const useTour = (
  { initialState = {}, onFinished, onSkipped } = null
) => {
  const [state, setState] = useState({
    ...defaultState,
    ...initialState,
  })

  const stateUpdate = (data = {}) => {
    setState(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const complete = () => {
    stateUpdate({
      run: false,
      stepIndex: 0,
    })
  }

  const onRunChange = value => {
    stateUpdate({
      run: value,
    })
  }

  const onRun = () => {
    onRunChange(true)
  }

  const onStop = () => {
    onRunChange(false)
  }

  const onStepIndexChange = index => {
    stateUpdate({
      stepIndex: index,
    })
  }

  const handleCallback = (data = {}) => {
    const { status, type, action, lifecycle } = data

    if (status === STATUS.FINISHED) {
      complete()
      onFinished && onFinished()
    } else if (status === STATUS.SKIPPED) {
      complete()
      onSkipped && onSkipped()
    }
  }

  const onSetSteps = (data = []) => {
    stateUpdate({
      steps: data,
    })
  }

  const onAddSteps = (data = []) => {
    setState(prevState => ({
      ...prevState,
      steps: [...prevState.steps, ...data],
    }))
  }

  const initTour = ({ run = true, steps, stepIndex = 0 }) => {
    stateUpdate({
      run,
      steps,
      stepIndex,
    })
  }

  const completeTour = () => {
    complete()
  }

  return {
    state,
    initTour,
    completeTour,
    onRun,
    onStop,
    handleCallback,
    onSetSteps,
    onAddSteps,
    onStepIndexChange,
  }
}

useTour.propTypes = {
  initialState: PropTypes.shape({
    run: PropTypes.bool,
    stepIndex: PropTypes.number,
    steps: PropTypes.array,
  }),
  onFinished: PropTypes.func,
  onSkipped: PropTypes.func,
  onStepIndexChange: PropTypes.func,
}
