import React from "react"

import { usePanelLayoutContext } from "../../contexts"

export const usePanelLayout = () => {
  const { config, updateIntroVideoConfig } = usePanelLayoutContext()

  const onIntroVideoVisible = (visible = false) => {
    updateIntroVideoConfig({ visible })
  }

  return {
    config,
    onIntroVideoVisible,
  }
}
