import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

import { TourProvider } from "features/tour-ui/contexts"

const FashionModelBookTourContext = createContext({
  tour: {},
  targetsRefs: {
    bookAddRef: null,
    bookFormFieldsRef: null,
  },
  targets: {
    bookFormPhotosTargetRef: null,
    bookFormSaveTargetRef: null,
  },
  callback: () => {},
  interruptTour: () => {},
  successCompleteTour: data => {},
  appTourActive: false,
  stepComplete: index => {},
  runStep: index => {},
})

const FashionModelBookTourProvider = ({ children, tour, ...props }) => {
  return (
    <TourProvider value={{ ...tour }}>
      <FashionModelBookTourContext.Provider value={{ tour, ...props }}>
        {children}
      </FashionModelBookTourContext.Provider>
    </TourProvider>
  )
}

FashionModelBookTourProvider.propTypes = {
  children: PropTypes.any,
  tour: PropTypes.any,
}

const useFashionModelBookTourContext = () =>
  useContext(FashionModelBookTourContext)

export { FashionModelBookTourProvider, useFashionModelBookTourContext }
