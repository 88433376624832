import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { useRefCallback } from "hooks"

export const useTargets = ({ appTourActive, onStepsStateUpdate }) => {
  const { t } = useTranslation()

  // Avatar
  const avatarEditTargetRef = useRef(null)
  const avatarEditRef = useRefCallback(
    node => {
      avatarEditTargetRef.current = node

      if (node !== null && appTourActive) {
        onStepsStateUpdate({ step1: true })
      }
    },
    [appTourActive]
  )

  const avatarUploadTargetRef = useRef(null)
  const avatarUploadRef = useRefCallback(node => {
    avatarUploadTargetRef.current = node
  }, [])
  // === //

  // Profile
  const profileEditTargetRef = useRef(null)
  const profileEditRef = useRefCallback(node => {
    profileEditTargetRef.current = node
  }, [])

  const profileEditFormTargetRef = useRef(null)
  // === //

  // Contacts
  const contactsEditTargetRef = useRef(null)
  const contactsEditRef = useRefCallback(node => {
    contactsEditTargetRef.current = node
  }, [])

  const contactsFormPhoneTargetRef = useRef(null)
  const contactsFormEmailTargetRef = useRef(null)
  const contactsFormSaveTargetRef = useRef(null)
  // === //

  // Measurements
  const measurementsEditTargetRef = useRef(null)
  const measurementsPrimaryFormSectionTargetRef = useRef(null)
  const measurementsSecondaryFormSectionTargetRef = useRef(null)
  const measurementsFormSaveTargetRef = useRef(null)
  // === //

  return {
    targetsRefs: {
      avatarEditRef,
      avatarUploadRef,
      profileEditRef,
      contactsEditRef,
    },
    targets: {
      avatarEditTargetRef,
      avatarUploadTargetRef,
      profileEditTargetRef,
      profileEditFormTargetRef,
      contactsEditTargetRef,
      contactsFormPhoneTargetRef,
      contactsFormEmailTargetRef,
      contactsFormSaveTargetRef,
      measurementsEditTargetRef,
      measurementsPrimaryFormSectionTargetRef,
      measurementsSecondaryFormSectionTargetRef,
      measurementsFormSaveTargetRef,
    },
  }
}

useTargets.propTypes = {
  appTourActive: PropTypes.bool,
  onStepsStateUpdate: PropTypes.func,
}
