import React from "react"
import PropTypes from "prop-types"

import { Tour } from "features/tour-ui/components/Tour"
import s from "./GeneralTour.scss"
import { Tooltip } from "./Tooltip"

export const GeneralTour = ({ ...props }) => {
  return <Tour tooltipComponent={Tooltip} {...props} />
}

GeneralTour.propTypes = {}
