import React, { useEffect } from "react"
import { userNavigationData } from "features/user-navigation/store"
import { useDispatch } from "react-redux"

export const useUserNavigationEffect = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userNavigationData.getData({}))

    return () => {
      dispatch(userNavigationData.cleanState({}))
    }
  }, [])
}
