import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Progress } from "reactstrap"

export const CompletionStepsProgressBar = ({ id, value, total }) => {
  const { t } = useTranslation()

  return (
    <div id={id} role="button">
      <h6 className={"text-truncate"}>{t("progress")}</h6>
      <Progress
        color={"success"}
        className="progress-lg"
        value={value}
        max={total}
      >{`${value} ${t("of")} ${total}`}</Progress>
    </div>
  )
}

CompletionStepsProgressBar.propTypes = {
  id: PropTypes.string,
  value: PropTypes.number,
  total: PropTypes.number,
}
