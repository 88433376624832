import { createAction } from "@reduxjs/toolkit"

const SOCKET_ON_CHAT_MESSAGE = "socket/onChatMessage"

export const socketOnChatMessage = createAction(
  SOCKET_ON_CHAT_MESSAGE,
  function prepare(data) {
    return {
      payload: {
        data,
      },
    }
  }
)
