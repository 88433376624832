import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

import { TourProvider } from "features/tour-ui/contexts"

const AgencyCreateTourContext = createContext({
  tour: {},
  targetsRefs: { createRef: null },
  targets: { formTargetRef: null },
  callback: () => {},
  interruptTour: () => {},
  successCompleteTour: data => {},
  appTourActive: false,
  stepComplete: index => {},
  goToStep: index => {},
})

const AgencyCreateTourProvider = ({ children, tour, ...props }) => {
  return (
    <TourProvider value={{ ...tour }}>
      <AgencyCreateTourContext.Provider value={{ tour, ...props }}>
        {children}
      </AgencyCreateTourContext.Provider>
    </TourProvider>
  )
}

AgencyCreateTourProvider.propTypes = {
  children: PropTypes.any,
  tour: PropTypes.any,
}

const useAgencyCreateTourContext = () => useContext(AgencyCreateTourContext)

export { AgencyCreateTourProvider, useAgencyCreateTourContext }
