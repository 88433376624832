import React from "react"
import { useHistory } from "react-router-dom"

import { useAppTourState } from "features/app-tour/hooks"
import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { useModel } from "./useModel"

const { AGENCY, CLIENT, CALENDAR_EVENT_JOB } = APP_TOUR_ALIAS

export const useAppTour = () => {
  const history = useHistory()

  const { startTour } = useAppTourState()

  const { getModelTourConfig, loading: modelFetching } = useModel()

  const getTourInitialRoute = alias => {
    let route = ""
    if (alias === AGENCY) {
      route = "/agencies"
    } else if (alias === CLIENT) {
      route = "/clients"
    } else if (alias === CALENDAR_EVENT_JOB) {
      route = "/calendar"
    }

    return route
  }

  const onTourStat = async ({ alias, payload = {} }) => {
    let route = ""
    let tourAlias = ""

    if ([AGENCY, CLIENT, CALENDAR_EVENT_JOB].includes(alias)) {
      route = getTourInitialRoute(alias)
      tourAlias = alias
    } else {
      try {
        const modelTourConfig = await getModelTourConfig(alias)

        if (modelTourConfig) {
          route = modelTourConfig.route
          tourAlias = modelTourConfig.tourAlias
        }
      } catch (e) {}
    }

    if (route && tourAlias) {
      startTour({ alias: tourAlias })

      history.push(route)
    }
  }

  return {
    onTourStat,
    loading: modelFetching,
  }
}
