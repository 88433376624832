import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import _ from "lodash"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
  CheckboxField,
} from "components"
import { modelHelper } from "helpers"
import { MEASUREMENT_SYSTEM_METRIC } from "consts"
import { useFashionModelUpdateTourContext } from "features/fashion-model/contexts"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as TOUR_STEP_ALIAS } from "features/fashion-model/consts/tour"

export const EditFeaturesForm = props => {
  const { t } = useTranslation()

  const measurementSystem =
    props.measurementsSystem || MEASUREMENT_SYSTEM_METRIC

  const featureNaturalnessOptions = modelHelper.general
    .getFeatureNaturalnessOptions()
    .map(item => ({
      ...item,
      text: item.label,
    }))

  const validationSchema = measurementSystem =>
    Yup.object().shape({
      weight: Yup.number()
        .min(
          modelHelper.features.weightValidValue(measurementSystem).min,
          "field.error.model-weight.between"
        )
        .max(
          modelHelper.features.weightValidValue(measurementSystem).max,
          "field.error.model-weight.between"
        )
        .nullable(true)
        .transform((v, o) => (o === "" ? null : v)),
    })

  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      ...modelHelper.features.featuresFormInitialValues,
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema(measurementSystem)),
    mode: "onBlur",
  })

  const onSubmit = values => {
    let params = {}

    for (let fieldName in values) {
      let value
      if (values.hasOwnProperty(fieldName)) {
        value = _.isObject(values[fieldName])
          ? values[fieldName].value
          : values[fieldName]

        params = {
          ...params,
          [fieldName]: value,
        }
      }
    }

    props.onSubmit(params, { setError })
  }

  const getOptionLabel = option => `${option.text}`

  const renderField = item => {
    if (!item) return null

    const {
      name,
      label,
      placeholder,
      options,
      type,
      normalize,
      translateParams,
    } = item

    if (item.fieldType === "combobox") {
      return (
        <ControllerHF
          name={name}
          control={control}
          component={ComboboxFormField}
          id={name}
          label={t(label)}
          placeholder={t(placeholder)}
          options={options}
          getOptionLabel={getOptionLabel}
          selectProps={{
            isClearable: true,
            isSearchable: item.isSearchable,
          }}
          normalize={normalize}
          isDetermineValue={item.isDetermineValue}
        />
      )
    } else if (item.fieldType === "input") {
      return (
        <ControllerHF
          name={name}
          control={control}
          component={InputField}
          id={name}
          label={t(label)}
          placeholder={t(placeholder)}
          type={type}
          normalize={normalize}
          translateParams={translateParams}
        />
      )
    } else if (item.fieldType === "checkbox") {
      return (
        <ControllerHF
          name={name}
          control={control}
          component={CheckboxField}
          id={name}
          label={t(label)}
          normalize={normalize}
          translateParams={translateParams}
          wrapClass={item.wrapClass}
          size={"lg"}
        />
      )
    }
    return null
  }

  const measurementsFields1 = useMemo(
    () =>
      modelHelper.features.getFeaturesFormFieldsSection1(
        props.measurementsOptions,
      ),
    [props.measurementsOptions]
  )

  const measurementsFields2 = useMemo(
    () =>
      modelHelper.features.getFeaturesFormFieldsSection2(
        props.measurementsOptions,
        measurementSystem
      ),
    [props.measurementsOptions]
  )

  const measurementsFields3_A = useMemo(
    () =>
      modelHelper.features.getFeaturesFormFieldsSection3_A(
        props.measurementsOptions,
        featureNaturalnessOptions,
      ),
    [props.measurementsOptions, featureNaturalnessOptions]
  )

  const measurementsFields3_B = useMemo(
    () =>
      modelHelper.features.getFeaturesFormFieldsSection3_B(
        props.measurementsOptions,
        featureNaturalnessOptions,
      ),
    [props.measurementsOptions, featureNaturalnessOptions]
  )

  const measurementsFields4 = useMemo(
    () => modelHelper.features.getFeaturesFormFieldsSection4(),
    []
  )

  const measurementsFields5 = useMemo(
    () => modelHelper.features.getFeaturesFormFieldsSection5(),
    []
  )

  const measurementsFields6 = useMemo(
    () => modelHelper.features.getFeaturesFormFieldsSection6(),
    []
  )

  const measurementsFields7 = useMemo(
    () => modelHelper.features.getFeaturesFormFieldsSection7(),
    []
  )

  const measurementsFields8 = useMemo(
    () => modelHelper.features.getFeaturesFormFieldsSection8(),
    []
  )

  const { targets, runStep, appTourActive } = useFashionModelUpdateTourContext()

  useEffect(() => {
    if (appTourActive) {
      runStep(TOUR_STEP_ALIAS.MEASUREMENTS_FORM_PRIMARY_SECTION)
    }
  }, [appTourActive])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div ref={targets.measurementsPrimaryFormSectionTargetRef}>
          {measurementsFields1.map((row, i) => (
            <Row key={`s1-${i}`}>
              {row.map((item, j) => (
                <Col key={`s1-${i}-${j}`} md={3}>
                  {renderField(item)}
                </Col>
              ))}
            </Row>
          ))}
        </div>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          {measurementsFields2.map((item, i) => (
            <Col md={3} key={`s2-${i}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <div ref={targets.measurementsSecondaryFormSectionTargetRef}>
          <Row>
            {measurementsFields3_A.map((item, i) => (
              <Col md={3} key={`s3-${i}`}>
                {renderField(item)}
              </Col>
            ))}
          </Row>
        </div>

        <Row>
          {measurementsFields3_B.map((item, i) => (
            <Col md={3} key={`s3-${i}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>

        <Row>
          {measurementsFields4.map((item, i) => (
            <Col md={6} key={`s4-${i}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <h4 className={"mb-4"}>
          {t("model.features.title.skills-permissions")}
        </h4>

        <Row>
          {measurementsFields5.map((item, i) => (
            <Col md={3} key={`s5-${i}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          {measurementsFields6.map((item, i) => (
            <Col md={6} key={`s6-${i}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <h4 className={"mb-4"}>{t("model.features.title.food-allergies")}</h4>

        <Row>
          {measurementsFields7.map((item, i) => (
            <Col md={3} key={`s7-${i}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>

        <Row>
          {measurementsFields8.map((item, i) => (
            <Col md={6} key={`s8-${i}`}>
              {renderField(item)}
            </Col>
          ))}
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              innerRef={targets.measurementsFormSaveTargetRef}
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

EditFeaturesForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  measurementsOptions: PropTypes.object,
  measurementsSystem: PropTypes.string,
}
