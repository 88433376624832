import React from "react"
import PropTypes from "prop-types"

import { useFashionModelBookTour } from "features/fashion-model/hooks"
import { FashionModelBookTourProvider } from "features/fashion-model/contexts"
import { GeneralTour } from "features/tour-ui/components/Tour"

export const BookTourPanel = props => {
  const modelBookTour = useFashionModelBookTour({})

  return (
    <FashionModelBookTourProvider {...modelBookTour}>
      {props.children}

      <GeneralTour
        {...modelBookTour.tour?.state}
        callback={modelBookTour.callback}
        scrollOffset={200}
        continuous
      />
    </FashionModelBookTourProvider>
  )
}

BookTourPanel.propTypes = {
  children: PropTypes.node,
}
