import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ExpensesField } from "../../../../FormElements/ExpensesField"
import { useSelector } from "react-redux"
import { modelHelper } from "helpers"

export const ExpensesForm = props => {
  const { t } = useTranslation()

  const expenseTypeOptions = modelHelper.expenses.getEventExpenseTypeOptions()

  const userCurrency = useSelector(
    state => state.user.profile.data?.settings?.currency
  )

  const { control } = useFormContext()

  return (
    <Row>
      <Col md={8}>
        <ControllerHF
          name={"expenses"}
          control={control}
          component={ExpensesField}
          id={"expenses"}
          currency={userCurrency}
          className={"mt-3"}
          expenseTypeOptions={expenseTypeOptions}
          {...props}
        />
      </Col>
    </Row>
  )
}

ExpensesForm.propTypes = {}
