import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classNames from "classnames"

import placeholder from "../../../../assets/images/dev/avatar-placeholder-square.svg"
import { ModelUtils } from "../../../../utils"
import { Image } from "components"
import { IMAGE_CROP_TYPE } from "consts"

export const ModelsItem = props => {
  const { t } = useTranslation()

  const handleClick = (event, item) => {
    event.preventDefault()
    props.onSelect(item)
  }

  return (
    <React.Fragment>
      <div
        className={classNames("models-item", {
          "models-item_active": props.item.id.toString() === props.selectedId,
        })}
      >
        <Link to={"#"} onClick={e => handleClick(e, props.item)}>
          <div className="models-item__block">
            <div className={"models-item__avatar-block"}>
              <div className={"image-rounded"}>
                <Image
                  type={IMAGE_CROP_TYPE.SQUARE}
                  src={props.item?.avatar || placeholder}
                  crops={props.item?.crops}
                  alt={props.item.name}
                />

                <div
                  className={classNames({
                    "image-rounded__outline":
                      props.item.id.toString() === props.selectedId,
                  })}
                />
              </div>
              <div className={"models-item__in-town-block"}>
                <div
                  className={classNames("models-item__in-town-mark", {
                    "bg-danger": !props.item.is_in_town,
                  })}
                />
              </div>
            </div>
            <div className={"models-item__title-block"}>
              <div className={"models-item__title"}>
                {props.item.name}
                {props.item.birthday
                  ? `, ${ModelUtils.getAge(props.item.birthday)}`
                  : null}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </React.Fragment>
  )
}

ModelsItem.propTypes = {
  selectedId: PropTypes.string,
  item: PropTypes.object,
  onSelect: PropTypes.func,
}
