import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"

import {
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
} from "components"
import {
  ExpensesSection,
  ModelAgencyNotesSection,
  ServiceDetailsSection,
} from "../FormsSections"

export const TravelForm = props => {
  const { t } = useTranslation()

  useEffect(() => {
    props.reset()
  }, [])

  return (
    <>
      <ServiceDetailsSection control={props.control} />

      <hr className="drawer-separator drawer-separator_form" />

      <Row>
        <Col md={4}>
          <ControllerHF
            name={"travel_by"}
            control={props.control}
            component={ComboboxFormField}
            id={"travel_by"}
            label={t("travel_by")}
            placeholder={t("travel_by")}
            options={props.travelOptions}
            selectProps={{
              isClearable: false,
            }}
            normalize={option => (option ? option.value : null)}
            isDetermineValue
          />
        </Col>
      </Row>

      <h4 className={"pt-3 mb-3"}>{t("departure")}</h4>

      <Row>
        <Col md={4}>
          <ControllerHF
            name={"departure_place"}
            control={props.control}
            component={InputField}
            id={"departure_place"}
            label={t("place")}
            placeholder={t("place")}
          />
        </Col>

        <Col md={2}>
          <ControllerHF
            name={"departure_date"}
            control={props.control}
            component={DateInputField}
            id={"departure_date"}
            label={t("date")}
            placeholder={"DD/MM/YYYY"}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          />
        </Col>

        <Col md={2}>
          <ControllerHF
            name={"departure_hour"}
            control={props.control}
            component={DateInputField}
            id={"departure_hour"}
            label={t("hour")}
            placeholder={"HH:mm"}
            timeFormat={"HH:mm"}
            mask={[/\d/, /\d/, ":", /\d/, /\d/]}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeCaption={t("time")}
          />
        </Col>
      </Row>

      <h4 className={"pt-3 mb-3"}>{t("arrival")}</h4>

      <Row>
        <Col md={4}>
          <ControllerHF
            name={"arrival_place"}
            control={props.control}
            component={InputField}
            id={"arrival_place"}
            label={t("place")}
            placeholder={t("place")}
          />
        </Col>

        <Col md={2}>
          <ControllerHF
            name={"arrival_date"}
            control={props.control}
            component={DateInputField}
            id={"arrival_date"}
            label={t("date")}
            placeholder={"DD/MM/YYYY"}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          />
        </Col>

        <Col md={2}>
          <ControllerHF
            name={"arrival_hour"}
            control={props.control}
            component={DateInputField}
            id={"arrival_hour"}
            label={t("hour")}
            placeholder={"HH:mm"}
            timeFormat={"HH:mm"}
            mask={[/\d/, /\d/, ":", /\d/, /\d/]}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeCaption={t("time")}
          />
        </Col>
      </Row>

      <hr className="drawer-separator drawer-separator_form" />

      <ModelAgencyNotesSection control={props.control} />

      <hr className="drawer-separator drawer-separator_form" />

      <ExpensesSection control={props.control} />
    </>
  )
}

TravelForm.propTypes = {
  control: PropTypes.object,
  travelOptions: PropTypes.array,
  reset: PropTypes.func,
}
