import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { Models } from "./Models"
import { GENDER_FEMALE, GENDER_MALE } from "consts"
import { NormalizeUtils } from "utils"
import {
  CheckboxGroup,
  ComboboxFormField,
  GenderTabsNav,
  SearchInput,
} from "components"
import {
  modelPanelModelsSidebarFetchList,
  modelPanelModelsSidebarListChangeFilter,
  modelPanelModelsSidebarListChangeSearchInput,
  modelsGroupsList,
} from "store/actions"
import { selectModelsSidebarSyncCount } from "store"

const tabs = [
  {
    label: "female",
    value: [GENDER_FEMALE],
  },
  {
    label: "male",
    value: [GENDER_MALE],
  },
  {
    label: "all",
    value: [GENDER_FEMALE, GENDER_MALE],
  },
]

const GeneralModelsSidebarComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isFirstMount = useFirstMountState()

  const isInTownOptions = [
    {
      label: (
        <span className={"text-secondary"}>{t("in-town.status.in")}</span>
      ),
      value: true,
      color: "secondary",
    },
    {
      label: (
        <span className={"text-danger"}>{t("in-town.status.out")}</span>
      ),
      value: false,
      color: "secondary",
    },
  ]

  useEffect(() => {
    if (!props.isFetched) {
      fetchData({ page: 1 })
    }
  }, [])

  useEffect(() => {
    dispatch(
      modelsGroupsList.getList({
        params: {
          pagination: false,
          type: null,
        },
      })
    )
    return () => {
      dispatch(modelsGroupsList.cleanState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData({ page: 1 })
    }
  }, [props.filters.values, props.syncCount])

  const fetchData = params => {
    params = {
      ...params,
    }

    const { keywords } = props.filters.values

    params.filters = {
      ...props.filters.values,
      search_fields: keywords ? ["name"] : null,
    }

    props.modelPanelModelsSidebarFetchList(params)
  }

  const onSearchInputChange = value => {
    props.modelPanelModelsSidebarListChangeSearchInput(value)
  }

  const handleSearch = value => {
    props.modelPanelModelsSidebarListChangeFilter({ keywords: value })
  }

  const onTabChange = value => {
    props.modelPanelModelsSidebarListChangeFilter({ genders: value })
  }

  const inTownChange = value => {
    props.modelPanelModelsSidebarListChangeFilter({ is_in_town: value })
  }

  const handleGroupChange = value => {
    props.modelPanelModelsSidebarListChangeFilter({
      group: value,
      genders: value ? [value.type] : [GENDER_FEMALE],
    })
  }

  const hasMore = useMemo(() => {
    const { meta } = props
    return meta && meta.current_page !== meta.last_page
  }, [props.meta])

  const isMoreLoading = useMemo(() => {
    const page = props.meta?.current_page
    return props.listLoading && !!props.meta
  }, [props.listLoading, props.meta])

  const fetchMore = () => {
    const { meta } = props
    if (hasMore && !props.listLoading) {
      fetchData({
        page: meta.current_page + 1,
      })
    }
  }

  const handleSelectModel = item => {
    props.onModelSelect(item)
  }

  return (
    <div className="panel-sidebar__sidebar-content panel-common-sidebar-content">
      <div className={""}>
        <SearchInput
          value={props.searchInput}
          onInputChange={onSearchInputChange}
          onSearch={handleSearch}
          placeholder={t("start_typing_models")}
          minLimit={1}
          className={"mb-2"}
        />
        <ComboboxFormField
          value={props.filters.values.group}
          onChange={handleGroupChange}
          placeholder={t("group")}
          options={props.groups}
          getOptionLabel={option => `${option.name} (${option.type})`}
          getOptionValue={option => option.id}
          normalize={option => (option ? option.id : null)}
          isDetermineValue
          selectProps={{
            isClearable: true,
          }}
        />
        <hr className={"divider mb-1"} />
      </div>

      <div>
        {!props.filters.values.group && (
          <GenderTabsNav
            tabs={tabs}
            activeTab={props.filters.values.genders}
            onTabChange={onTabChange}
          />
        )}
      </div>

      <div className="my-3">
        <CheckboxGroup
          id={"is_in_town"}
          onChange={inTownChange}
          fieldValue={props.filters.values.is_in_town || []}
          options={isInTownOptions}
          normalize={NormalizeUtils.stringBoolToBoolean}
          className={"d-flex"}
        />
      </div>

      <Models
        items={props.list}
        loading={props.listLoading}
        error={props.listError}
        meta={props.meta}
        showMore={!!props.list.length && hasMore}
        isMoreLoading={isMoreLoading}
        onLoadMore={fetchMore}
        onSelect={handleSelectModel}
        selectedId={props.selectedId}
      />
    </div>
  )
}

GeneralModelsSidebarComponent.propTypes = {
  onModelSelect: PropTypes.func,
  selectedId: PropTypes.string,

  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  isFetched: PropTypes.bool,
  searchInput: PropTypes.string,
  meta: PropTypes.object,
  filters: PropTypes.object,
  syncCount: PropTypes.number,

  groups: PropTypes.array,

  modelPanelModelsSidebarFetchList: PropTypes.func,
  modelPanelModelsSidebarListChangeFilter: PropTypes.func,
  modelPanelModelsSidebarListChangeSearchInput: PropTypes.func,
}

const mapStateToProps = state => {
  const {
    list,
    listLoading,
    listError,
    isFetched,
    searchInput,
    meta,
    filters,
  } = state.modelPanel.panel.modelsSidebar
  const { list: groups } = state.models.groups.list

  const syncCount = selectModelsSidebarSyncCount(state)
  return {
    list,
    listLoading,
    listError,
    isFetched,
    searchInput,
    meta,
    filters,
    syncCount,
    groups,
  }
}

const mapDispatchToProps = {
  modelPanelModelsSidebarFetchList,
  modelPanelModelsSidebarListChangeFilter,
  modelPanelModelsSidebarListChangeSearchInput,
}

export const GeneralModelsSidebar = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralModelsSidebarComponent)
