import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

import { TourProvider } from "features/tour-ui/contexts"
import { eventsCalendarHelper } from "helpers"

const eventsOptions = eventsCalendarHelper.eventConfig.getEventsOptions()

const eventsInitialTargetsRef = eventsOptions.reduce((acc, cur) => {
  const { type } = cur
  return { ...acc, [type]: null }
}, {})

const EventsCalendarTourContext = createContext({
  tour: {},
  targetsRefs: {
    eventsTypesControlsRef: (node, type) => {},
    eventsTypesFormsRef: (node, type) => {},
  },
  targets: {
    eventsTypesControlsTargetsRef: eventsInitialTargetsRef,
    eventsTypesFormsTargetsRef: eventsInitialTargetsRef,
  },
  callback: () => {},
  interruptTour: () => {},
  successCompleteTour: data => {},
  appTourActive: false,
  stepComplete: index => {},
  goToStep: index => {},
})

const EventsCalendarTourProvider = ({ children, tour, ...props }) => {
  return (
    <TourProvider value={{ ...tour }}>
      <EventsCalendarTourContext.Provider value={{ tour, ...props }}>
        {children}
      </EventsCalendarTourContext.Provider>
    </TourProvider>
  )
}

EventsCalendarTourProvider.propTypes = {
  children: PropTypes.any,
  tour: PropTypes.any,
}

const useEventsCalendarTourContext = () => useContext(EventsCalendarTourContext)

export { EventsCalendarTourProvider, useEventsCalendarTourContext }
