import { clientHelper } from "helpers"

export const mapClientData = client => {
  let result = {
    client_information: "",
    client_vat: client?.vat || "",
    bank_name: client?.bank_name || "",
    iban: client?.bank_account_number || "",
    swift: client?.swift_code || "",
  }

  const mapInfo = () => {
    const newLine = `\n`

    let value = `${client.name}`
    const country = client?.country?.name
    const billingAddress = clientHelper.common.getBillingAddress(client)

    if (billingAddress) {
      const { street, town, zip } = billingAddress
      value += `${newLine}${street} ${town}`

      if (zip) {
        value += ` ${zip}`
      }
    }

    if (country) {
      value += `${newLine}${country}`
    }

    return value
  }

  result.client_information = mapInfo()

  return result
}
