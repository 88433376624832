import React, { useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Link } from "react-router-dom"

import paperclipIcon from "../../../assets/images/dev/file-paperclip-icon.svg"
import { AttachedFile } from "../../AttachedFile"
import { FormUtils } from "../../../utils"

export const FilePickerField = ({
  id,
  label,
  pickerLabelText,
  fileNamePlaceholderText,
  className,
  accept,
  value,
  meta = {},
  onChange,
  onDownload,
  withDownload = true,
  withRemove = true,
  ...props
}) => {
  const { error } = meta

  const showError = Boolean(error)

  const { t } = useTranslation()

  const inputRef = useRef()

  const handleFilePicked = event => {
    const file = event.target.files[0]

    onChange({ file: file })

    if (inputRef.current) {
      inputRef.current.value = null
    }
  }

  const pickFile = event => {
    event.preventDefault()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const removeFile = () => {
    onChange(null)
  }

  const renderPicker = () => (
    <div className={"files-picker-action"}>
      <input
        type="file"
        id={id}
        className="d-none"
        onChange={handleFilePicked}
        ref={inputRef}
        accept={accept}
      />

      <Link
        to="#"
        className="text-decoration-underline files-picker-action__btn"
        onClick={pickFile}
      >
        <img
          src={paperclipIcon}
          alt={"pick"}
          className={"files-picker-action__btn-icon"}
        />

        {pickerLabelText}
      </Link>
    </div>
  )

  const isLocalFile = !!value?.file

  const file = useMemo(() => {
    if (isLocalFile) {
      return value?.file
    }

    return value?.attachment
  }, [value])

  return (
    <div
      className={classNames("file-picker", {
        [className]: className,
      })}
    >
      <div className={"file-picker__block"}>
        {value && (
          <div className={"file-picker__file-block"}>
            <AttachedFile
              name={file?.name}
              onRemove={removeFile}
              size={file?.size}
              withDownload={withDownload && !isLocalFile}
              withRemove={withRemove}
              onDownload={() => onDownload(value?.attachment?.src)}
              fileNamePlaceholderText={fileNamePlaceholderText}
            />
          </div>
        )}

        {!props.hidePicker && renderPicker()}

        {error && (
          <div className={"field-error-message"}>
            {FormUtils.getErrorMessage(error, {
              t,
              translateParams: props.translateParams,
            })}
          </div>
        )}
      </div>
    </div>
  )
}

FilePickerField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  pickerLabelText: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  accept: PropTypes.string,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  onDownload: PropTypes.func,
  withDownload: PropTypes.bool,
  fileNamePlaceholderText: PropTypes.string,
  hidePicker: PropTypes.bool,
  withRemove: PropTypes.bool,
}
