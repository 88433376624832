
export const FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS = {
  AVATAR_EDIT: "avatar_edit",
  AVATAR_UPLOAD: "avatar_upload",
  PROFILE_EDIT: "profile_edit",
  PROFILE_EDIT_FORM: 'profile_edit_form',
  CONTACTS_EDIT: "contacts_edit",
  CONTACTS_FORM_PHONE: "contacts_form_phone",
  CONTACTS_FORM_EMAIL: "contacts_form_email",
  CONTACTS_FORM_SAVE: "contacts_form_save",
  MEASUREMENTS_EDIT: "measurements_edit",
  MEASUREMENTS_FORM_PRIMARY_SECTION: "measurements_form_primary_section",
  MEASUREMENTS_FORM_SECONDARY_SECTION: "measurements_form_secondary_section",
  MEASUREMENTS_FORM_SAVE: "measurements_form_save",
  FINISHED: "finished",
}
