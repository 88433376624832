import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Label } from "reactstrap"

import { FieldError } from "../FieldError"
import { LangPicker } from "components"

export const LangPickerField = ({
  value,
  meta = {},
  translateParams,
  className,
  onChange,
  label,
  ...props
}) => {
  const { error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  return (
    <div className={classNames("", { [className]: className })}>
      {label && <Label>{label}</Label>}

      <div
        className={classNames("", {
          "is-invalid": showError,
        })}
      >
        <LangPicker value={value} onChange={onChange} />
      </div>

      <FieldError
        error={error}
        translateParams={translateParams}
        showError={showError}
      />
    </div>
  )
}

LangPickerField.propTypes = {
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  value: PropTypes.any,
  meta: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.any,
}
