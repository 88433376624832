import React, { useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"

import { MegaTagTitle, BookPhotosPicker } from "../../../components"
import {
  useDraftPackageDataPicker,
  usePanelSidebar,
  usePagePanelLayoutEffect,
} from "../../../hooks"
import { MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR } from "../../../consts"
import {
  PhotosContainer,
  BookForm,
  BookDataLoader,
} from "../components/BookComponents"
import { ValidationsUtils } from "../../../utils"
import {
  modelEditBook,
  modelEditBookCleanState,
  modelBookPhotosPickerSetValue,
  modelBookPhotosPickerCleanValue,
} from "../../../store/actions"

const BookEditComponent = props => {
  const history = useHistory()
  const { id, bookId } = useParams()

  const pagePanelLayoutOptions = useMemo(() => {
    return {
      sidebar: true,
    }
  }, [])

  usePagePanelLayoutEffect(pagePanelLayoutOptions)

  const panelSideBarOptions = useMemo(() => {
    return {
      active: MODEL_PANEL_BOOK_PHOTOS_PICKER_SIDEBAR,
      extra: { mode: "edit" },
    }
  }, [])

  usePanelSidebar(panelSideBarOptions)

  useEffect(() => {
    return () => {
      props.modelEditBookCleanState()
      props.modelBookPhotosPickerCleanValue()
    }
  }, [])

  const { updateBook, isBookPicked } = useDraftPackageDataPicker()

  useEffect(() => {
    if (props.book?.pages.length > 0) {
      const items = props.book?.pages.map(item => ({
        ...item,
      }))
      props.modelBookPhotosPickerSetValue(items)
    }
    return () => {}
  }, [props.book])

  const findAndUpdateDraftPackageBook = book => {
    if (isBookPicked(book?.id)) {
      updateBook(book.id, book)
    }
  }

  const onSubmitSuccess = onSubmitProps => data => {
    const { reset } = onSubmitProps
    reset()

    findAndUpdateDraftPackageBook(data)

    history.push(`/models/${id}/book`)
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelEditBook(
      bookId,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }

  return (
    <div className={"model-panel-book-edit"}>
      <MegaTagTitle title={"title.model.book-edit"} />

      <BookDataLoader id={bookId}>
        {props.book && (
          <>
            <Row>
              <Col>
                <BookForm
                  initialValues={{
                    title: props.book?.title,
                    description: props.book?.description,
                    published: props.book?.published,
                  }}
                  loading={props.loading}
                  error={props.error}
                  onSubmit={handleSubmit}
                  picked={props.picked}
                />
              </Col>
            </Row>

            <Row className={"mt-4 pt-4"}>
              <BookPhotosPicker component={PhotosContainer} />
            </Row>
          </>
        )}
      </BookDataLoader>
    </div>
  )
}

BookEditComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
  book: PropTypes.object,
  picked: PropTypes.array,

  modelEditBook: PropTypes.func,
  modelEditBookCleanState: PropTypes.func,
  modelBookPhotosPickerSetValue: PropTypes.func,
  modelBookPhotosPickerCleanValue: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.modelPanel.book.details.data
  const { loading, error } = state.modelPanel.book.details.edit
  const { picked } = state.modelPanel.book.photosPicker
  return {
    book: data,
    loading,
    error,
    picked,
  }
}

const mapDispatchToProps = {
  modelEditBook,
  modelEditBookCleanState,
  modelBookPhotosPickerSetValue,
  modelBookPhotosPickerCleanValue,
}

export const BookEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookEditComponent)
