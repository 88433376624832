import React, { useEffect } from "react"

import { usePagePanelLayoutContext } from "../../contexts"

export const usePagePanelLayoutEffect = (options = {}) => {
  const { onChangeOptions, resetOptions } = usePagePanelLayoutContext()

  useEffect(() => {
    return () => {
      resetOptions()
    }
  }, [])

  useEffect(() => {
    onChangeOptions(options)
  }, [options])
}
