import React from "react"
import PropTypes from "prop-types"

import { Button } from "components"

export const PaymentSubmit = ({ isDebt, is_paid, onClick, ...props }) => {
  return (
    <Button
      title={is_paid ? "refund" : isDebt ? "pay_debt" : "paid"}
      onClick={onClick}
      outline={is_paid}
      color={is_paid ? "dark-gray" : "primary"}
      type={"button"}
      size={"btn-sm"}
      btnClass={"statement-payment-submit__btn"}
    />
  )
}

PaymentSubmit.propTypes = {
  isDebt: PropTypes.bool,
  is_paid: PropTypes.bool,
  onClick: PropTypes.func,
}
