import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"

import { SendDataEmailsForm } from "../../Forms"
import { ValidationsUtils } from "utils"
import { sharedEntitySend } from "store/actions"
import { BOOK, PACKAGE } from "../consts"

const SendSharedEntityComponent = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(sharedEntitySend.cleanState())
    }
  }, [])

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()

    props.onSuccess()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    params = {
      ...params,
      entity: props.entity,
      entity_id: props.entityId,
    }
    dispatch(
      sharedEntitySend.send({
        params,
        onSuccess: onSubmitSuccess(onSubmitProps),
        onError: onSubmitError(onSubmitProps),
      })
    )
  }

  return (
    <SendDataEmailsForm
      entity={props.entity}
      initialValues={{}}
      loading={props.loading}
      error={props.error}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
    />
  )
}

SendSharedEntityComponent.propTypes = {
  entity: PropTypes.oneOf([BOOK, PACKAGE]).isRequired,
  entityId: PropTypes.number,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,

  loading: PropTypes.bool,
  error: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, error } = state.sharedEntity.send
  return {
    loading,
    error,
  }
}

const mapDispatchToProps = {}

export const SendSharedEntity = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendSharedEntityComponent)
