export const APP_TOUR_ALIAS = {
  FASHION_MODEL_CREATE: "fashion_model_create",
  FASHION_MODEL_UPDATE: "fashion_model_update",
  FASHION_MODEL_PHOTO: "fashion_model_photo",
  FASHION_MODEL_BOOK: "fashion_model_book",
  FASHION_MODEL_COMPCARD: "fashion_model_compacard",
  AGENCY: "agency",
  CLIENT: "client",
  CALENDAR_EVENT_JOB: "calendar_event_job",
}
