import { createAction } from "@reduxjs/toolkit"

const USER_NAVIGATION_SOCKET_DATA_ON_UPDATE =
  "userNavigation/socketDataOnUpdate"

export const userNavigationSocketDataOnUpdate = createAction(
  USER_NAVIGATION_SOCKET_DATA_ON_UPDATE,
  function prepare({ data }) {
    return {
      payload: {
        data,
      },
    }
  }
)
