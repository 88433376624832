import React, { useState, useMemo } from 'react'
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from "moment";

import {
    Button,
    ComboboxFormField,
    ControllerHF,
    InputField,
    DateInputField,
} from '../../../../../../components'
import { FilesUploader } from './FilesUploader';
import { ValidationsUtils } from "../../../../../../utils"
import { modelHelper, commonHelper } from "../../../../../../helpers"

const FILE_MAX_SIZE = 16777216; // 16 mb

export const DocumentForm = (props) => {
    const { t } = useTranslation();

    const [filesProcessing, setFilesProcessing] = useState([]);

    const { control, handleSubmit, setError, reset, setValue, trigger } = useForm({
        defaultValues: {
            type: null,
            reference: '',
            number: '',
            country_id: null,
            date_start: null,
            date_end: null,
            reminder: null,
            details: '',
            files: [],
            ...props.initialValues,
        },
        resolver: yupResolver(Yup.object().shape({
            type: Yup.string().nullable().required('field.error.required'),
            date_end: Yup.date().nullable()
                .test({
                    name: 'dateEqualOrGrater',
                    exclusive: false,
                    params: { },
                    message: 'field.error.date.after-or-equal',
                    test: function (value) {
                        return ValidationsUtils.dateEqualOrAfter(value, this.parent.date_start)
                    },
                }),
            files: Yup.array()
                .of(
                    Yup.object().shape({
                        file: Yup.mixed()
                            .test('maxSize', 'field.error.file-size.max', (value) => ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)),
                    })
                ),
        })),
        mode: 'onChange'
    });

    const onReset = () => {
        setValue('files', []);
        reset();
    }

    const onSubmit = (values) => {
        const params = {
            ...values,
            date_start: values.date_start ? moment(values.date_start).format('YYYY-MM-DD') : null,
            date_end: values.date_end ? moment(values.date_end).format('YYYY-MM-DD') : null,
            country_id: values.country_id ? values.country_id.id : null,
            files: values.files.map(item => item.id),
        }

        props.onSubmit(params, { onReset, setError });
    };

    const onCancel = () => {
        props.onCancel();
    }

    const addFileToProcessing = (fieldId) => {
        setFilesProcessing(prevState => (
            [...prevState, fieldId]
        ))
    }

    const removeFileFromProcessing = (fieldId) => {
        setFilesProcessing(prevState => (
            prevState.filter((item) => item !== fieldId)
        ))
    }

    const isDisabled = useMemo(() => {
        return props.loading || !!filesProcessing.length;
    }, [props.loading, filesProcessing])

    return (
        <>
            {props.error && (
                <Alert color="danger">
                    {props.error.message}
                </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4}>
                        <ControllerHF
                            name={'type'}
                            control={control}
                            component={ComboboxFormField}
                            id={'type'}
                            label={t('document-type')}
                            placeholder={t('document-type')}
                            options={modelHelper.documents.getDocumentsOptions()}
                            selectProps={{
                                isSearchable: false,
                            }}
                            normalize={(option) => option ? option.value : null}
                            isDetermineValue
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <ControllerHF
                            name={'reference'}
                            control={control}
                            component={InputField}
                            id={'reference'}
                            label={t('reference')}
                            placeholder={t('reference')}
                        />
                    </Col>

                    <Col md={4}>
                        <ControllerHF
                            name={'number'}
                            control={control}
                            component={InputField}
                            id={'number'}
                            label={t('document-number')}
                            placeholder={t('document-number')}
                        />
                    </Col>

                    <Col md={4}>
                        <ControllerHF
                            name={'country_id'}
                            control={control}
                            component={ComboboxFormField}
                            id={'country_id'}
                            label={t('country')}
                            placeholder={t('country')}
                            options={props.countryOptions}
                            getOptionLabel={(option) => `${option.name}`}
                            getOptionValue={(option) => option.id}
                            selectProps={{
                                isClearable: true,
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <ControllerHF
                            name={'date_start'}
                            control={control}
                            component={DateInputField}
                            id={'date_start'}
                            label={t('date_start')}
                            placeholder={'DD/MM/YYYY'}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                        />
                    </Col>

                    <Col md={4}>
                        <ControllerHF
                            name={'date_end'}
                            control={control}
                            component={DateInputField}
                            id={'date_end'}
                            label={t('date_expiration')}
                            placeholder={'DD/MM/YYYY'}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            translateParams={{ date: t('date_start') }}
                        />
                    </Col>

                    <Col md={4}>
                        <ControllerHF
                            name={'reminder'}
                            control={control}
                            component={ComboboxFormField}
                            id={'reminder'}
                            label={t('reminder-date')}
                            placeholder={t('reminder-date')}
                            options={commonHelper.reminder.getReminderOptions()}
                            selectProps={{
                                isSearchable: false,
                                isClearable: true,
                            }}
                            normalize={(option) => option ? option.value : null}
                            isDetermineValue
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <ControllerHF
                            name={'details'}
                            control={control}
                            component={InputField}
                            id={'details'}
                            label={t('details')}
                            placeholder={t('details')}
                            type={'textarea'}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <FilesUploader
                            control={control}
                            processing={filesProcessing}
                            addFileToProcessing={addFileToProcessing}
                            removeFileFromProcessing={removeFileFromProcessing}
                            trigger={trigger}
                            setError={setError}
                            fileMaxSize={FILE_MAX_SIZE}
                            documentId={props.documentId}
                        />
                    </Col>
                </Row>

                <div className="mt-3">
                    <div className="button-items">
                        <Button
                            submit
                            title={'btn.save'}
                            disabled={isDisabled}
                            loading={props.loading}
                        />

                        <Button
                            title={'cancel'}
                            color="light"
                            outline
                            onClick={props.onCancel}
                        />
                    </div>
                </div>
            </form>

        </>
    );
};

DocumentForm.propTypes = {
    initialValues: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    countryOptions: PropTypes.array,
    documentId: PropTypes.number,

    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
}