import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { PROFILE_EDIT_GENERAL_INFO } from "./actionTypes"
import {
  profileEditGeneralInfoSuccess,
  profileEditGeneralInfoError,
} from "./actions"
import { userProfileUpdateData } from "../../user/profile/actions"

import { API } from "api"
import { LOCALE } from "services"

function* editInfo({ payload: { params } }) {
  try {
    const response = yield call(API.profile.editProfile, params)

    const { data } = response

    yield put(profileEditGeneralInfoSuccess())

    yield put(userProfileUpdateData({ ...data.data }))

    const { lang } = data.data
    if (i18n.language !== lang && lang) {
      LOCALE.changeLang(lang)
    }

    toastr.success(i18n.t("profile.edit.success"))
  } catch (error) {
    const errors = error.response?.data?.errors
      ? error.response.data.errors
      : {}
    const message = error.response?.data?.message
      ? error.response.data.message
      : i18n.t("error")

    yield put(
      profileEditGeneralInfoError({
        errors,
        message,
      })
    )
  }
}

export function* watchEditInfo() {
  yield takeEvery(PROFILE_EDIT_GENERAL_INFO, editInfo)
}

function* generalInfoSaga() {
  yield all([fork(watchEditInfo)])
}

export default generalInfoSaga
