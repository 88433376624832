import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"

import { MegaTagTitle } from "components"
import {
  ProfileContainer,
  ContactsContainer,
  FeaturesContainer,
  LogisticsContainer,
  DocumentsContainer,
  AgenciesContainer,
  WorkContainer,
  DeleteModel,
} from "./components/GeneralComponents"
import { usePanelTopBarOptions } from "hooks"
import { useFashionModelUpdateTour } from "features/fashion-model/hooks"
import { FashionModelUpdateTourProvider } from "features/fashion-model/contexts"
import { GeneralTour } from "features/tour-ui/components/Tour"

const GeneralComponent = props => {
  const panelOptions = useMemo(
    () => ({
      actions: (
        <>
          <DeleteModel />
        </>
      ),
    }),
    []
  )

  usePanelTopBarOptions(panelOptions)

  const modelUpdateTour = useFashionModelUpdateTour({})

  return (
    <FashionModelUpdateTourProvider {...modelUpdateTour}>
      <div className={"model-general"}>
        <MegaTagTitle title={"title.model.general"} />

        <Row>
          <Col xl={6} className={"mb-4"}>
            <ProfileContainer />
          </Col>

          <div className={"col-xl-6 col-xxl-3 mb-4"}>
            <ContactsContainer />
          </div>

          <div className={"col-xl-6 col-xxl-3 mb-4"}>
            <FeaturesContainer />
          </div>
        </Row>

        <Row>
          <Col xl={6} className={"mb-4"}>
            <AgenciesContainer />
          </Col>
          <Col xl={6} className={"mb-4"}>
            <LogisticsContainer />
          </Col>
        </Row>

        <Row>
          <Col xl={6} className={"mb-4"}>
            <DocumentsContainer />
          </Col>

          <Col xl={6} className={"mb-4"}>
            <WorkContainer />
          </Col>
        </Row>
      </div>

      <GeneralTour
        {...modelUpdateTour.tour?.state}
        callback={modelUpdateTour.callback}
        continuous
      />
    </FashionModelUpdateTourProvider>
  )
}

GeneralComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const General = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralComponent)
