import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"

import { Drawer } from "components"
import { EditFeaturesForm } from "./EditFeaturesForm"
import { FeaturesDataLoader } from "../FeaturesDataLoader"
import { requestHelper } from "helpers"
import { ValidationsUtils } from "utils"
import {
  modelPanelGeneralEditFeatures,
  modelPanelGeneralEditFeaturesCleanState,
} from "store/actions"
import { useFashionModelUpdateTourContext } from "features/fashion-model/contexts"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as TOUR_STEP_ALIAS } from "features/fashion-model/consts/tour"

const EditFeaturesComponent = props => {
  const { t } = useTranslation()

  const [measurementsOptions, setMeasurementsOptions] = useState({})

  useEffect(() => {
    if (props.visible) {
      fetchMeasurementsOptions()
    }
    return () => {
      if (props.visible) {
        setMeasurementsOptions({})
      }
    }
  }, [props.visible])

  const fetchMeasurementsOptions = async () => {
    try {
      const response = await requestHelper.staticData.getMeasurementsOptions()
      const { data } = response

      setMeasurementsOptions(data.data)
    } catch (e) {}
  }

  const onSubmitSuccess = () => {
    onDrawerClose()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelPanelGeneralEditFeatures(
      props.model.id,
      params,
      onSubmitSuccess,
      onSubmitError(onSubmitProps)
    )
  }

  const onDestroy = () => {
    props.modelPanelGeneralEditFeaturesCleanState()
  }

  const onDrawerClose = () => {
    props.onClose()

    if (appTourActive) {
      // finished
      stepComplete(TOUR_STEP_ALIAS.MEASUREMENTS_FORM_SAVE)
    }
  }

  const { appTourActive, stepComplete } = useFashionModelUpdateTourContext()

  return (
    <React.Fragment>
      <Drawer
        title={t("measurements_and_features")}
        visible={props.visible}
        onClose={onDrawerClose}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
        drawerProps={{}}
      >
        <FeaturesDataLoader>
          <Container fluid>
            <Row>
              <EditFeaturesForm
                initialValues={{
                  ...props.features,
                }}
                loading={props.loading}
                error={props.error}
                onSubmit={handleSubmit}
                onCancel={onDrawerClose}
                measurementsOptions={measurementsOptions}
                measurementsSystem={props.user.settings?.measurement_system}
              />
            </Row>
          </Container>
        </FeaturesDataLoader>
      </Drawer>
    </React.Fragment>
  )
}

EditFeaturesComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  model: PropTypes.object,
  features: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object,

  modelPanelGeneralEditFeatures: PropTypes.func,
  modelPanelGeneralEditFeaturesCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.modelPanel.general.features.data
  const { loading, error } = state.modelPanel.general.features.edit
  return {
    model: state.model.profile.data,
    features: data,
    loading,
    error,
    user: state.user.profile.data,
  }
}

const mapDispatchToProps = {
  modelPanelGeneralEditFeatures,
  modelPanelGeneralEditFeaturesCleanState,
}

export const EditFeatures = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFeaturesComponent)
