import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { CALENDAR_EVENT_NOTIFICATION_STATUS_EMAIL_READ as READ } from "consts"
import { EventCalendarIcon } from "components"

export const Models = ({ data }) => {
  const { t } = useTranslation()

  const renderNotificationStatus = item => (
    <EventCalendarIcon
      name={"check"}
      className={"font-size-18"}
      double={item.notification_status === READ}
    />
  )

  return (
    <div className="event-preview-models">
      {!!data.length ? (
        <div className="table-responsive">
          <table className="table table-nowrap">
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.notification_status && (
                      <span className={"me-2 align-middle"}>
                        {renderNotificationStatus(item)}
                      </span>
                    )}

                    <span className={"fw-medium"}>
                      {item?.name} {item?.surname}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        t("no_models")
      )}
    </div>
  )
}

Models.propTypes = {
  data: PropTypes.array,
}
