import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import {
  Button,
  CheckboxField,
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
} from "components"
import moment from "moment"
import _ from "lodash"
import { getMeasurementFields } from "pages/Models/components/ModelsPanelContainer/ModelsToolbar/DefaultControls/AddModel/helper"
import { modelHelper } from "helpers"
import { useFetchBookers } from "hooks"
import { useFashionModelCreateTourContext } from "features/fashion-model/contexts"

export const AddModelForm = props => {
  const { t } = useTranslation()

  const ethnicCategories = modelHelper.general.getEthnicCategories()

  const { data: bookers, fetchData: fetchBookers } = useFetchBookers()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      name: "",
      surname: "",
      gender: "",
      birthday: null,
      birthdayplace: "",
      nationality_id: "",
      is_eu: false,
      residence_id: "",
      website: "",
      in_agency_since: null,
      is_in_town: false,
      booker_id: "",
      ethnic: null,
      profile: {
        height: null,
        bust: null,
        waist: null,
        hips: null,
        shoes: null,
        hair_color: null,
        eyes_color: null,
      },
      ...props.initialValues,
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("field.error.required"),
      })
    ),
    mode: "onChange",
  })

  const onSubmit = values => {
    let params = {
      ...values,
      gender: values.gender.value,
      birthday: values.birthday
        ? moment(values.birthday).format("YYYY-MM-DD")
        : null,
      nationality_id: values.nationality_id.id,
      residence_id: values.residence_id.id,
      in_agency_since: values.in_agency_since
        ? moment(values.in_agency_since).format("YYYY-MM-DD")
        : null,
      booker_id: values.booker_id ? values.booker_id.id : null,
    }

    let profileValues = values.profile || {}
    for (let fieldName in profileValues) {
      let value
      if (profileValues.hasOwnProperty(fieldName)) {
        value = _.isObject(profileValues[fieldName])
          ? profileValues[fieldName].value
          : profileValues[fieldName]
        params = {
          ...params,
          profile: {
            ...params.profile,
            [fieldName]: value,
          },
        }
      }
    }
    props.onSubmit(params, { reset, setError })
  }

  const onCancel = () => {
    props.onCancel()
  }
  const measurementFields = useMemo(
    () => getMeasurementFields(props.measurementsOptions),
    [props.measurementsOptions]
  )
  useEffect(() => {
    fetchBookers()
  }, [])

  const { targetsRefs, goToStep, appTourActive } =
    useFashionModelCreateTourContext()

  useEffect(() => {
    if (appTourActive) {
      goToStep(1)
    }
  }, [appTourActive])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)} ref={targetsRefs.formRef}>
        <Row>
          <Col md={4}>
            <ControllerHF
              name="name"
              control={control}
              component={InputField}
              label={t("field.label.name")}
              placeholder={t("field.placeholder.name")}
              id={"name"}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              name="surname"
              control={control}
              component={InputField}
              label={t("field.label.surname")}
              placeholder={t("field.placeholder.surname")}
              id={"surname"}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"gender"}
              id={"gender"}
              label={t("gender")}
              placeholder={t("gender")}
              options={modelHelper.general.getGenderOptions()}
              selectProps={{
                isSearchable: false,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ControllerHF
              control={control}
              component={DateInputField}
              name="birthday"
              label={t("birthday")}
              placeholder={"DD/MM/YYYY"}
              id={"birthday"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="birthdayplace"
              label={t("birthplace")}
              placeholder={t("birthplace")}
              id={"birthdayplace"}
            />
          </Col>
          <Col md={4}>
            <ControllerHF
              name={"booker_id"}
              control={control}
              component={ComboboxFormField}
              id={"booker_id"}
              label={t("booker")}
              placeholder={t("booker")}
              options={bookers}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => option.id}
            />
          </Col>
        </Row>

        <Row>
          <Col md={8} lg={3}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"nationality_id"}
              id={"nationality_id"}
              label={t("nationality")}
              placeholder={t("nationality")}
              options={props.countryOptions}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => `${option.id}`}
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
          <Col md={4} lg={1}>
            <ControllerHF
              control={control}
              component={CheckboxField}
              name={"is_eu"}
              id={"is_eu"}
              label={t("eu")}
              wrapClass={"mb-3 form-checkbox-control"}
              size={"lg"}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"residence_id"}
              id={"residence_id"}
              label={t("residence_country")}
              placeholder={t("residence_country")}
              options={props.countryOptions}
              getOptionLabel={option => `${option.name}`}
              getOptionValue={option => `${option.id}`}
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={ComboboxFormField}
              name={"ethnic"}
              id={"ethnic"}
              label={t("ethnic_category")}
              placeholder={t("ethnic_category")}
              options={ethnicCategories}
              normalize={option => (option !== null ? option.value : null)}
              isDetermineValue
              selectProps={{
                isClearable: true,
              }}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={InputField}
              name="website"
              label={t("website")}
              placeholder={t("field.placeholder.website")}
              id={"website"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} lg={4}>
            <ControllerHF
              control={control}
              component={DateInputField}
              name="in_agency_since"
              label={t("in_agency_since")}
              placeholder={"DD/MM/YYYY"}
              id={"in_agency_since"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>

          <Col md={4} lg={4}>
            <ControllerHF
              control={control}
              component={CheckboxField}
              name={"is_in_town"}
              id={"is_in_town"}
              label={t("set_as_in_town")}
              wrapClass={"mb-3 form-checkbox-control"}
              size={"lg"}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />
        <Row>
          {measurementFields.map((item, i) => (
            <Col md={3} key={`m-${i}`}>
              <ControllerHF
                control={control}
                component={ComboboxFormField}
                name={item.name}
                id={item.name}
                label={t(item.label)}
                placeholder={t(item.placeholder)}
                options={item.options}
                getOptionLabel={option => `${option.text}`}
                selectProps={{
                  isClearable: true,
                }}
              />
            </Col>
          ))}
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button title={"cancel"} color="light" outline onClick={onCancel} />
          </div>
        </div>
      </form>
    </>
  )
}

AddModelForm.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,

  countryOptions: PropTypes.array,
  measurementsOptions: PropTypes.object,
  initialValues: PropTypes.object,
}
