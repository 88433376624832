import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import DatePicker from "react-datepicker"

export const DateRangePicker = ({
  value = [],
  onChange,
  dateFormat = "dd/MM/yyyy",
  placeholder,
  disabled,
  isClearable = true,
  ...props
}) => {
  const { t } = useTranslation()

  const [startValue, endValue] = value

  const [pickerValue, setPickerValue] = useState([startValue, endValue])
  const [pickerStartValue, pickerEndValue] = pickerValue

  useEffect(() => {
    const [startValue, endValue] = value
    setPickerValue([startValue, endValue])
  }, [value])

  const handleChange = range => {
    const [start, end] = range

    setPickerValue(range)

    if (start && end) {
      onChange(range)
    } else if (!start && !end && startValue && endValue) {
      onChange(range)
    }
  }

  const handleCalendarClose = () => {
    if (!pickerStartValue || !pickerEndValue) {
      handleChange([])
    }
  }

  const handleDateChangeRaw = e => {
    e.preventDefault()
  }

  return (
    <>
      <DatePicker
        selectsRange={true}
        startDate={pickerStartValue}
        endDate={pickerEndValue}
        onChangeRaw={handleDateChangeRaw}
        onChange={value => handleChange(value)}
        dateFormat={dateFormat}
        className={classNames("form-control", {
          "form-control-right-accessory": isClearable,
          [props.className]: props.className,
        })}
        placeholderText={placeholder}
        disabled={disabled}
        autoComplete="off"
        calendarStartDay={1}
        onCalendarClose={handleCalendarClose}
        isClearable={isClearable}
        {...props}
      />
    </>
  )
}

DateRangePicker.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
}
