import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Modal, ModalBody } from "reactstrap"

import videoUrl from "assets/videos/Giuliaformodels.mp4"
import s from "./IntroVideo.module.scss"

export const IntroVideo = ({ visible, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={visible}
      toggle={onClose}
      centered
      className={s.modal}
      contentClassName={s.modalContent}
      size={"lg"}
      zIndex={1051}
    >
      <ModalBody className={s.modalBody}>
        <div className={s.videoPlayer}>
          <video controls>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      </ModalBody>
    </Modal>
  )
}

IntroVideo.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}
