import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

import { TourProvider } from "features/tour-ui/contexts"

const FashionModelUpdateTourContext = createContext({
  tour: {},
  targetsRefs: {
    avatarEditRef: null,
    avatarUploadRef: null,
    profileEditRef: null,
    contactsEditRef: null,
  },
  targets: {
    profileEditFormTargetRef: null,
    contactsFormPhoneTargetRef: null,
    contactsFormEmailTargetRef: null,
    contactsFormSaveTargetRef: null,
    measurementsEditTargetRef: null,
    measurementsPrimaryFormSectionTargetRef: null,
    measurementsSecondaryFormSectionTargetRef: null,
    measurementsFormSaveTargetRef: null,
  },
  callback: () => {},
  interruptTour: () => {},
  successCompleteTour: data => {},
  appTourActive: false,
  stepComplete: index => {},
  runStep: index => {},
})

const FashionModelUpdateTourProvider = ({ children, tour, ...props }) => {
  return (
    <TourProvider value={{ ...tour }}>
      <FashionModelUpdateTourContext.Provider value={{ tour, ...props }}>
        {children}
      </FashionModelUpdateTourContext.Provider>
    </TourProvider>
  )
}

FashionModelUpdateTourProvider.propTypes = {
  children: PropTypes.any,
  tour: PropTypes.any,
}

const useFashionModelUpdateTourContext = () =>
  useContext(FashionModelUpdateTourContext)

export { FashionModelUpdateTourProvider, useFashionModelUpdateTourContext }
