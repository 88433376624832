import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { USER_NAVIGATION_ALIAS } from "features/user-navigation/consts/alias"
import s from "./CompletionStepsCheckList.module.scss"
import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { StartTourButton } from "./StartTourButton"

const {
  FASHION_MODEL,
  FASHION_MODEL_PHOTO,
  FASHION_MODEL_BOOK,
  FASHION_MODEL_COMPCARD,
  AGENCY,
  CLIENT,
  CALENDAR_EVENT_JOB,
} = USER_NAVIGATION_ALIAS

export const CompletionStepsCheckList = ({ data = {}, onStatTour }) => {
  const { t } = useTranslation()

  const items = [
    {
      label: t("user_navigation.steps.label.fashion_model"),
      active: data[FASHION_MODEL],
      tourAlias: APP_TOUR_ALIAS.FASHION_MODEL_UPDATE,
    },
    {
      label: t("user_navigation.steps.label.fashion_model_photo"),
      active: data[FASHION_MODEL_PHOTO],
      tourAlias: APP_TOUR_ALIAS.FASHION_MODEL_PHOTO,
    },
    {
      label: t("user_navigation.steps.label.fashion_model_book"),
      active: data[FASHION_MODEL_BOOK],
      tourAlias: APP_TOUR_ALIAS.FASHION_MODEL_BOOK,
    },
    {
      label: t("user_navigation.steps.label.fashion_model_compcard"),
      active: data[FASHION_MODEL_COMPCARD],
      tourAlias: APP_TOUR_ALIAS.FASHION_MODEL_COMPCARD,
    },
    {
      label: t("user_navigation.steps.label.agency"),
      active: data[AGENCY],
      tourAlias: APP_TOUR_ALIAS.AGENCY,
    },
    {
      label: t("user_navigation.steps.label.client"),
      active: data[CLIENT],
      tourAlias: APP_TOUR_ALIAS.CLIENT,
    },
    {
      label: t("user_navigation.steps.label.calendar_event_job"),
      active: data[CALENDAR_EVENT_JOB],
      tourAlias: APP_TOUR_ALIAS.CALENDAR_EVENT_JOB,
    },
  ]

  return (
    <ul className={s.list}>
      {items.map(({ label, active, tourAlias, tourIndexRoute }, index) => (
        <li className={s.listItem} key={index}>
          <div className={s.item}>
            <div className={s.itemBody}>
              <div
                className={classNames(`bg-soft ${s.itemIcon}`, {
                  ["bg-success"]: active,
                  ["bg-dark"]: !active,
                })}
              >
                <i
                  className={classNames("bx font-size-18", {
                    ["bx-check text-success"]: active,
                    ["bx-x text-white"]: !active,
                  })}
                />
              </div>
              <span>{label}</span>
            </div>

            <div>
              <StartTourButton
                onClick={() => onStatTour({ alias: tourAlias })}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

CompletionStepsCheckList.propTypes = {
  data: PropTypes.object,
  onStatTour: PropTypes.func,
}
