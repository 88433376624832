import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

import { PageIntro } from "../PageIntro"

export const CalendarLayout = ({ children, ...props }) => {
  return (
    <PageIntro>
      <div className="page-content">
        <Container fluid>{children}</Container>
      </div>
    </PageIntro>
  )
}

CalendarLayout.propTypes = {
  children: PropTypes.any,
}
