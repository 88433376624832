import React, { useEffect, useMemo, useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ACTIONS, EVENTS } from "react-joyride"
import { useHistory } from "react-router-dom"

import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { useAppTourState } from "features/app-tour/hooks"
import { useTour } from "features/tour-ui/hooks"
import { useTargets } from "./useTargets"

const { FASHION_MODEL_CREATE, FASHION_MODEL_UPDATE } = APP_TOUR_ALIAS
const alias = FASHION_MODEL_CREATE

const basePath = "/models"

export const useFashionModelCreateTour = ({}) => {
  const { t } = useTranslation()

  const history = useHistory()

  const [stepsState, setStepState] = useState({
    step1: false,
  })

  const onStepsStateUpdate = (data = {}) => {
    setStepState(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const { finishTour, startTour, state: appTourState } = useAppTourState()

  const onFinishAppTour = () => {
    finishTour()
  }

  const tour = useTour({
    onFinished: onFinishAppTour,
    onSkipped: onFinishAppTour,
  })

  const { initTour, onSetSteps, state, onRun, onStop, onStepIndexChange } = tour

  const { stepIndex } = state

  const appTourActive = useMemo(() => {
    return appTourState.active && appTourState.alias === alias
  }, [appTourState.active, appTourState.alias])

  const { targets, targetsRefs } = useTargets({
    appTourActive,
    onStepsStateUpdate,
  })

  const callback = (data = {}) => {
    tour.handleCallback(data)
  }

  const defaultStepOptions = {
    title: t("complete_profile"),
    disableBeacon: true,
    disableOverlayClose: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    hideBackButton: true,
    hideNextButton: true,
    hideCloseButton: true,
  }

  const getInitialSteps = useCallback(() => {
    if (appTourActive) {
      return [
        {
          ...defaultStepOptions,
          target: targets.createTargetRef.current || "body",
          content: t("app_tour.model_create.step_1.content"),
          spotlightClicks: true,
        },
        {
          ...defaultStepOptions,
          target: targets.formTargetRef.current || "body",
          content: t("app_tour.model_create.step_2.content"),
          placement: undefined,
          disableBeacon: false,
          placementBeacon: "left",
          hideCloseButton: false,
          spotlightClicks: true,
        },
      ]
    }
  }, [appTourActive, stepsState])

  useEffect(() => {
    if (appTourActive && stepsState.step1) {
      initTour({
        steps: getInitialSteps(),
      })
    }
  }, [appTourActive, stepsState.step1])

  const goToNextTour = useRef(false)

  useEffect(() => {
    return () => {
      if (appTourActive && !goToNextTour.current) {
        interruptTour()
      }
    }
  }, [appTourActive])

  const interruptTour = () => {
    tour.completeTour()

    onFinishAppTour()
  }

  const successCompleteTour = data => {
    const { model } = data ?? {}

    tour.completeTour()

    if (model) {
      const modelId = model?.id
      const route = `${basePath}/${modelId}/general`

      goToNextTour.current = true

      startTour({ alias: FASHION_MODEL_UPDATE })

      history.push(route)
    } else {
      onFinishAppTour()
    }
  }

  const stepComplete = index => {
    if (index === 0) {
      onStop()
      onStepIndexChange(1)
    }
  }

  const goToStep = index => {
    if (index === 1) {
      if (appTourActive) {
        onSetSteps(getInitialSteps())
        setTimeout(() => {
          onRun()
        }, 500)
      }
    }
  }

  return {
    tour,
    targetsRefs,
    callback,
    interruptTour,
    successCompleteTour,
    appTourActive,
    stepComplete,
    goToStep,
  }
}

useFashionModelCreateTour.propTypes = {}
