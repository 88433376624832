import React from "react"
import PropTypes from "prop-types"
import { AppLangPicker } from "components"

export const Header = ({ withLang = true }) => {
  return (
    <div className={"d-flex px-4"}>
      {withLang && (
        <div className={"d-flex my-2 w-100 justify-content-end"}>
          <AppLangPicker
            dropdownMenuProps={{ className: "dropdown-menu-end" }}
          />
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  withLang: PropTypes.bool,
}
