export const ECHO_EVENT = {
  UPDATE_SUBSCRIPTION_EVENT: "UpdateSubscriptionEvent",

  CHAT_MESSAGE_EVENT: "Chat\\ChatMessageEvent",
  CHAT_SEEN_EVENT: "Chat\\ChatSeenEvent",
  CHAT_TYPING_EVENT: "Chat\\ChatTypingEvent",

  CHAT_GROUP_CREATED_EVENT: "Chat\\ChatGroupCreatedEvent",
  CHAT_GROUP_UPDATED_EVENT: "Chat\\ChatGroupUpdatedEvent",
  CHAT_GROUP_REMOVED_EVENT: "Chat\\ChatGroupRemovedEvent",

  CHAT_GROUP_ADDED_FOR_USER_EVENT: "Chat\\ChatGroupAddedForUserEvent",
  CHAT_GROUP_REMOVED_FOR_USER_EVENT: "Chat\\ChatGroupRemovedForUserEvent",

  CHAT_GROUP_USER_ADDED_EVENT: "Chat\\ChatGroupUserAddedEvent",
  CHAT_GROUP_USER_REMOVED_EVENT: "Chat\\ChatGroupUserRemovedEvent",

  LOGIN_USER_EVENT: "LoginUserEvent",

  UPDATE_TAX_ID_EVENT: "UpdateTaxIdEvent",

  CLIENT_STATEMENT_ALERT_EVENT: "ClientStatementAlertEvent",

  UPDATE_BILLING_INVOICE_EVENT: "UpdateBillingInvoiceEvent",

  CALENDAR_POST_EVENT: "CalendarPostEvent",

  CREATE_WEBSITE_EVENT: "CreateWebsiteEvent",

  UPDATE_IMPORTER_EVENT: "UpdateImporterEvent",

  UPDATE_USER_NAVIGATION_EVENT: "UpdateUserNavigationEvent",
}
