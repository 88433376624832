import React, { useEffect, useMemo, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ACTIONS, EVENTS } from "react-joyride"

import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { useAppTourState } from "features/app-tour/hooks"
import { useTour } from "features/tour-ui/hooks"
import { useTargets } from "./useTargets"
import { EVENT_TYPE_JOB } from "consts"

const { CALENDAR_EVENT_JOB } = APP_TOUR_ALIAS
const alias = CALENDAR_EVENT_JOB

export const useEventsCalendarTour = ({}) => {
  const { t } = useTranslation()

  const [stepsState, setStepState] = useState({
    step1: false,
  })

  const onStepsStateUpdate = (data = {}) => {
    setStepState(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const { finishTour, startTour, state: appTourState } = useAppTourState()

  const onFinishAppTour = () => {
    finishTour()
  }

  const tour = useTour({
    onFinished: onFinishAppTour,
    onSkipped: onFinishAppTour,
  })

  const { initTour, onSetSteps, state, onRun, onStop, onStepIndexChange } = tour

  const { stepIndex } = state

  const appTourActive = useMemo(() => {
    return appTourState.active && appTourState.alias === alias
  }, [appTourState.active, appTourState.alias])

  const { targets, targetsRefs } = useTargets({
    appTourActive,
  })

  const callback = (data = {}) => {
    tour.handleCallback(data)

    const { action, size } = data

    if (action === ACTIONS.CLOSE && stepIndex === size - 1) {
      successCompleteTour()
    }
  }

  const defaultStepOptions = {
    title: t("event-type.job"),
    disableBeacon: true,
    disableOverlayClose: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    hideBackButton: true,
    hideNextButton: true,
    hideCloseButton: true,
  }

  const getInitialSteps = useCallback(() => {
    if (appTourActive) {
      return [
        {
          ...defaultStepOptions,
          target:
            targets.eventsTypesControlsTargetsRef.current?.[EVENT_TYPE_JOB] ||
            "body",
          content: t("app_tour.events-calendar.job.create.content"),
          spotlightClicks: true,
          disableScrolling: true,
        },
        {
          ...defaultStepOptions,
          target:
            targets.eventsTypesFormsTargetsRef.current?.[EVENT_TYPE_JOB] ||
            "body",
          content: t("app_tour.events-calendar.job.form.content"),
          placement: undefined,
          disableBeacon: false,
          placementBeacon: "left",
          hideCloseButton: false,
          spotlightClicks: true,
        },
        {
          ...defaultStepOptions,
          target: "body",
          content: t("finished"),
          placement: "center",
          hideCloseButton: false,
        },
      ]
    }
  }, [appTourActive])

  useEffect(() => {
    if (appTourActive) {
      initTour({
        steps: getInitialSteps(),
      })
    }
  }, [appTourActive])

  useEffect(() => {
    return () => {
      if (appTourActive) {
        interruptTour()
      }
    }
  }, [appTourActive])

  const interruptTour = () => {
    tour.completeTour()

    onFinishAppTour()
  }

  const successCompleteTour = () => {
    tour.completeTour()

    onFinishAppTour()
  }

  const stepComplete = index => {
    if (index === 0) {
      onStop()
      onStepIndexChange(1)
    } else if (index === 1) {
      onStepIndexChange(2)
    }
  }

  const goToStep = index => {
    if (index === 1) {
      if (appTourActive) {
        onSetSteps(getInitialSteps())
        setTimeout(() => {
          onRun()
        }, 500)
      }
    }
  }

  return {
    tour,
    targetsRefs,
    targets,
    callback,
    interruptTour,
    successCompleteTour,
    appTourActive,
    stepComplete,
    goToStep,
  }
}

useEventsCalendarTour.propTypes = {}
