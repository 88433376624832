import axios from "axios"

import { RequestUtils } from "utils"

class BillingAPI {
  setupIntent = async params => {
    return axios.get("api/billing/setup-intent", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  // Payment methods
  getPaymentMethods = async () => {
    return axios.get(`api/billing/payment-methods`)
  }

  addPaymentMethod = async params => {
    return axios.post(`api/billing/payment-methods`, params)
  }

  deletePaymentMethod = async id => {
    return axios.delete(`api/billing/payment-methods/${id}`)
  }

  sendDefaultPaymentMethod = async params => {
    return axios.post(`api/billing/payment-methods/default`, params)
  }
  // --- //

  // Billing info
  getBillingInfo = async () => {
    return axios.get("api/billing/billing-address")
  }

  editBillingInfo = async params => {
    return axios.put(`api/billing/billing-address`, params)
  }
  // --- //

  // Subscription
  subscriptionPay = async params => {
    return axios.post(`api/billing/subscription`, params)
  }

  getSubscriptionPaymentLink = async () => {
    return axios.get(`api/billing/subscription/payment-link`)
  }

  subscriptionDelete = async () => {
    return axios.delete(`api/billing/subscription`)
  }

  subscriptionResume = async () => {
    return axios.post(`api/billing/subscription/resume`)
  }

  subscriptionSwap = async params => {
    return axios.post(`api/billing/subscription/swap`, params)
  }
  // --- //

  // Tariffs
  getTariffs = async params => {
    return axios.get("api/billing/tariffs", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }
  // --- //

  // Invoices
  getInvoices = async params => {
    return axios.get("api/billing/invoices", {
      params,
      paramsSerializer: () => RequestUtils.paramsSerializer(params),
    })
  }

  getInvoice = async id => {
    return axios.get(`api/billing/invoices/${id}`)
  }
  // --- //

  // Countries
  getBillingInfoCountries = async () => {
    return axios.get(`api/billing/billing-address-countries`)
  }
  // --- //
}

export const billing = new BillingAPI()
