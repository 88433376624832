import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { useRefCallback } from "hooks"

export const useTargets = ({ appTourActive, onStepsStateUpdate }) => {
  const { t } = useTranslation()

  const photoAddTargetRef = useRef(null)
  const photoAddRef = useRefCallback(
    node => {
      photoAddTargetRef.current = node

      if (node !== null && appTourActive) {
        onStepsStateUpdate({ step1: true })
      }
    },
    [appTourActive]
  )

  const photoMultipleAddTargetRef = useRef(null)

  return {
    targetsRefs: {
      photoAddRef,
    },
    targets: {
      photoAddTargetRef,
      photoMultipleAddTargetRef,
    },
  }
}

useTargets.propTypes = {
  appTourActive: PropTypes.bool,
  onStepsStateUpdate: PropTypes.func,
}
