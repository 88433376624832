import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"

import { calendarEventData, calendarEventEditData } from "store/actions"
import { useEventsCalendarContext } from "contexts"
import { EVENTS_CALENDAR_DEFAULT_EVENT_FETCH_PARAMS } from "consts"
import * as HOC from "../../../HOC"

const DataShower = HOC.withDataShower()

const previewEventFetchParams = {
  includes: [
    ...EVENTS_CALENDAR_DEFAULT_EVENT_FETCH_PARAMS.includes,
    "eventModels.rates.fashionModelAgency.agency",
    "eventModels.rates.currency",
    "eventModels.expenses.currency",
    "eventModels.files.attachment",
    "invoice",
  ],
}

const EventDataLoaderComponent = props => {
  const dispatch = useDispatch()

  const { config: calendarConfig } = useEventsCalendarContext()

  const { data, loading, error } = useSelector(state =>
    props.isEdit ? state.calendarEvent.edit.data : state.calendarEvent.data
  )

  useEffect(() => {
    fetchData()

    return () => {
      if (props.isEdit) {
        dispatch(calendarEventEditData.cleanState())
      } else {
        dispatch(calendarEventData.cleanState())
      }
    }
  }, [])

  const fetchData = () => {
    if (props.isEdit) {
      const params = {
        ...calendarConfig.eventFetchParams,
      }
      dispatch(calendarEventEditData.getData({ id: props.id, params }))
    } else {
      const params = {
        ...previewEventFetchParams,
        ...calendarConfig.eventFetchParams,
      }
      dispatch(calendarEventData.getData({ id: props.id, params }))
    }
  }

  return (
    <DataShower isLoading={loading} isFailed={error} error={error}>
      {data && props.children}
    </DataShower>
  )
}

EventDataLoaderComponent.propTypes = {
  id: PropTypes.number,
  isEdit: PropTypes.bool,
  children: PropTypes.any,
}

const mapStateToProps = state => {
  return {}
}

export const EventDataLoader = connect(mapStateToProps)(
  EventDataLoaderComponent
)
