import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as TOUR_STEP_ALIAS } from "features/fashion-model/consts/tour"

import {
  Button,
  EmailsFieldArrayHF,
  PhonesCountriesFieldArrayHF,
  SocialNetworkFieldArrayHF,
} from "components"
import { FormatUtils, ValidationsUtils } from "utils"
import { INSTAGRAM } from "consts"
import { useFashionModelUpdateTourContext } from "features/fashion-model/contexts"

export const EditContactsForm = props => {
  const { t } = useTranslation()

  const onSubmit = values => {
    let params = {
      ...values,
    }

    params.phones = values.phones.map(item => {
      let result = {}

      if (item.id) {
        result.id = item.id
      }

      return {
        ...result,
        value: item.value.replace(/[^0-9]/g, ""),
        country_id: item.country?.id,
      }
    })

    props.onSubmit(params, { reset, setError })
  }

  const countryOptions = props.countryOptions

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      ...props.initialValues,
      phones: props.initialValues.phones.map(item => ({
        ...item,
        value: FormatUtils.formatPhone(item.value),
      })),
    },
    resolver: yupResolver(
      Yup.object().shape({
        phones: Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            value: Yup.string()
              .required("field.error.required")
              .test(
                "phone",
                "field.error.invalid",
                ValidationsUtils.phoneFormat
              ),
            country: Yup.object().nullable(),
          })
        ),
        emails: Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            value: Yup.string()
              .email("field.error.invalid")
              .required("field.error.required"),
          })
        ),
        social_networks: Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
            value: Yup.string().test({
              name: "socialNetwork",
              exclusive: false,
              params: {},
              message: "field.error.invalid",
              test: function (value) {
                return ValidationsUtils.socialNetwork(value, this.parent.name)
              },
            }),
          })
        ),
      })
    ),
    mode: "onBlur",
  })

  const { targets, runStep, appTourActive } = useFashionModelUpdateTourContext()

  useEffect(() => {
    if (appTourActive) {
      runStep(TOUR_STEP_ALIAS.CONTACTS_FORM_PHONE)
    }
  }, [appTourActive])

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form
        className="form-horizontal"
        noValidate={true}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div ref={targets.contactsFormPhoneTargetRef}>
          <Row>
            <PhonesCountriesFieldArrayHF
              name={"phones"}
              control={control}
              limit={5}
              countryOptions={countryOptions}
              meta={{}}
            />
          </Row>
        </div>

        <hr className="drawer-separator drawer-separator_form" />

        <div ref={targets.contactsFormEmailTargetRef}>
          <Row>
            <EmailsFieldArrayHF
              name={"emails"}
              control={control}
              limit={5}
              meta={{}}
            />
          </Row>
        </div>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <SocialNetworkFieldArrayHF
            name={"social_networks"}
            control={control}
            inputName={"value"}
            config={{
              [INSTAGRAM]: {
                hintText: "instagram-followers.info",
              },
            }}
          />
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              innerRef={targets.contactsFormSaveTargetRef}
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

EditContactsForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  countryOptions: PropTypes.array,
}
