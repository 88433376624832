import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Popover, OverlayBlockLoading } from "components"
import { usePopover } from "hooks"
import { CompletionStepsCheckList } from "./CompletionStepsCheckList"
import { CompletionStepsProgressBar } from "./CompletionStepsProgressBar"
import s from "./UserNavigationCompletionSteps.module.scss"
import { useAppTour } from "features/app-tour/hooks"

export const UserNavigationCompletionSteps = () => {
  const { t } = useTranslation()

  const { data } = useSelector(state => state.userNavigation.data)

  const { popoverId, toggle, popoverOpen } = usePopover({
    id: "completionSteps",
  })

  const stepsValues = useMemo(() => {
    let result = {
      value: 0,
      total: 0,
    }

    if (data) {
      const objectValues = Object.values(data)
      result = {
        value: objectValues.filter(item => item === true).length,
        total: objectValues.length,
      }
    }

    return result
  }, [data])

  const { onTourStat, loading } = useAppTour()

  const onStatTour = async ({ alias }) => {
    await onTourStat({ alias })

    toggle()
  }

  return (
    <>
      {data ? (
        <div className={s.wrap}>
          <CompletionStepsProgressBar
            id={popoverId}
            value={stepsValues.value}
            total={stepsValues.total}
          />

          <Popover
            popoverId={popoverId}
            popoverOpen={popoverOpen}
            toggle={toggle}
            placement={"bottom"}
          >
            <OverlayBlockLoading isLoading={loading}>
              <CompletionStepsCheckList data={data} onStatTour={onStatTour} />
            </OverlayBlockLoading>
          </Popover>
        </div>
      ) : null}
    </>
  )
}

UserNavigationCompletionSteps.propTypes = {}
