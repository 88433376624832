import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

import { TabNavigator } from "../../../Tabs"
import { PreviewSidebar } from "./PreviewSidebar"
import {
  DetailsContainer,
  ModelsContainer,
  AlertsContainer,
  RatesContainer,
  ExpensesContainer,
  AttachmentsContainer,
  InvoicesContainer,
} from "./Tabs"
import { EventInvoiceManager } from "components"
import { useEventInvoiceManager } from "../../EventInvoice/EventInvoiceManager/hooks"

const EVENT_DETAILS_TAB = "event_details"
const MODELS_TAB = "models"
const ALERTS_TAB = "alerts"
const RATES_TAB = "rates"
const EXPENSES_TAB = "expenses"
const ATTACHMENTS_TAB = "attachments"
const INVOICE_TAB = "invoice"

export const EventPreviewContainer = props => {
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(EVENT_DETAILS_TAB)

  const eventClosed = useMemo(() => {
    return props.event.is_closed
  }, [props.event.close])

  // Manage event invoice drawers (create, edit, view)
  const eventInvoiceManager = useEventInvoiceManager({
    initialState: {
      event: props.event,
    },
  })

  const onEventInvoiceCreate = () => {
    eventInvoiceManager.navigation.onScreenCreate()
  }

  const onEventInvoiceEdit = () => {
    eventInvoiceManager.navigation.onScreenEdit()
  }

  const onEventInvoiceView = () => {
    eventInvoiceManager.navigation.onScreenView()
  }

  const onEventInvoiceRemoveSuccess = () => {
    setActiveTab(EVENT_DETAILS_TAB)
  }
  // === //

  return (
    <>
      <Row>
        <Col md={8}>
          <div className={"pe-md-3"}>
            <TabNavigator
              activeTab={activeTab}
              onTabChange={tab => setActiveTab(tab)}
              destroyOnClose={true}
              tabs={[
                {
                  label: t("event_details"),
                  value: EVENT_DETAILS_TAB,
                  render: () => (
                    <DetailsContainer
                      event={props.event}
                      entityScreen={props.entityScreen}
                      entityId={props.entityId}
                    />
                  ),
                },
                {
                  label: t("models"),
                  value: MODELS_TAB,
                  render: () => <ModelsContainer event={props.event} />,
                },
                {
                  label: t("alerts"),
                  value: ALERTS_TAB,
                  render: () => <AlertsContainer event={props.event} />,
                  hidden: !props.event?.alerts?.length,
                },
                {
                  label: t("invoice"),
                  value: INVOICE_TAB,
                  render: () => (
                    <InvoicesContainer
                      event={props.event}
                      onEdit={onEventInvoiceEdit}
                      onView={onEventInvoiceView}
                      onRemoveSuccess={onEventInvoiceRemoveSuccess}
                    />
                  ),
                  hidden: !props.event?.invoice,
                },
                {
                  label: t("rates"),
                  value: RATES_TAB,
                  render: () => <RatesContainer event={props.event} />,
                  hidden: !eventClosed,
                },
                {
                  label: t("expenses"),
                  value: EXPENSES_TAB,
                  render: () => <ExpensesContainer event={props.event} />,
                  hidden: !eventClosed,
                },
                {
                  label: t("attachments"),
                  value: ATTACHMENTS_TAB,
                  render: () => <AttachmentsContainer event={props.event} />,
                  hidden: !eventClosed,
                },
              ]}
            />
          </div>
        </Col>

        <Col md={4} className={"col-divider_start col-divider_md"}>
          <div className={"pt-3 ps-md-3"}>
            <PreviewSidebar
              event={props.event}
              onEdit={props.onEdit}
              onSendEmail={props.onSendEmail}
              onRemoveSuccess={props.onRemoveSuccess}
              entityScreen={props.entityScreen}
              entityId={props.entityId}
              onEventCloseSuccess={props.onEventCloseSuccess}
              onEventReopenSuccess={props.onEventReopenSuccess}
              onEventInvoiceCreate={onEventInvoiceCreate}
              onEventDoneSuccess={props.onEventDoneSuccess}
            />
          </div>
        </Col>
      </Row>

      <EventInvoiceManager {...eventInvoiceManager} />
    </>
  )
}

EventPreviewContainer.propTypes = {
  event: PropTypes.object,
  onEdit: PropTypes.func,
  onSendEmail: PropTypes.func,
  onRemoveSuccess: PropTypes.func,
  entityScreen: PropTypes.string,
  entityId: PropTypes.number,
  onEventCloseSuccess: PropTypes.func,
  onEventReopenSuccess: PropTypes.func,
  onEventDoneSuccess: PropTypes.func,
}
