import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { PageHeader, MegaTagTitle } from "../../components"
import { Questionnaire as QuestionnaireModal } from "./components"
import { useModal, usePanelLayout } from "hooks"
import { IntroAppTourModal } from "features/app-tour/components/IntroAppTour"

const QuestionnaireComponent = props => {
  const { t } = useTranslation()

  const history = useHistory()

  const { modalRef, closeModal, showModal } = useModal({})

  const { onIntroVideoVisible } = usePanelLayout()

  const onQuestionnaireSuccess = () => {
    onIntroVideoVisible(true)

    showModal()
  }

  const onIntroAppTourClose = () => {
    closeModal()

    history.push("/calendar")
  }

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.questionnaire"} />

      <div className="page-content">
        <Container fluid>
          <PageHeader title={t("title.questionnaire")} />

          <QuestionnaireModal onSuccess={onQuestionnaireSuccess} />

          <IntroAppTourModal
            modalRef={modalRef}
            onClose={onIntroAppTourClose}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

QuestionnaireComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Questionnaire = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireComponent)
