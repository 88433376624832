import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { ConfirmPopup, Button } from "components"
import { useConfirmModal } from "hooks"
import { calendarEventReopen } from "store/actions"

const EventDeleteComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(calendarEventReopen.cleanState())
    }
  }, [])

  const onSuccess = data => {
    if (props.onSuccess) {
      const { id, is_closed } = data

      props.onSuccess({ id, is_closed })
    }
  }

  const { showModal, closeModal, handleConfirm, modalRef } = useConfirmModal(
    () => {
      dispatch(calendarEventReopen.reopen({ id: props.id, onSuccess }))
    },
    () => {}
  )

  return (
    <React.Fragment>
      <Button
        title={"reopen_event"}
        onClick={showModal}
        loading={props.loading}
        disabled={props.loading}
        btnClass={"w-100"}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"sure_event_reopen"}
      />
    </React.Fragment>
  )
}

EventDeleteComponent.propTypes = {
  id: PropTypes.number,
  onSuccess: PropTypes.func,

  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading } = state.calendarEvent.reopen
  return {
    loading,
  }
}

export const EventReopen = connect(mapStateToProps)(EventDeleteComponent)
