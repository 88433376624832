import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { Modal, Button } from "components"
import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { useAppTourState } from "features/app-tour/hooks"

const { FASHION_MODEL_CREATE } = APP_TOUR_ALIAS
const alias = FASHION_MODEL_CREATE

export const IntroAppTourModal = props => {
  const { t } = useTranslation()

  const history = useHistory()

  const { startTour } = useAppTourState()

  const onStart = () => {
    startTour({ alias })

    history.push("/models")
  }

  return (
    <Modal
      ref={props.modalRef}
      title={t("intro_app_tour.title")}
      strapModalProp={{
        centered: true,
        onClosed: props.onClose,
      }}
    >
      <h5>{t("intro_app_tour.content")}</h5>

      <div className="mt-4">
        <div className="button-items">
          <Button title={t("start_tour")} onClick={onStart} />

          <Button
            title={"cancel"}
            color="light"
            outline
            onClick={props.onClose}
          />

        </div>
      </div>

    </Modal>
  )
}

IntroAppTourModal.propTypes = {
  modalRef: PropTypes.any,
  onClose: PropTypes.func,
}
