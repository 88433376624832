import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Alert, Row, Col } from "reactstrap"
import { useTranslation, Trans } from "react-i18next"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import {
  FormikTextInputFiled as TextInputFiled,
  FormikPasswordInputFiled as PasswordInputFiled,
  FormikCheckboxFiled,
  Button,
} from "../../../../components"
import { registerUser, registerUserCleanState } from "store/actions"
import { useLang } from "hooks"

const passwordMinLength = 8

const RegisterFormComponent = props => {
  const { t } = useTranslation()

  const { lang } = useLang()

  useEffect(() => {
    return () => {
      props.registerUserCleanState()
    }
  }, [])

  const onSubmit = (values, onSubmitProps) => {
    const params = {
      ...values,
      lang,
    }
    props.registerUser(params, onSubmitProps)
  }

  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
        accept: false,
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("field.error.invalid")
          .required("field.error.required"),
        name: Yup.string().required("field.error.required"),
        password: Yup.string()
          .min(passwordMinLength, "field.error.password.length")
          .required("field.error.required"),
        password_confirmation: Yup.string()
          .required("field.error.required")
          .oneOf([Yup.ref("password"), null], "field.error.password.no.match")
          .min(passwordMinLength, "field.error.password.length"),
        accept: Yup.bool().oneOf(
          [true],
          "field.accept_terms_&_privacy.required"
        ),
      })}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnBlur={false}
    >
      {({ isSubmitting, ...rest }) => {
        return (
          <>
            {props.success && (
              <Alert color="success">{t("confirmation.email.sent")}</Alert>
            )}

            {props.registerError && (
              <Alert color="danger">{props.registerError.message}</Alert>
            )}

            <Form className="form-horizontal" noValidate={true}>
              <TextInputFiled
                name="email"
                label={t("field.label.email")}
                placeholder={t("field.placeholder.email")}
                id={"email"}
                type="email"
              />

              <TextInputFiled
                name="name"
                label={t("field.label.fullname")}
                placeholder={t("field.placeholder.fullname")}
                id={"name"}
                type="text"
              />

              <Row>
                <Col>
                  <PasswordInputFiled
                    name="password"
                    label={t("field.label.password")}
                    placeholder={t("field.placeholder.password")}
                    id={"password"}
                    translateParams={{ count: passwordMinLength }}
                  />
                </Col>

                <Col>
                  <PasswordInputFiled
                    name="password_confirmation"
                    label={t("field.label.confirm.password")}
                    placeholder={t("field.label.confirm.password")}
                    id={"password_confirmation"}
                    translateParams={{ count: passwordMinLength }}
                  />
                </Col>
              </Row>

              <FormikCheckboxFiled
                name={"accept"}
                id={"accept"}
                label={
                  <Trans
                    i18nKey="accept_terms_&_privacy.label"
                    components={{
                      linkTerms: (
                        <Link
                          to="/terms"
                          className="font-weight-medium text-secondary"
                        />
                      ),
                      linkPrivacy: (
                        <Link
                          to="/privacy"
                          className="font-weight-medium text-secondary"
                        />
                      ),
                    }}
                  />
                }
              />

              <div className="mt-3 d-grid">
                <Button
                  submit
                  title={"sign-up"}
                  disabled={isSubmitting || props.loading}
                  loading={props.loading}
                />
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

RegisterFormComponent.propTypes = {
  registerError: PropTypes.object,
  loading: PropTypes.bool,
  success: PropTypes.bool,

  registerUserCleanState: PropTypes.func,
  registerUser: PropTypes.func,
}

const mapStateToProps = state => {
  const { registerError, loading, success } = state.auth.register
  return {
    registerError,
    loading,
    success,
  }
}

const mapDispatchToProps = {
  registerUser,
  registerUserCleanState,
}

export const RegisterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterFormComponent)
