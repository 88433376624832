import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { ControllerHF, ExpensesField } from "components"
import { useDispatch, useSelector } from "react-redux"
import { modelHelper } from "helpers"
import { expenseEdit, expenseRemove, modelLogisticsExpenseAdd } from "store"

export const ExpensesSection = props => {
  const { t } = useTranslation()

  const expenseTypeOptions = modelHelper.expenses.getModelExpenseTypeOptions()

  const userCurrency = useSelector(
    state => state.user.profile.data.settings.currency
  )
  const { data: logistics } = useSelector(
    state => state.modelPanel.general.logistics.details.data
  )
  const dispatch = useDispatch()

  const handleEdit = (data, params) => {
    dispatch(
      expenseEdit.edit({
        id: data.id,
        params: data,
        onSuccess: params.onSuccess,
        onError: params.onError,
      })
    )
  }

  const handleRemove = (data, params) => {
    dispatch(
      expenseRemove.remove({
        id: data.id,
        onSuccess: params.onSuccess,
      })
    )
  }
  const handleAdd = (data, params) => {
    dispatch(
      modelLogisticsExpenseAdd.add({
        id: logistics.id,
        params: data,
        onSuccess: params.onSuccess,
        onError: params.onError,
      })
    )
  }

  return (
    <Row>
      <Col md={8}>
        <ControllerHF
          async={!!logistics}
          name={"expenses"}
          control={props.control}
          component={ExpensesField}
          id={"expenses"}
          placeholder={t("expense")}
          currency={userCurrency}
          className={"mt-3"}
          expenseTypeOptions={expenseTypeOptions}
          onEdit={handleEdit}
          onRemove={handleRemove}
          onAdd={handleAdd}
          addBtnText={"add-expenses-rates"}
        />
      </Col>
    </Row>
  )
}

ExpensesSection.propTypes = {
  control: PropTypes.object,
}
