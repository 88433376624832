import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Container, Row } from "reactstrap"
import { Drawer } from "components"
import { modelHelper } from "helpers"
import {
  modelPanelGeneralEditProfile,
  modelPanelGeneralEditProfileCleanState,
} from "store/actions"
import { useCountryOptions } from "hooks"
import { ValidationsUtils } from "utils"
import { EditProfileForm } from "./EditProfileForm"
import { useFashionModelUpdateTourContext } from "features/fashion-model/contexts"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as TOUR_STEP_ALIAS } from "features/fashion-model/consts/tour"

const EditProfileComponent = props => {
  const { t } = useTranslation()
  const countryOptions = useCountryOptions({ visible: props.visible })

  const onSubmitSuccess = onSubmitProps => () => {
    const { reset } = onSubmitProps
    reset()
    handleCloseDrawer()
  }

  const onSubmitError = onSubmitProps => errors => {
    const { setError } = onSubmitProps
    ValidationsUtils.setServerSideErrors(errors, setError)
  }

  const handleSubmit = (params, onSubmitProps) => {
    props.modelPanelGeneralEditProfile(
      props.model.id,
      params,
      onSubmitSuccess(onSubmitProps),
      onSubmitError(onSubmitProps)
    )
  }
  const onDestroy = () => {
    props.modelPanelGeneralEditProfileCleanState()
  }

  const onDrawerClose = () => {
    props.onClose()
  }

  const { stepComplete, appTourActive } = useFashionModelUpdateTourContext()

  const handleCloseDrawer = () => {
    if (appTourActive) {
      stepComplete(TOUR_STEP_ALIAS.PROFILE_EDIT_FORM)
    }

    onDrawerClose()
  }

  return (
    <React.Fragment>
      <Drawer
        title={t("general")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container"}
        destroyOnClose
        onDestroy={onDestroy}
      >
        <Container fluid>
          <Row>
            <EditProfileForm
              initialValues={{
                ...props.model,
                gender: modelHelper.general.getGender(props.model.gender),
                tax_residence: modelHelper.general.getTaxResidenceBool(
                  props.model.tax_residence
                ),
              }}
              loading={props.loading}
              error={props.error}
              onSubmit={handleSubmit}
              onCancel={handleCloseDrawer}
              countryOptions={countryOptions}
            />
          </Row>
        </Container>
      </Drawer>
    </React.Fragment>
  )
}

EditProfileComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  model: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  modelPanelGeneralEditProfile: PropTypes.func,
  modelPanelGeneralEditProfileCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading, error } = state.modelPanel.general.profile.edit
  return {
    model: data,
    loading,
    error,
  }
}

const mapDispatchToProps = {
  modelPanelGeneralEditProfile,
  modelPanelGeneralEditProfileCleanState,
}

export const EditProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileComponent)
