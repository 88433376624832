import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

const TourContext = createContext(null)

const TourProvider = ({ children, ...props }) => {
  return (
    <TourContext.Provider value={{ ...props }}>{children}</TourContext.Provider>
  )
}

TourProvider.propTypes = {
  children: PropTypes.any,
}

const useTourContext = () => useContext(TourContext)

export { TourProvider, useTourContext }
