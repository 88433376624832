import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import VerticalLayout from "../../VerticalLayout"
import { PanelLayoutIntroProvider, PanelLayoutProvider } from "contexts"

const PanelLayoutComponent = props => {
  return (
    <PanelLayoutProvider>
      <PanelLayoutIntroProvider>
        <VerticalLayout>{props.children}</VerticalLayout>
      </PanelLayoutIntroProvider>
    </PanelLayoutProvider>
  )
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

PanelLayoutComponent.propTypes = {
  children: PropTypes.node,
}

export const PanelLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelLayoutComponent)
