import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { API } from "api"
import { calendarEventReopen } from "./slice"

function* reopen({ payload: { id, onSuccess } }) {
  try {
    const response = yield call(API.calendar.reopenEvent, id)

    const { data } = response

    yield put(calendarEventReopen.reopenSuccess())
    if (onSuccess) {
      onSuccess(data?.data)
    }
    toastr.success(i18n.t("event.reopen.success"))
  } catch (error) {
    const errors = error.response?.data?.errors || {}
    const message = error.response?.data?.message || i18n.t("error")

    yield put(
      calendarEventReopen.reopenError({
        errors,
        message,
      })
    )
    toastr.error(message)
  }
}

export function* watchReopen() {
  yield takeEvery(calendarEventReopen.reopen, reopen)
}

function* reopenSaga() {
  yield all([fork(watchReopen)])
}

export default reopenSaga
