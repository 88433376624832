import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useDeepCompareEffect, useFirstMountState } from "react-use"
import { ConfirmPopup, GridDataLoader, PageHeader } from "components"
import {
  modelsPanelFetchList,
  modelsPanelListResetFetchedData,
  modelsPanelListSetPage,
  modelsPanelState,
  removeMemberFromGroup,
} from "store/actions"
import { useConfirmModal, useSelectionRegular } from "hooks"
import { ModelsToolbar } from "./ModelsToolbar"
import { ModelsGridItem } from "./ModelsGridItem"
import { selectModelsPanelState, selectModelsPanelListSyncCount } from "store"
import { FashionModelCreateTourProvider } from "features/fashion-model/contexts"
import { useFashionModelCreateTour } from "features/fashion-model/hooks"
import { GeneralTour } from "features/tour-ui/components/Tour"

const ModelsPanelContainerComponent = props => {
  const { t } = useTranslation()
  const isFirstMount = useFirstMountState()
  const dispatch = useDispatch()
  const {
    isDefaultState,
    isEditGroupState,
    isAddToGroupState,
    isCreateGroupState,
  } = useSelector(selectModelsPanelState)

  //=================SELECTION LOGIC =======================//

  const { selectionValue, selectionToggleSelect, selectionClear } =
    useSelectionRegular({})

  useEffect(() => {
    if (isDefaultState || isEditGroupState) {
      selectionClear()
    }
  }, [isDefaultState, isEditGroupState])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      if (isEditGroupState && !props.filters.values.group) {
        dispatch(modelsPanelState.setDefaultState())
      }
    }
  }, [props.filters.values, isEditGroupState])
  //========================================================//
  useEffect(() => {
    fetchData()
    return () => {
      props.modelsPanelListResetFetchedData()
      dispatch(modelsPanelState.setDefaultState())
    }
  }, [])

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      fetchData()
    }
  }, [props.page, props.filters.values, props.syncCount])

  const fetchData = () => {
    let params = {
      page: props.page,
      per_page: 12,
    }

    props.modelsPanelFetchList(params)
  }

  const handlePageChange = item => {
    props.modelsPanelListSetPage(item.selected + 1)
  }

  const handleModelsDataChange = () => {
    fetchData()
    selectionClear()
  }

  const { showModal, closeModal, handleConfirm, modalRef } = useConfirmModal(
    item => {
      dispatch(
        removeMemberFromGroup.remove({
          modelId: item.id,
          groupId: props.filters.values.group,
          onSuccess: fetchData,
        })
      )
    },
    () => dispatch(removeMemberFromGroup.cleanState())
  )

  const modelCreateTour = useFashionModelCreateTour({})

  return (
    <React.Fragment>
      <FashionModelCreateTourProvider {...modelCreateTour}>
        <PageHeader
          title={t("model")}
          headerRight={
            <div className={"d-flex"}>
              <ModelsToolbar
                selectedModels={selectionValue}
                fetchData={fetchData}
                onModelsDataChange={handleModelsDataChange}
                group={props.filters.values.group}
              />
            </div>
          }
        />
        <GridDataLoader
          loading={props.listLoading}
          error={props.listError}
          meta={props.meta}
          page={props.page}
          onPageChange={handlePageChange}
          className={"models-panel-grid mb-4"}
          items={props.list}
          renderItem={item => (
            <ModelsGridItem
              item={item}
              selected={selectionValue.includes(item.id)}
              toggleSelected={selectionToggleSelect}
              isSelectMode={isAddToGroupState || isCreateGroupState}
              isEditGroupMode={isEditGroupState}
              onRemoveFromGroup={showModal}
            />
          )}
        />
        <ConfirmPopup
          ref={modalRef}
          onConfirm={handleConfirm}
          onClosed={closeModal}
          questionText={"sure-remove-from-group"}
        />

        <GeneralTour
          {...modelCreateTour.tour?.state}
          callback={modelCreateTour.callback}
          continuous
        />
      </FashionModelCreateTourProvider>
    </React.Fragment>
  )
}

ModelsPanelContainerComponent.propTypes = {
  list: PropTypes.array,
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  meta: PropTypes.object,
  page: PropTypes.any,
  filters: PropTypes.object,
  syncCount: PropTypes.number,

  modelsPanelFetchList: PropTypes.func,
  modelsPanelListSetPage: PropTypes.func,
  modelsPanelListResetFetchedData: PropTypes.func,
  modelsPanelView: PropTypes.object,
}

const mapStateToProps = state => {
  const { list, listLoading, listError, meta, page, filters } =
    state.models.panel.list.data

  const syncCount = selectModelsPanelListSyncCount(state)
  return {
    list,
    listLoading,
    listError,
    meta,
    page,
    filters,
    syncCount,
  }
}

const mapDispatchToProps = {
  modelsPanelFetchList,
  modelsPanelListResetFetchedData,
  modelsPanelListSetPage,
  modelsPanelView: modelsPanelState,
}

export const ModelsPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelsPanelContainerComponent)
