import React, { useEffect } from "react"
import PropTypes from "prop-types"

import {
  ServiceDetailsSection,
  FromToSection,
  ExpensesSection,
} from "../FormsSections"

export const BasicForm = props => {
  useEffect(() => {
    props.reset()
  }, [])

  return (
    <>
      <ServiceDetailsSection control={props.control} />

      <hr className="drawer-separator drawer-separator_form" />

      <FromToSection control={props.control} />

      <hr className="drawer-separator drawer-separator_form" />

      <ExpensesSection control={props.control} />
    </>
  )
}

BasicForm.propTypes = {
  control: PropTypes.object,
  reset: PropTypes.func,
}
