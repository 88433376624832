import { takeEvery, fork, all, call } from "redux-saga/effects"
import toastr from "toastr"

import i18n from "i18n"
import { socketOnChatGroupCreated } from "./actions"
import { onChatAdd } from "../../../../chat/chats/list/saga"

function* onCreate({ payload: { data } }) {
  const { chat } = data

  yield call(onChatAdd, {
    payload: {
      chat: {
        ...chat,
        last_message: null,
      },
    },
  })

  toastr.success(i18n.t("chat.group.created"))
}

export function* watchOnCreate() {
  yield takeEvery(socketOnChatGroupCreated, onCreate)
}

function* createSaga() {
  yield all([fork(watchOnCreate)])
}

export default createSaga
