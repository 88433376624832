import React from "react"
import PropTypes from "prop-types"

export const BlankLayout = ({ header, children }) => {
  return (
    <React.Fragment>
      {header}

      {children}
    </React.Fragment>
  )
}

BlankLayout.propTypes = {
  header: PropTypes.any,
  children: PropTypes.object,
}
