import React from "react"
import toastr from "toastr"
import { useTranslation } from "react-i18next"

import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { useFetchData } from "hooks"
import { API } from "api"

const {
  FASHION_MODEL_CREATE,
  FASHION_MODEL_UPDATE,
  FASHION_MODEL_PHOTO,
  FASHION_MODEL_BOOK,
  FASHION_MODEL_COMPCARD,
} = APP_TOUR_ALIAS

const basePath = "/models"

export const useModel = () => {
  const { t } = useTranslation()

  const getRoute = (alias, modelId) => {
    let path = basePath

    if (modelId) {
      let route = ""
      if (alias === FASHION_MODEL_UPDATE) {
        route = "general"
      } else if (
        [FASHION_MODEL_PHOTO, FASHION_MODEL_COMPCARD].includes(alias)
      ) {
        route = "photo"
      } else if (alias === FASHION_MODEL_BOOK) {
        route = "book"
      }

      path = `${path}/${modelId}/${route}`
    }

    return path
  }

  const { fetchData, loading } = useFetchData()

  const onFetch = async () => {
    const params = {
      pagination: false,
    }
    const request = API.model.getModelList(params)

    try {
      const data = await fetchData({
        request,
      })

      return data.data
    } catch (error) {
      const message = error.response?.data?.message || t("error")
      toastr.error(message)
    }
  }

  const getModelTourConfig = async alias => {
    let route = ""
    let tourAlias = alias

    const items = await onFetch()

    if (items && !!items.length) {
      const modelId = items[0]?.id

      if (modelId) {
        route = getRoute(alias, modelId)
      }
    } else {
      route = getRoute(alias)
      tourAlias = FASHION_MODEL_CREATE
    }

    return {
      route,
      tourAlias,
    }
  }

  return {
    getModelTourConfig,
    loading,
  }
}
