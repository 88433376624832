import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"

import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, useHistory, withRouter } from "react-router-dom"

import { logoutUser } from "store/actions"

import avatar from "../../../assets/images/dev/avatar-placeholder.png"
import { ROLE_DIRECTOR, ROLE_EMPLOYEE } from "../../../consts"
import { Image } from "components"

const ProfileMenu = props => {
  const history = useHistory()

  const [menu, setMenu] = useState(false)

  const logOut = e => {
    e.preventDefault()

    props.logoutUser({})
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-inline-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="image-rounded image-rounded__xs">
            <Image src={props.user.avatar || avatar} alt="user-avatar" />
          </div>
          <span className="d-none d-xl-inline-block ms-2">
            {props.user?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link
            to="/profile"
            className="dropdown-item"
            onClick={() => setMenu(false)}
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("profile")}</span>
          </Link>

          {props.user?.role === ROLE_DIRECTOR && (
            <Link
              to={"/billing/general"}
              className="dropdown-item"
              onClick={() => setMenu(false)}
            >
              <i className="bx bx-wallet font-size-16 align-middle me-1" />
              <span>{props.t("billing_portal")}</span>
            </Link>
          )}

          <div className="dropdown-divider" />

          <Link to="#" onClick={logOut} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  t: PropTypes.any,

  user: PropTypes.object,

  logoutUser: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.user.profile
  return {
    user: data,
  }
}

const mapDispatchToProps = {
  logoutUser,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileMenu))
)
