import React, { useCallback, useState, useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { TooltipWrapper } from "components"
import s from "./AnalyticsItem.module.scss"
import { FormatUtils } from "utils"
import { useAnalyticFetch } from "features/analytics/hooks"

import * as HOC from "HOC"
import { GraphsPeriodControls, IconButton, InfoCard } from "components"
import {
  EVENT_TYPE_JOB,
  EVENT_TYPE_CASTING,
  GRAPH_PERIOD_TYPE,
  MODELS_CASTINGS,
  MODELS_EARNINGS,
  MODELS_JOBS,
} from "consts"
import { CommonUtils } from "utils"
import { analyticsHelper, commonHelper } from "helpers"
import variables from "assets/scss/giulia-variables.module.scss"

const DataShower = HOC.withDataShowerOverlay()

const defaultPeriodType = GRAPH_PERIOD_TYPE.MONTH
const { getPeriodData } = analyticsHelper.period

export const AnalyticsItem = ({ className, alias }) => {
  const { t } = useTranslation()

  const [filterVisible, setFilterVisible] = useState(false)

  const onFilterVisibleToggle = status => {
    if (!CommonUtils.isNil(status)) {
      setFilterVisible(status)
    } else {
      setFilterVisible(prevState => !prevState)
    }
  }

  const { data, loading, error, onFetch } = useAnalyticFetch()

  const fetchData = params => {
    onFetch(alias, params)
  }

  useEffect(() => {
    let { params } = getPeriodData(defaultPeriodType)
    fetchData(params)
  }, [])

  const onPeriodChange = useCallback(
    params => {
      fetchData(params)
    },
    [fetchData]
  )

  const domElementId = `analytics-item-${alias}`

  const value = useMemo(() => {
    const item = data?.data

    let result

    if (alias === MODELS_JOBS) {
      result = item?.job
    } else if (alias === MODELS_CASTINGS) {
      result = item?.casting
    } else if (alias === MODELS_EARNINGS) {
      result = item?.earnings
    }

    return !CommonUtils.isNil(result) ? result : ""
  }, [data?.data])

  const eventColors = useSelector(
    state => state.user.options.data.data.event_colors
  )

  const getColor = type => {
    return commonHelper.colors.getColorFromEntity(eventColors[type])
  }

  const color = useMemo(() => {
    if (alias === MODELS_JOBS) {
      return getColor(EVENT_TYPE_JOB)
    } else if (alias === MODELS_CASTINGS) {
      return getColor(EVENT_TYPE_CASTING)
    } else if (alias === MODELS_EARNINGS) {
      return variables.warningColor
    }
    return ""
  }, [alias])

  return (
    <InfoCard
      actionSection={
        <IconButton
          wrapClass={"ms-2"}
          name={"filter"}
          onClick={() => onFilterVisibleToggle()}
        />
      }
    >
      {filterVisible && (
        <div className={"mb-2 chart-card__controller"}>
          <GraphsPeriodControls
            id={"analytics-controls"}
            defaultPeriodType={defaultPeriodType}
            onChange={onPeriodChange}
          />
        </div>
      )}

      <DataShower isLoading={loading} isFailed={error} error={error?.message}>
        <div
          className={"d-flex gap-2 flex-wrap flex-grow-1 align-items-center"}
        >
          <div className={"flex-grow-1 d-flex text-muted"}>
            {t(`analytics_graph_name.alias.${alias}`)}
          </div>

          <div>
            <div
              className={`${s.circle}`}
              style={{ borderColor: color, color }}
            >
              <TooltipWrapper target={domElementId} content={value}>
                <span id={domElementId}>
                  {value && FormatUtils.formatNumberToCompact(value)}
                </span>
              </TooltipWrapper>
            </div>
          </div>
        </div>
      </DataShower>
    </InfoCard>
  )
}

AnalyticsItem.propTypes = {
  className: PropTypes.string,
  alias: PropTypes.string,
}
