import React, { useMemo } from "react"
import PropTypes from "prop-types"

import {
  CalendarGeneralEventContent,
  CalendarGeneralModelEventContent,
} from "../../EventsCalendarEventContent"
import { GENERAL_CALENDAR_ENTITY } from "consts"

const {
  MODEL: ENTITY_MODEL,
  AGENCY: ENTITY_AGENCY,
  CLIENT: ENTITY_CLIENT,
} = GENERAL_CALENDAR_ENTITY

export const GeneralProvider = ({
  eventInfo,
  eventContent,
  entity = null,
  entityData = null,
}) => {
  const {
    event: { title, extendedProps },
  } = eventInfo

  const contentData = useMemo(() => {
    return {
      title: title,
      subTitle: extendedProps.product,
      times: extendedProps.times,
      active: extendedProps.active,
      notificationStatus: extendedProps.notification_status,
      closed: extendedProps.is_closed,
      done: extendedProps.done,
    }
  }, [extendedProps, title])

  switch (entity) {
    case ENTITY_MODEL:
      return (
        <CalendarGeneralModelEventContent
          eventInfo={eventInfo}
          contentData={contentData}
          model={entityData}
        />
      )

    default:
      return (
        <>
          {eventContent ? (
            eventContent(eventInfo, contentData)
          ) : (
            <CalendarGeneralEventContent {...contentData} />
          )}
        </>
      )
  }
}

GeneralProvider.propTypes = {
  eventInfo: PropTypes.object,
  eventContent: PropTypes.func,
  entity: PropTypes.oneOf([ENTITY_MODEL, ENTITY_AGENCY, ENTITY_CLIENT]),
  entityData: PropTypes.object,
}
