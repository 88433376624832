import React from "react"
import { Redirect } from "react-router-dom"

import {
  Register,
  Login,
  ForgetPassword,
  ResetPassword,
  RegisterConfirmed,
  Terms,
  Privacy,
  Profile,
  QuestionnaireIndex,
  ModelsIndex,
  DashboardIndex,
  CalendarIndex,
  AgenciesIndex,
  PackagesIndex,
  ClientsIndex,
  BillingIndex,
  ChatIndex,
} from "../pages"

import {
  ROUTE_MIDDLEWARE_USER_SETTINGS,
  ROUTE_MIDDLEWARE_SUBSCRIPTION,
  ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
} from "consts"

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/register/confirmed", component: RegisterConfirmed },
  { path: "/register", component: Register },
]

const directorRoutes = [
  { path: "/questionnaire", component: QuestionnaireIndex, middlewares: [] },
  {
    path: "/dashboard",
    component: DashboardIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  { path: "/profile", component: Profile, middlewares: [] },
  {
    path: "/calendar",
    component: CalendarIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  {
    path: "/models",
    component: ModelsIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  {
    path: "/agencies",
    component: AgenciesIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  // {
  //   path: "/analytics",
  //   component: AnalyticsIndex,
  //   middlewares: [
  //     ROUTE_MIDDLEWARE_USER_SETTINGS,
  //     ROUTE_MIDDLEWARE_SUBSCRIPTION,
  //     ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
  //   ],
  // },
  {
    path: "/packages",
    component: PackagesIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  {
    path: "/clients",
    component: ClientsIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  // {
  //   path: "/website",
  //   component: WebsiteIndex,
  //   middlewares: [
  //     ROUTE_MIDDLEWARE_USER_SETTINGS,
  //     ROUTE_MIDDLEWARE_SUBSCRIPTION,
  //     ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
  //   ],
  // },
  {
    path: "/billing",
    component: BillingIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  {
    path: "/chat",
    component: ChatIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  // {
  //   path: "/social-media-calendar",
  //   component: SocialMediaCalendarIndex,
  //   middlewares: [
  //     ROUTE_MIDDLEWARE_USER_SETTINGS,
  //     ROUTE_MIDDLEWARE_SUBSCRIPTION,
  //     ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
  //   ],
  // },
  // eslint-disable-next-line react/display-name
  { path: "*", exact: true, component: () => <Redirect to="/calendar" /> },
]

const employeeRoutes = [
  { path: "/questionnaire", component: QuestionnaireIndex, middlewares: [] },
  {
    path: "/dashboard",
    component: DashboardIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  { path: "/profile", component: Profile, middlewares: [] },
  {
    path: "/calendar",
    component: CalendarIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  {
    path: "/models",
    component: ModelsIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  {
    path: "/agencies",
    component: AgenciesIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  // {
  //   path: "/analytics",
  //   component: AnalyticsIndex,
  //   middlewares: [
  //     ROUTE_MIDDLEWARE_USER_SETTINGS,
  //     ROUTE_MIDDLEWARE_SUBSCRIPTION,
  //     ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
  //   ],
  // },
  {
    path: "/packages",
    component: PackagesIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  {
    path: "/clients",
    component: ClientsIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  // {
  //   path: "/website",
  //   component: WebsiteIndex,
  //   middlewares: [
  //     ROUTE_MIDDLEWARE_USER_SETTINGS,
  //     ROUTE_MIDDLEWARE_SUBSCRIPTION,
  //     ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
  //   ],
  // },
  {
    path: "/chat",
    component: ChatIndex,
    middlewares: [
      ROUTE_MIDDLEWARE_USER_SETTINGS,
      ROUTE_MIDDLEWARE_SUBSCRIPTION,
      ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
    ],
  },
  // {
  //   path: "/social-media-calendar",
  //   component: SocialMediaCalendarIndex,
  //   middlewares: [
  //     ROUTE_MIDDLEWARE_USER_SETTINGS,
  //     ROUTE_MIDDLEWARE_SUBSCRIPTION,
  //     ROUTE_MIDDLEWARE_COMPANY_DEACTIVATED,
  //   ],
  // },
  // eslint-disable-next-line react/display-name
  { path: "*", exact: true, component: () => <Redirect to="/calendar" /> },
]

const utilityRoutes = [
  { path: "/terms", component: Terms },
  { path: "/privacy", component: Privacy },
]

export { publicRoutes, directorRoutes, employeeRoutes, utilityRoutes }
