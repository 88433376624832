import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { IconButton } from "../Buttons"

export const InfoCard = ({
  title,
  editMode,
  onEdit,
  children,
  className,
  editBtn,
  addMode,
  addBtn,
  onAdd,
  absolute,
  actionSection,
  editInnerRef,
}) => {
  const { t } = useTranslation()

  const handleEdit = () => {
    onEdit()
  }

  const handleAdd = e => {
    e.preventDefault()
    onAdd()
  }

  return (
    <div
      className={classNames("info-card", {
        [className]: className,
      })}
    >
      {title && (
        <div className={"info-card__title-block"}>
          <div className={"info-card__title"}>{title}</div>

          {editMode &&
            (editBtn || (
              <div>
                <IconButton
                  name={"edit"}
                  onClick={handleEdit}
                  wrapClass={"ms-1"}
                  bg
                  innerRef={editInnerRef}
                />
              </div>
            ))}

          {addMode &&
            (addBtn || (
              <div>
                <Link
                  to="#"
                  className="text-secondary text-decoration-underline info-card__add-btn"
                  onClick={handleAdd}
                >
                  + {t("btn.add")}
                </Link>
              </div>
            ))}
        </div>
      )}

      <div className={"info-card__body"}>
        <div className={"info-card__content"}>{children}</div>

        {(actionSection || editMode) && !title && (
          <div
            className={classNames("info-card__menu", {
              ["info-card__menu_absolute"]: absolute,
            })}
          >
            {editMode && (
              <>
                {editBtn || (
                  <IconButton
                    name={"edit"}
                    onClick={handleEdit}
                    bg
                    innerRef={editInnerRef}
                  />
                )}
              </>
            )}

            {actionSection}
          </div>
        )}
      </div>
    </div>
  )
}

InfoCard.propTypes = {
  title: PropTypes.any,
  editMode: PropTypes.bool,
  children: PropTypes.any,
  onEdit: PropTypes.func,
  className: PropTypes.string,
  editBtn: PropTypes.any,
  addMode: PropTypes.bool,
  addBtn: PropTypes.any,
  onAdd: PropTypes.func,
  absolute: PropTypes.bool,
  actionSection: PropTypes.node,
  editInnerRef: PropTypes.any,
}
