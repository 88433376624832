import React from "react"
import { appTourState } from "features/app-tour/store"
import { useDispatch, useSelector } from "react-redux"

export const useAppTourState = () => {
  const dispatch = useDispatch()

  const state = useSelector(state => state.appTour.state)

  const startTour = ({ alias }) => {
    dispatch(appTourState.startTour({ alias }))
  }

  const finishTour = () => {
    dispatch(appTourState.finishTour({}))
  }

  return {
    state,
    startTour,
    finishTour,
  }
}
