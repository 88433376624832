import * as Yup from "yup"

import { ValidationsUtils } from "utils"
import { FILE_MAX_SIZE } from "consts"

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

export const validationSchema = Yup.object().shape({
  logo: Yup.mixed()
    .nullable()
    .test("maxSize", "field.error.file-size.max", value =>
      ValidationsUtils.fileMaxSize(value, FILE_MAX_SIZE)
    ),
  contact_information: Yup.string().required("field.error.required"),
  client_information: Yup.string().required("field.error.required"),
  client_vat: Yup.string().required("field.error.required"),
  invoice_date: Yup.date().nullable().required("field.error.required"),
  payment_terms: Yup.string().required("field.error.required"),
  bank_name: Yup.string().required("field.error.required"),
  iban: Yup.string().required("field.error.required"),
  swift: Yup.string().nullable().required("field.error.required"),
  currency: Yup.string().nullable().required("field.error.required"),
  vat_percent: Yup.number()
    .min(PERCENT_VALUES.min, "field.error.percent.between")
    .max(PERCENT_VALUES.max, "field.error.percent.between")
    .nullable(true)
    .transform((v, o) => (o === "" ? null : v))
    .required("field.error.required"),
  expiration_date: Yup.date().nullable().required("field.error.required"),
})
