import i18n from "i18n"
import { DATE } from "services"
import { API } from "api"

class LocaleService {
  constructor() {}

  initLocale = t => {
    this.setLocale(i18n.language)
  }

  setLocale = code => {
    API.setLang(code)
    DATE.setLocale(code)
  }

  changeLang = async code => {
    this.setLocale(code)

    await i18n.changeLanguage(code)
  }
}

export const LOCALE = new LocaleService()
