import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { Expenses, AsyncExpenses } from "./Expenses"
import { modelHelper } from "../../../../../helpers"

export const ExpensesContainer = props => {
  const expenseTypeOptions = modelHelper.expenses.getModelExpenseTypeOptions()

  const currency = useSelector(
    state => state.user.profile.data?.settings?.currency
  )

  const onExpensesChange = expenses => {
    props.onModelUpdate(props.model, { expenses: expenses })
  }

  return (
    <>
      {!props.eventId ? (
        <Expenses
          expenses={props?.model?.expenses}
          onChange={onExpensesChange}
          currency={currency}
          expenseTypeOptions={expenseTypeOptions}
        />
      ) : (
        <AsyncExpenses
          eventId={props.eventId}
          modelId={props.model?.id}
          currency={currency}
          expenseTypeOptions={expenseTypeOptions}
        />
      )}
    </>
  )
}

ExpensesContainer.propTypes = {
  model: PropTypes.object,
  eventId: PropTypes.number,
  onModelUpdate: PropTypes.func,
}
