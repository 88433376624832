import React from "react"
import { Row, Col } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"
import { useDeepCompareEffect } from "react-use"

import { Button, ControllerHF, InputField } from "components"

export const CompanyForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      invoice_information: "",
    },
  })

  const onReset = () => {
    const { invoice_information } = props.initialValues

    const formDefaultValues = {
      invoice_information,
    }

    reset(formDefaultValues)
  }

  // set value after fetch data
  useDeepCompareEffect(() => {
    onReset()
  }, [props.initialValues])

  const onSubmit = values => {
    const params = {
      ...values,
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={6}>
          <ControllerHF
            name={`invoice_information`}
            control={control}
            component={InputField}
            id={`invoice_information`}
            label={t("contact_information")}
            placeholder={t("contact_information")}
            disabled={props.isDisabled}
            type={"textarea"}
          />
        </Col>
      </Row>

      <div className="mt-3">
        <div className="button-items">
          <Button
            submit
            title={"btn.save"}
            disabled={props.isDisabled}
            loading={props.loading}
          />
        </div>
      </div>
    </form>
  )
}

CompanyForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
}
