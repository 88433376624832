import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { UploadedPhotos } from "../UploadedPhotos"
import { Drawer } from "components"
import { AvatarManager } from "./AvatartManager"
import { useFashionModelUpdateTourContext } from "features/fashion-model/contexts"
import { FASHION_MODEL_TOUR_MODEL_UPDATE_STEP_ALIAS as TOUR_STEP_ALIAS } from "features/fashion-model/consts/tour"

const EditAvatarComponent = props => {
  const { t } = useTranslation()

  const onDrawerClose = () => {
    props.onClose()
    removeNotCroppedImage()
  }

  const handleCloseDrawer = () => {
    onDrawerClose()

    if (appTourActive) {
      runStep(TOUR_STEP_ALIAS.PROFILE_EDIT)
    }
  }

  const onAvatarSavedSuccess = () => {
    if (appTourActive) {
      handleCloseDrawer()
    }
  }

  const [notCroppedImage, setNotCroppedImage] = useState(null)

  const removeNotCroppedImage = useCallback(() => {
    setNotCroppedImage(null)
  }, [])

  const { appTourActive, runStep } = useFashionModelUpdateTourContext()

  return (
    <React.Fragment>
      <Drawer
        title={t("edit-model-avatar-title")}
        visible={props.visible}
        onClose={handleCloseDrawer}
        placement={"right"}
        className={"drawer-container model-edit-avatar-drawer"}
        destroyOnClose
      >
        <div className="mb-4">
          <AvatarManager
            notCroppedImage={notCroppedImage}
            removeNotCroppedImage={removeNotCroppedImage}
            onSuccess={onAvatarSavedSuccess}
          />
        </div>
        <Row>
          <Col>
            <UploadedPhotos setNotCroppedImage={setNotCroppedImage} />
          </Col>
        </Row>
      </Drawer>
    </React.Fragment>
  )
}

EditAvatarComponent.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,

  model: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  modelPanelUploadModelAvatar: PropTypes.func,
  modelPanelRemoveModelAvatar: PropTypes.func,
  modelPanelUpdateModelAvatarCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { loading, error } = state.modelPanel.general.avatar.update
  return {
    model: data,
    loading,
    error,
  }
}

export const EditAvatar = connect(mapStateToProps)(EditAvatarComponent)
