import React from "react"
import { Row, Col } from "reactstrap"

import { AnalyticsItem } from "./AnalyticsItem"
import { MODELS_CASTINGS, MODELS_EARNINGS, MODELS_JOBS } from "consts"

export const AnalyticsContainer = () => {
  return (
    <Row>
      <Col md={4}>
        <AnalyticsItem alias={MODELS_JOBS} />
      </Col>

      <Col md={4}>
        <AnalyticsItem alias={MODELS_CASTINGS} />
      </Col>

      <Col md={4}>
        <AnalyticsItem alias={MODELS_EARNINGS} />
      </Col>
    </Row>
  )
}
