import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { DateInputField } from "../../../../FormElements"

export const DateSection = props => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <Row>
      <Col md={6}>
        <ControllerHF
          name={"date_start"}
          control={control}
          component={DateInputField}
          id={"date_start"}
          label={t("date_start")}
          placeholder={"DD/MM/YYYY"}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        />
      </Col>

      <Col md={6}>
        <ControllerHF
          name={"date_end"}
          control={control}
          component={DateInputField}
          id={"date_end"}
          label={t("date_end")}
          placeholder={"DD/MM/YYYY"}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          translateParams={{ date: t("date_start") }}
        />
      </Col>
    </Row>
  )
}

DateSection.propTypes = {}
