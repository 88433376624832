import React from "react"
import PropTypes from "prop-types"
import { Alert, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  DateInputField,
  InputField,
  LogoPickerField,
} from "components"
import { validationSchema } from "./validation"
import { FileUtils, NormalizeUtils } from "utils"
import { commonHelper } from "helpers"
import { FILE_MAX_SIZE } from "consts"

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

export const EventInvoiceForm = props => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      contact_information: "",
      client_information: "",
      client_vat: "",
      invoice_date: null,
      payment_terms: "",
      bank_name: "",
      iban: "",
      swift: "",
      currency: null,
      expiration_date: null,
      ...props.initialValues,
      logo: props.initialValues?.logo
        ? { src: props.initialValues.logo }
        : null,
      vat_percent:
        props.initialValues?.vat_percent ||
        props.initialValues?.vat_percent === 0
          ? (props.initialValues?.vat_percent).toString()
          : "22",
    },
    resolver: yupResolver(validationSchema),
  })

  const { control, handleSubmit, setError, reset } = methods

  const onSubmit = values => {
    const { invoice_date, expiration_date, logo, ...restValues } = values

    let params = {
      invoice_date: invoice_date
        ? moment(invoice_date).format("YYYY-MM-DD")
        : "",
      expiration_date: expiration_date
        ? moment(expiration_date).format("YYYY-MM-DD")
        : "",
      ...restValues,
    }

    if (logo) {
      if (logo instanceof File) {
        params.logo = logo
      }
    } else {
      params.logo = ""
    }

    props.onSubmit(params, { reset, setError })
  }

  return (
    <FormProvider {...methods}>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4} className={"d-flex"}>
            <ControllerHF
              name={"logo"}
              control={control}
              component={LogoPickerField}
              selectBtnText={t("btn.add-logo")}
              id={"logo"}
              type={"file"}
              translateParams={{
                text: `${FileUtils.formatBytes(FILE_MAX_SIZE)}`,
              }}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"contact_information"}
              control={control}
              component={InputField}
              id={"contact_information"}
              label={t("contact_information")}
              placeholder={t("contact_information")}
              type={"textarea"}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={"client_information"}
              control={control}
              component={InputField}
              id={"client_information"}
              label={t("client_information")}
              placeholder={t("client_information")}
              type={"textarea"}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={"client_vat"}
              control={control}
              component={InputField}
              id={"client_vat"}
              label={t("client_vat")}
              placeholder={t("client_vat")}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"invoice_date"}
              control={control}
              component={DateInputField}
              id={"invoice_date"}
              label={t("invoice_date")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"payment_terms"}
              control={control}
              component={InputField}
              id={"payment_terms"}
              label={t("payment_terms")}
              placeholder={t("payment_terms")}
              type={"textarea"}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={"bank_name"}
              control={control}
              component={InputField}
              id={"bank_name"}
              label={t("bank_name")}
              placeholder={t("bank_name")}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={"iban"}
              control={control}
              component={InputField}
              id={"iban"}
              label={t("iban")}
              placeholder={t("iban")}
            />
          </Col>

          <Col md={6}>
            <ControllerHF
              name={"swift"}
              control={control}
              component={InputField}
              id={"swift"}
              label={t("swift")}
              placeholder={t("swift")}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"currency"}
              control={control}
              component={ComboboxFormField}
              id={"currency"}
              label={t("currency")}
              placeholder={t("currency")}
              options={commonHelper.currency.getCurrencies()}
              getOptionLabel={option => option.label.toUpperCase()}
              normalize={option => (option ? option.value : null)}
              isDetermineValue
              selectProps={{
                isSearchable: false,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"vat_percent"}
              control={control}
              component={InputField}
              id={"vat_percent"}
              label={t("vat_percent")}
              placeholder={t("vat_percent")}
              normalize={NormalizeUtils.onlyNumbers}
              translateParams={{
                min: PERCENT_VALUES.min,
                max: PERCENT_VALUES.max,
              }}
              appendAccessory={[
                <span key={"%"} className="input-group-text">
                  %
                </span>,
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <ControllerHF
              name={"expiration_date"}
              control={control}
              component={DateInputField}
              id={"expiration_date"}
              label={t("date_expiration")}
              placeholder={"DD/MM/YYYY"}
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
          </Col>
        </Row>

        <div className="mt-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.save"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  )
}

EventInvoiceForm.propTypes = {
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}
