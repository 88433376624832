import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Combobox } from "../../../FormElements"
import { eventsCalendarHelper } from "helpers"

export const EventTypeFilter = props => {
  const { t } = useTranslation()

  const defaultOptions = [
    ...eventsCalendarHelper.eventConfig.getEventsOptions(),
    ...eventsCalendarHelper.eventConfig.getHolidayCategoryEvents(),
  ].map(item => ({
    label: item.title,
    value: item.type,
  }))

  return (
    <div className={"event-type-filter"}>
      <div className={"select2-container"}>
        <Combobox
          id={"event-type-filter"}
          label={t("filter")}
          placeholder={t("filter")}
          options={props.options || defaultOptions}
          value={props.value}
          onChange={props.onChange}
          normalize={option => (option !== null ? option.value : null)}
          isDetermineValue
          isSearchable={false}
          isClearable
        />
      </div>
    </div>
  )
}

EventTypeFilter.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
}
