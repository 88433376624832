import React from "react"
import PropTypes from "prop-types"
import Joyride from "react-joyride"

import s from "./Tour.scss"

export const Tour = ({ ...props }) => {
  return <Joyride {...props} />
}

Tour.propTypes = {}
