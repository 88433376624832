import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Button } from "components"

export const StartTourButton = ({ ...props }) => {
  const { t } = useTranslation()

  return (
    <Button
      color={"secondary"}
      outline
      btnClass={"text-nowrap"}
      title={t("start_tour")}
      size={"btn-sm"}
      {...props}
    />
  )
}

StartTourButton.propTypes = {
  onClick: PropTypes.func,
}
