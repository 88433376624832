import { combineReducers } from "redux"

import linking from "./linking/reducers"
import rate from "./rate/reducers"
import expense from "./expense/reducers"
import attachment from "./attachment/reducers"
import data from "./data/reducers"

const modelReducer = combineReducers({
  linking,
  rate,
  expense,
  attachment,
  data,
})

export default modelReducer
