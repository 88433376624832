import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { PackagesContainer } from "./components"

const PackagesComponent = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"title.packages"} />

      <div className="page-content">
        <Container fluid>
          <PackagesContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

PackagesComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Packages = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackagesComponent)
