import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { eventsCalendarHelper } from "helpers"

export const CalendarEventCategory = ({ item, innerRef, ...props }) => {
  const eventColors = useSelector(
    state => state.user.options.data.data.event_colors
  )

  const bgColor = useMemo(() => {
    return eventsCalendarHelper.eventConfig.getEventColor(
      eventColors,
      item.type
    )
  }, [eventColors])

  return (
    <div className={"calendar-event-category-col"}>
      <div
        className={classNames("calendar-event-category external-event", {
          [`calendar-event_theme_${item.theme}`]: item.theme,
          [props.draggableSelector]: props.draggableSelector,
        })}
        style={{ backgroundColor: bgColor }}
        data-event-type={item.type}
        draggable
        onDrag={event => props.onDrag(event, item)}
        onClick={() => props.onClick(item)}
        ref={innerRef}
      >
        <i className={"align-middle mr-2 bx bx-plus"} />

        {item.title}
      </div>
    </div>
  )
}

CalendarEventCategory.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  onDrag: PropTypes.func,
  draggableSelector: PropTypes.string,
  innerRef: PropTypes.any,
}
