import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Alert,
  Row,
  Col,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { FormatUtils, NormalizeUtils } from "utils"
import { Button, InputField, ControllerHF, LangPickerField } from "components"
import { ROLE_DIRECTOR } from "consts"
import {
  profileEditGeneralInfo,
  profileEditGeneralInfoCleanState,
} from "store/actions"
import { useLang } from "hooks"

const EditGeneralInfoComponent = ({
  loading,
  error,
  user,
  initialValues,
  profileEditGeneralInfo,
  profileEditGeneralInfoCleanState,
}) => {
  const { t } = useTranslation()

  const isDirector = user?.role === ROLE_DIRECTOR

  const { lang } = useLang()

  useEffect(() => {
    return () => {
      profileEditGeneralInfoCleanState()
    }
  }, [])

  const onSubmit = values => {
    let params = {
      ...values,
    }

    if (values.phone) {
      params.phone = values.phone.replace(/[^0-9]/g, "")
    }

    profileEditGeneralInfo(params)
  }

  const formSchema = isDirector =>
    Yup.object({
      email: Yup.string(),
      name: Yup.string().required("field.error.required"),
      company_name: isDirector
        ? Yup.string().required("field.error.required")
        : Yup.string(),
      company_address: Yup.string().nullable(),
      phone: Yup.string().test("phone", "field.error.invalid", val => {
        if (val && val !== "") {
          return val.toString() !== "+"
        }
        return true
      }),
      department: Yup.string(),
      lang: Yup.string(),
    })

  const methods = useForm({
    defaultValues: {
      ...initialValues,
      lang: initialValues?.lang || lang,
    },
    resolver: yupResolver(formSchema(isDirector)),
  })

  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = methods

  return (
    <Card>
      <CardHeader className={"bg-dark"}>
        <CardTitle className={"text-white mb-0"}>{t("general")}</CardTitle>
      </CardHeader>
      <CardBody className={"bg-neutral"}>
        {error && <Alert color="danger">{error.message}</Alert>}

        <FormProvider {...methods}>
          <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={6}>
                <ControllerHF
                  name={"email"}
                  id={"email"}
                  control={control}
                  component={InputField}
                  label={t("field.label.email")}
                  placeholder={t("field.placeholder.email")}
                  disabled
                />
              </Col>

              <Col lg={6}>
                <ControllerHF
                  name={"name"}
                  id={"name"}
                  control={control}
                  component={InputField}
                  label={t("field.label.fullname")}
                  placeholder={t("field.placeholder.fullname")}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <ControllerHF
                  name={"company_name"}
                  id={"company_name"}
                  control={control}
                  component={InputField}
                  label={t("field.label.company")}
                  placeholder={t("field.placeholder.company")}
                  disabled={!isDirector}
                />
              </Col>

              <Col lg={6}>
                <ControllerHF
                  name={"company_address"}
                  id={"company_address"}
                  control={control}
                  component={InputField}
                  label={t("company_address")}
                  placeholder={t("field.placeholder.company_address")}
                  disabled={!isDirector}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <ControllerHF
                  name={"phone"}
                  id={"phone"}
                  control={control}
                  component={InputField}
                  label={t("field.label.phone")}
                  placeholder={t("field.placeholder.phone")}
                  normalize={NormalizeUtils.phone}
                />
              </Col>

              {!isDirector && (
                <Col lg={6}>
                  <ControllerHF
                    name={"department"}
                    id={"department"}
                    control={control}
                    component={InputField}
                    label={t("department")}
                    placeholder={t("department")}
                    disabled={true}
                  />
                </Col>
              )}
            </Row>

            <Row>
              <Col lg={6}>
                <ControllerHF
                  label={t("language")}
                  name={"lang"}
                  control={control}
                  component={LangPickerField}
                />
              </Col>
            </Row>

            <div className="mt-3">
              <Button
                submit
                title={"save"}
                disabled={isSubmitting || loading}
                loading={loading}
              />
            </div>
          </form>
        </FormProvider>
      </CardBody>
    </Card>
  )
}

EditGeneralInfoComponent.propTypes = {
  user: PropTypes.object,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  profileEditGeneralInfo: PropTypes.func,
  profileEditGeneralInfoCleanState: PropTypes.func,
}

const mapStateToProps = state => {
  const { data } = state.user.profile
  const { loading, error } = state.profile.generalInfo
  return {
    user: data,
    initialValues: {
      email: data?.email || "",
      name: data?.name || "",
      company_name: data?.company?.name || "",
      company_address: data?.company?.address || "",
      phone: FormatUtils.formatPhone(data?.phone) || "",
      department: data?.department?.name || "",
      lang: data?.lang || "",
    },
    loading,
    error,
  }
}

const mapDispatchToProps = {
  profileEditGeneralInfo,
  profileEditGeneralInfoCleanState,
}

export const EditGeneralInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGeneralInfoComponent)
