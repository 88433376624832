import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

import { TourProvider } from "features/tour-ui/contexts"

const ClientCreateTourContext = createContext({
  tour: {},
  targetsRefs: { createRef: null },
  targets: { formTargetRef: null },
  callback: () => {},
  interruptTour: () => {},
  successCompleteTour: data => {},
  appTourActive: false,
  stepComplete: index => {},
  goToStep: index => {},
})

const ClientCreateTourProvider = ({ children, tour, ...props }) => {
  return (
    <TourProvider value={{ ...tour }}>
      <ClientCreateTourContext.Provider value={{ tour, ...props }}>
        {children}
      </ClientCreateTourContext.Provider>
    </TourProvider>
  )
}

ClientCreateTourProvider.propTypes = {
  children: PropTypes.any,
  tour: PropTypes.any,
}

const useClientCreateTourContext = () => useContext(ClientCreateTourContext)

export { ClientCreateTourProvider, useClientCreateTourContext }
