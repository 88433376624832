import React, { createContext, useContext, useState } from "react"
import PropTypes from "prop-types"

const defaultOptions = {
  sidebar: true,
}

const PagePanelLayoutContext = createContext({
  options: defaultOptions,
  setOptions: () => {},
  onChangeOptions: data => {},
  resetOptions: () => {},
})

const PagePanelLayoutProvider = ({ children, initialOptions }) => {
  const initialOptionsValues = {
    ...defaultOptions,
    ...initialOptions,
  }

  const [options, setOptions] = useState(initialOptionsValues)

  const onChangeOptions = data => {
    setOptions(prevState => ({ ...prevState, ...data }))
  }

  const resetOptions = () => {
    setOptions(initialOptionsValues)
  }

  return (
    <PagePanelLayoutContext.Provider
      value={{ options, setOptions, onChangeOptions, resetOptions }}
    >
      {children}
    </PagePanelLayoutContext.Provider>
  )
}

PagePanelLayoutProvider.propTypes = {
  children: PropTypes.any,
  initialOptions: PropTypes.object,
}

const usePagePanelLayoutContext = () => useContext(PagePanelLayoutContext)

export { PagePanelLayoutProvider, usePagePanelLayoutContext }
