import React from "react"
import PropTypes from "prop-types"

import { SharedEntityDrawer } from "./SharedEntityDrawer"
import { SendSharedEntity } from "./SendSharedEntity"
import { BOOK, PACKAGE } from "./consts"

export const SharedEntity = props => {
  return (
    <SharedEntityDrawer
      entity={props.entity}
      visible={props.visible}
      onClose={props.onCancel}
    >
      <SendSharedEntity
        entity={props.entity}
        entityId={props.entityId}
        onSuccess={props.onSuccess}
        onCancel={props.onCancel}
      />
    </SharedEntityDrawer>
  )
}

SharedEntity.propTypes = {
  entity: PropTypes.oneOf([BOOK, PACKAGE]).isRequired,
  entityId: PropTypes.number,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
}
