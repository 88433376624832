import React, { useEffect, useMemo, useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { ACTIONS, EVENTS } from "react-joyride"

import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"
import { useAppTourState } from "features/app-tour/hooks"
import { useTour } from "features/tour-ui/hooks"
import { useTargets } from "./useTargets"

const { FASHION_MODEL_COMPCARD } = APP_TOUR_ALIAS
const alias = FASHION_MODEL_COMPCARD

export const useFashionModelCompcardTour = ({}) => {
  const { t } = useTranslation()

  const [stepsState, setStepState] = useState({
    step1: false,
  })

  const onStepsStateUpdate = (data = {}) => {
    setStepState(prevState => ({
      ...prevState,
      ...data,
    }))
  }

  const { finishTour, state: appTourState } = useAppTourState()

  const onFinishAppTour = () => {
    finishTour()
  }

  const tour = useTour({
    onFinished: onFinishAppTour,
    onSkipped: onFinishAppTour,
  })

  const { initTour, onSetSteps, state, onRun, onStop, onStepIndexChange } = tour

  const { stepIndex } = state

  const appTourActive = useMemo(() => {
    return appTourState.active && appTourState.alias === alias
  }, [appTourState.active, appTourState.alias])

  const { targets, targetsRefs } = useTargets({
    appTourActive,
    onStepsStateUpdate,
  })

  const callback = (data = {}) => {
    tour.handleCallback(data)

    const { action, type } = data

    const stepsLength = state.steps.length

    if ([EVENTS.STEP_AFTER].includes(type) && action === ACTIONS.NEXT) {
      if (stepIndex !== stepsLength - 1) {
        const nextStepIndex = stepIndex + 1

        onStepIndexChange(nextStepIndex)
      }
    } else if (action === ACTIONS.CLOSE && stepIndex === stepsLength - 1) {
      successCompleteTour()
    }
  }

  const defaultStepOptions = {
    title: t("compcard"),
    disableBeacon: true,
    disableOverlayClose: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    hideBackButton: true,
    hideNextButton: true,
    hideCloseButton: true,
    placementBeacon: "left",
  }

  const getInitialSteps = useCallback(() => {
    if (appTourActive) {
      return [
        {
          ...defaultStepOptions,
          target: targets.compcardAddTargetRef.current || "body",
          content: t("app_tour.model_compcard.add.content"),
          hideNextButton: false,
        },
        {
          ...defaultStepOptions,
          target: "body",
          content: t("finished"),
          placement: "center",
          hideCloseButton: false,
        },
      ]
    }
  }, [appTourActive, stepsState])

  // Init tour
  useEffect(() => {
    if (appTourActive && stepsState.step1) {
      initTour({
        steps: getInitialSteps(),
      })
    }
  }, [appTourActive, stepsState.step1])

  useEffect(() => {
    return () => {
      if (appTourActive) {
        interruptTour()
      }
    }
  }, [appTourActive])

  const interruptTour = () => {
    tour.completeTour()

    onFinishAppTour()
  }

  const successCompleteTour = () => {
    tour.completeTour()

    onFinishAppTour()
  }

  const stepComplete = index => {}

  const runStep = index => {
    if (appTourActive) {
    }
  }

  return {
    tour,
    targetsRefs,
    targets,
    callback,
    interruptTour,
    successCompleteTour,
    appTourActive,
    stepComplete,
    runStep,
  }
}

useFashionModelCompcardTour.propTypes = {}
