import {
    PACKAGE_DATA_PICKER_SET_VALUE,
    PACKAGE_DATA_PICKER_CLEAN_VALUE,
    PACKAGE_DATA_PICKER_TOGGLE_BOOK,
    PACKAGE_DATA_PICKER_REMOVE_BOOK,
    PACKAGE_DATA_PICKER_TOGGLE_VIDEO,
    PACKAGE_DATA_PICKER_REMOVE_VIDEO,
} from './actionTypes';

export const packageDataPickerSetValue = (books, videos) => {
    return {
        type: PACKAGE_DATA_PICKER_SET_VALUE,
        payload: { books, videos },
    }
};

export const packageDataPickerCleanValue = () => {
    return {
        type: PACKAGE_DATA_PICKER_CLEAN_VALUE,
    }
};

export const packageDataPickerToggleBook = (data) => {
    return {
        type: PACKAGE_DATA_PICKER_TOGGLE_BOOK,
        payload: data,
    }
};

export const packageDataPickerRemoveBook = (id) => {
    return {
        type: PACKAGE_DATA_PICKER_REMOVE_BOOK,
        payload: { id },
    }
};

export const packageDataPickerToggleVideo = (data) => {
    return {
        type: PACKAGE_DATA_PICKER_TOGGLE_VIDEO,
        payload: data,
    }
};

export const packageDataPickerRemoveVideo = (id) => {
    return {
        type: PACKAGE_DATA_PICKER_REMOVE_VIDEO,
        payload: { id },
    }
};