import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { ControllerHF, DateInputField } from "components"

export const FromToSection = props => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col md={4}>
        <ControllerHF
          name={"workdays_from"}
          control={props.control}
          component={DateInputField}
          id={"workdays_from"}
          label={t("from")}
          placeholder={"DD/MM/YYYY"}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        />
      </Col>
      <Col md={4}>
        <ControllerHF
          name={"workdays_to"}
          control={props.control}
          component={DateInputField}
          id={"workdays_to"}
          label={t("to")}
          placeholder={"DD/MM/YYYY"}
          mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          translateParams={{ date: t("from") }}
        />
      </Col>
    </Row>
  )
}

FromToSection.propTypes = {
  control: PropTypes.object,
}
