import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import i18n from "i18n"
import { FETCH_USER } from "./actionTypes"
import { fetchUserSuccess, fetchUserError } from "./actions"
import { userProfileSetData } from "../profile/actions"
import { API } from "api"
import { ROLE_ADMIN } from "consts"
import { logoutUser } from "store/auth/logout/actions"
import toastr from "toastr"
import { LOCALE } from "services"

export function* fetchUser() {
  try {
    const includes = ["company", "department", "settings"]

    const response = yield call(API.profile.getProfile, { includes })

    const { data } = response
    const { role } = data.data
    if (role === ROLE_ADMIN) {
      yield put(logoutUser({}))
      toastr.error(i18n.t("access.only-for-user"))
      return
    }
    yield put(fetchUserSuccess())

    yield put(userProfileSetData(data?.data))

    const { lang } = data.data
    if (i18n.language !== lang && lang) {
      LOCALE.changeLang(lang)
    }

    return data?.data
  } catch (error) {
    const message = error.response?.data?.message
      ? error.response.data.message
      : i18n.t("error")
    yield put(fetchUserError(message))

    throw error
  }
}

export function* watchUserFetch() {
  yield takeEvery(FETCH_USER, fetchUser)
}

function* loadSaga() {
  yield all([fork(watchUserFetch)])
}

export default loadSaga
