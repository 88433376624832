import React from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  Button,
  ComboboxFormField,
  ControllerHF,
  InputField,
  EditorField,
  CheckboxField,
} from "../../index"
import { RecipientsField } from "./RecipientsField"
import { validationSchema } from "./validation"
import { commonHelper } from "../../../helpers"
import { EditorUtils } from "../../../utils"
import { EDITOR_DRAFT_COMMON_TOOLBAR_OPTIONS } from "../../../consts"

const BOOK = "book"
const PACKAGE = "package"

export const SendDataEmailsForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset, watch } = useForm({
    defaultValues: {
      subject: "",
      // notify: null,
      message: EditorUtils.draftJs.getEditorState(""),
      signatureStatus: false,
      signature: "",
      recipients: [],
    },
    resolver: yupResolver(validationSchema),
  })

  const watchSignatureStatus = watch("signatureStatus")

  const onSubmit = values => {
    const { subject, message, signatureStatus, signature, recipients } = values
    let params = {
      subject,
      recipients: recipients.map(item => {
        const { value: email, emailable } = item
        return {
          email,
          id: emailable?.owner?.id || null,
          type: emailable?.owner?.type || null,
        }
      }),
    }

    if (message && !EditorUtils.draftJs.isContentEmpty(message)) {
      params.message = EditorUtils.draftJs.editorStateToHtml(message)
    } else {
      params.message = null
    }

    if (props.entity === PACKAGE) {
      params.notify = values.notify
    }

    if (signatureStatus) {
      params.signature = signature
    }

    props.onSubmit(params, { reset, setError })
  }

  const notifyOptionEntity =
    props.entity === BOOK ? t("book-lc") : t("package-lc")

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4}>
            <ControllerHF
              name={"subject"}
              control={control}
              component={InputField}
              id={"subject"}
              label={t("subject")}
              placeholder={t("subject")}
            />
          </Col>

          {props.entity === PACKAGE && (
            <Col md={4}>
              <ControllerHF
                defaultValue={null}
                name={"notify"}
                control={control}
                component={ComboboxFormField}
                id={"notify"}
                label={t("notify")}
                placeholder={t("notify")}
                options={commonHelper.notify.getEmailNotifyOptions(
                  notifyOptionEntity
                )}
                selectProps={{
                  isSearchable: false,
                  isClearable: true,
                }}
                normalize={option => (option ? option.value : null)}
                isDetermineValue
              />
            </Col>
          )}
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"message"}
              control={control}
              component={EditorField}
              id={"message"}
              label={t("message")}
              placeholder={t("message")}
              toolbar={{
                options: EDITOR_DRAFT_COMMON_TOOLBAR_OPTIONS,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <ControllerHF
              name={"signatureStatus"}
              control={control}
              component={CheckboxField}
              id={"signatureStatus"}
              label={t("signature")}
              wrapClass={"form-checkbox-control"}
              size={"lg"}
            />
          </Col>

          <Col md={8}>
            <ControllerHF
              name={"signature"}
              control={control}
              component={InputField}
              id={"signature"}
              placeholder={t("signature")}
              type={"textarea"}
              disabled={!watchSignatureStatus}
            />
          </Col>
        </Row>

        <hr className="drawer-separator drawer-separator_form" />

        <Row>
          <Col>
            <ControllerHF
              name={"recipients"}
              control={control}
              component={RecipientsField}
              id={"recipients"}
              placeholder={t("recipients")}
              limit={20}
            />
          </Col>
        </Row>

        <div className="mt-3 mb-3">
          <div className="button-items">
            <Button
              submit
              title={"btn.send"}
              disabled={props.loading}
              loading={props.loading}
            />

            <Button
              title={"cancel"}
              color="light"
              outline
              onClick={props.onCancel}
            />
          </div>
        </div>
      </form>
    </>
  )
}

SendDataEmailsForm.propTypes = {
  entity: PropTypes.oneOf([BOOK, PACKAGE]).isRequired,
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,

  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}
