import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { UploadPhoto } from "./UploadPhoto"
import { useTranslation } from "react-i18next"
import { nanoid } from "@reduxjs/toolkit"
import { MAX_FILE_COUNT, UPLOAD_FILE_TYPE } from "consts"
import toastr from "toastr"
import { multipleUpload } from "store"

const AddPhotosComponent = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    return () => {
      dispatch(multipleUpload.cleanState())
    }
  }, [])

  const handleUpload = fileList => {
    //Handle max file count error
    // if (fileList.length > MAX_FILE_COUNT) {
    //   toastr.error(t(`files-count.error`, { max_count: MAX_FILE_COUNT }))
    //   return
    // }
    //Prepare payload object for each file id, params for request, fileConfig
    let fileListWithPayload = fileList.map(file => {
      let customId = nanoid()
      return {
        id: customId,
        params: {
          id: props.model.id,
          photo: file,
        },
        fileConfig: {
          name: file.name,
          size: file.size,
          id: customId,
        },
      }
    })
    dispatch(
      multipleUpload.addFiles({
        filesType: UPLOAD_FILE_TYPE.MODEL_PHOTO,
        filesList: fileListWithPayload,
        onSuccess: handleSuccess,
      })
    )
  }

  const handleSuccess = () => {
    props.onAddSuccess()
  }

  return (
    <div ref={props.innerRef}>
      <UploadPhoto loading={props.filesUploading} onUpload={handleUpload} />
    </div>
  )
}

AddPhotosComponent.propTypes = {
  filesUploading: PropTypes.bool,
  model: PropTypes.object,
  onAddSuccess: PropTypes.func,
  innerRef: PropTypes.any,
}

const mapStateToProps = state => {
  const { data } = state.model.profile
  const { filesUploading } = state.multipleUpload
  return {
    model: data,
    filesUploading,
  }
}

export const AddPhotos = connect(mapStateToProps)(AddPhotosComponent)
