import React from "react"
import PropTypes from "prop-types"
import { BlankLayoutHeader, BlankLayout } from "components"

const CommonLayoutComponent = props => {
  return (
    <>
      <BlankLayout header={<BlankLayoutHeader />}>{props.children}</BlankLayout>
    </>
  )
}

CommonLayoutComponent.propTypes = {
  children: PropTypes.object,
}

export const CommonLayout = CommonLayoutComponent
