import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { eventsCalendarHelper } from "helpers"
import { useRefCallback } from "hooks"

const eventsOptions = eventsCalendarHelper.eventConfig.getEventsOptions()

const eventsInitialTargetsRef = eventsOptions.reduce((acc, cur) => {
  const { type } = cur
  return { ...acc, [type]: null }
}, {})

export const useTargets = ({ appTourActive }) => {
  const { t } = useTranslation()

  // Events buttons (toolbar buttons)
  const eventsTypesControlsTargetsRef = useRef(eventsInitialTargetsRef)
  const eventsTypesControlsRef = useRefCallback(
    (node, type) => {
      eventsTypesControlsTargetsRef.current = {
        ...eventsTypesControlsTargetsRef.current,
        [type]: node,
      }
    },
    [appTourActive]
  )

  // Events forms
  const eventsTypesFormsTargetsRef = useRef(eventsInitialTargetsRef)
  const eventsTypesFormsRef = useRefCallback(
    (node, type) => {
      eventsTypesFormsTargetsRef.current = {
        ...eventsTypesFormsTargetsRef.current,
        [type]: node,
      }
    },
    [appTourActive]
  )

  return {
    targetsRefs: {
      eventsTypesControlsRef,
      eventsTypesFormsRef,
    },
    targets: {
      eventsTypesControlsTargetsRef,
      eventsTypesFormsTargetsRef,
    },
  }
}

useTargets.propTypes = {
  appTourActive: PropTypes.bool,
}
