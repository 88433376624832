import React from "react"
import { useTranslation } from "react-i18next"
import { useLang } from "hooks"
import { LangPicker } from "../LangPicker"
import PropTypes from "prop-types"

export const AppLangPicker = ({ ...props }) => {
  const { changeLang } = useLang()
  const { i18n } = useTranslation()

  const currentLang = i18n.language

  const onChange = async code => {
    await changeLang(code)
  }

  return <LangPicker value={currentLang} onChange={onChange} {...props} />
}

AppLangPicker.propTypes = {
  className: PropTypes.string,
  btnProps: PropTypes.any,
  dropdownMenuProps: PropTypes.any,
}
