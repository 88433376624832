import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import { packageModelsListChangeFilter } from "../../../../../store/actions"
import {
  CheckboxGroup,
  GenderTabsNav,
  SearchInput,
} from "../../../../../components"
import { NormalizeUtils } from "../../../../../utils"
import { GENDER_FEMALE, GENDER_MALE } from "../../../../../consts"

const tabs = [
  {
    label: "female",
    value: [GENDER_FEMALE],
  },
  {
    label: "male",
    value: [GENDER_MALE],
  },
  {
    label: "all",
    value: [GENDER_FEMALE, GENDER_MALE],
  },
]

const PackageDataFiltersComponent = props => {
  const { t } = useTranslation()

  const isInTownOptions = [
    {
      label: (
        <span className={"text-secondary"}>{t("in-town.status.in")}</span>
      ),
      value: true,
      color: "secondary",
    },
    {
      label: (
        <span className={"text-danger"}>{t("in-town.status.out")}</span>
      ),
      value: false,
      color: "secondary",
    },
  ]

  const [searchInputValue, setSearchInputValue] = useState("")

  const onSearchInputChange = value => {
    setSearchInputValue(value)
  }

  const handleSearch = value => {
    props.packageModelsListChangeFilter({ keywords: value })
  }

  const onTabChange = value => {
    props.packageModelsListChangeFilter({ genders: value })
  }

  const inTownChange = value => {
    props.packageModelsListChangeFilter({ is_in_town: value })
  }

  return (
    <>
      <div className={""}>
        <SearchInput
          value={searchInputValue}
          onInputChange={onSearchInputChange}
          onSearch={handleSearch}
          placeholder={t("start_typing")}
        />
      </div>

      <div className={"mt-3"}>
        <GenderTabsNav
          tabs={tabs}
          activeTab={props.filters.values.genders}
          onTabChange={onTabChange}
        />
      </div>

      <div className="my-3">
        <CheckboxGroup
          id={"is_in_town"}
          onChange={inTownChange}
          fieldValue={props.filters.values.is_in_town || []}
          options={isInTownOptions}
          normalize={NormalizeUtils.stringBoolToBoolean}
          className={"d-flex form-checkbox-group_horizontal"}
        />
      </div>
    </>
  )
}

PackageDataFiltersComponent.propTypes = {
  filters: PropTypes.object,

  packageModelsListChangeFilter: PropTypes.func,
}

const mapStateToProps = state => {
  const { filters } = state.package.models.list.data
  return {
    filters,
  }
}

const mapDispatchToProps = {
  packageModelsListChangeFilter,
}

export const PackageDataFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageDataFiltersComponent)
