import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { ComboboxFormField, InputField } from "../../../../FormElements"
import { NormalizeUtils } from "../../../../../utils"
import { modelHelper } from "../../../../../helpers"
import { RATE_PERIOD_TYPE_FLAT } from "../../../../../consts"

const PERCENT_VALUES = {
  min: 0,
  max: 100,
}

export const RateSection = props => {
  const { t } = useTranslation()

  const { control, setValue, getValues, watch, trigger } = useFormContext()

  const watchPeriod = watch("period")

  const onInputNumberBlur = (field, value, defaultValue) => {
    // input for calculation cannot be empty
    if (!value || value === "") {
      setValue(field, defaultValue || "0")
    }
    trigger(field)
  }

  const onPercentBlur = (field, value) => {
    // input for calculation cannot be empty and has correct value
    const minValue = PERCENT_VALUES.min.toString()

    if (value) {
      value = Number(value)
      if (value < PERCENT_VALUES.min || value > PERCENT_VALUES.max) {
        setValue(field, minValue)
      }
    } else {
      setValue(field, minValue)
    }

    trigger(field)
  }

  const onPeriodTypeChange = value => {
    setValue("period", value)
    if (value === RATE_PERIOD_TYPE_FLAT) {
      setValue("count", "1")

      updateCalculatedFields()
    }
  }

  const updateCalculatedFields = () => {
    const { value, count, model_commission } = getValues()
    const values = {
      value,
      count,
      model_commission,
    }
    const { total_fee, net_for_model } =
      modelHelper.rate.getRateCalculatedData(values)

    setValue("total_fee", total_fee.toString())
    setValue("net_for_model", net_for_model.toString())
  }

  return (
    <>
      <Row>
        <Col md={5}>
          <ControllerHF
            name={"value"}
            control={control}
            component={InputField}
            id={"value"}
            label={t("rate")}
            placeholder={t("rate")}
            normalize={value => NormalizeUtils.onlyDecimal(value, 2)}
            onBlur={event => {
              onInputNumberBlur("value", event.target.value, "0")
              updateCalculatedFields()
            }}
            translateParams={{
              min: 0,
              max: 999999,
            }}
          />
        </Col>

        <Col md={1}>
          <div
            className={
              "form-control-offset align-items-center justify-content-center"
            }
          >
            <div className={"mt-3"}>X</div>
          </div>
        </Col>

        <Col md={3}>
          <ControllerHF
            name={"count"}
            control={control}
            component={InputField}
            id={"count"}
            label={" "}
            placeholder={""}
            normalize={NormalizeUtils.onlyNumbers}
            disabled={watchPeriod === RATE_PERIOD_TYPE_FLAT}
            onBlur={event => {
              onInputNumberBlur("count", event.target.value, "1")
              updateCalculatedFields()
            }}
            translateParams={{
              min: 1,
              max: 999,
            }}
          />
        </Col>
        <Col md={3}>
          <ControllerHF
            name={"period"}
            control={control}
            component={ComboboxFormField}
            id={"period"}
            label={" "}
            placeholder={""}
            options={modelHelper.rate.getRatePeriodTypes()}
            normalize={option => (option ? option.value : null)}
            isDetermineValue
            selectProps={{
              isSearchable: false,
            }}
            onChange={onPeriodTypeChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={5}>
          <ControllerHF
            name={"total_fee"}
            control={control}
            component={InputField}
            id={"total_fee"}
            label={t("total_fee")}
            placeholder={t("total_fee")}
            // normalize={value => NormalizeUtils.onlyDecimal(value, 2)}
            disabled
          />
        </Col>

        <Col md={4}>
          <ControllerHF
            name={"agency_commission"}
            control={control}
            component={InputField}
            id={"agency_commission"}
            label={`${t("agency_commission")}, %`}
            placeholder={t("agency_commission")}
            normalize={NormalizeUtils.onlyNumbers}
            translateParams={{
              min: PERCENT_VALUES.min,
              max: PERCENT_VALUES.max,
            }}
            onBlur={event => {
              onPercentBlur("agency_commission", event.target.value)
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={5}>
          <ControllerHF
            name={"model_commission"}
            control={control}
            component={InputField}
            id={"model_commission"}
            label={`${t("model_commission")}, %`}
            placeholder={t("model_commission")}
            normalize={NormalizeUtils.onlyNumbers}
            translateParams={{
              min: PERCENT_VALUES.min,
              max: PERCENT_VALUES.max,
            }}
            onBlur={event => {
              onPercentBlur("model_commission", event.target.value)

              updateCalculatedFields()
            }}
          />
        </Col>

        <Col md={4}>
          <ControllerHF
            name={"net_for_model"}
            control={control}
            component={InputField}
            id={"net_for_model"}
            label={t("net_for_model")}
            placeholder={t("net_for_model")}
            // normalize={value => NormalizeUtils.onlyDecimal(value, 2)}
            disabled
          />
        </Col>
      </Row>
    </>
  )
}

RateSection.propTypes = {
  isEdit: PropTypes.bool,
  currency: PropTypes.string,
}
