import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { ROLE_DIRECTOR } from "consts"
import { useLang } from "hooks"

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || ""

const stripePromise = loadStripe(STRIPE_KEY)

const fontsPath =
  "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap'"

export const StripeWrapper = props => {
  const { data: user } = useSelector(state => state.user.profile)

  const fonts = [
    {
      cssSrc: fontsPath,
    },
  ]

  const { lang } = useLang()

  return (
    <>
      {user.role === ROLE_DIRECTOR ? (
        <Elements stripe={stripePromise} options={{ fonts, locale: lang }}>
          {props.children}
        </Elements>
      ) : (
        props.children
      )}
    </>
  )
}

StripeWrapper.propTypes = {
  children: PropTypes.any,
}
