import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Models } from './Models';

export const ModelsContainer = props => {
  const { t } = useTranslation()

  return (
    <>
      <Models data={props.event?.fashion_models} />
    </>
  )
}

ModelsContainer.propTypes = {
  event: PropTypes.object,
}
