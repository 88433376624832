import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useDeepCompareEffect } from "react-use"

import { GeneralEventsCalendar } from "components"
import { GENERAL_CALENDAR_ENTITY } from "consts"
import { EventsCalendarProvider } from "contexts"
import { useEventsCalendar } from "hooks"

const { MODEL: ENTITY_MODEL } = GENERAL_CALENDAR_ENTITY

export const CalendarContainer = props => {
  const { model } = useSelector(state => ({
    model: state.model.profile.data,
  }))

  const calendar = useEventsCalendar({})

  return (
    <EventsCalendarProvider {...calendar}>
      <GeneralEventsCalendar entity={ENTITY_MODEL} entityData={model} />
    </EventsCalendarProvider>
  )
}

CalendarContainer.propTypes = {}
