import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"

import {
  CalendarLayout,
  CalendarContainer,
  AnalyticsContainer,
} from "./components"
import { MegaTagTitle } from "components"
import { useModal } from "hooks"
import { ICalUrlsManager } from "features/ical/components/ICalUrls/ICalUrlsManager"
import { CalendarHeader } from "./components/Panel/Layout"

const CalendarComponent = props => {
  const { modalRef, closeModal, showModal } = useModal()

  return (
    <>
      <CalendarLayout>
        <MegaTagTitle title={"title.calendar"} />

        <div className={"mb-4"}>
          <AnalyticsContainer />
        </div>

        <CalendarHeader onSyncToCalendar={showModal} />
        <div>
          <Row>
            <Col>
              <CalendarContainer />
            </Col>
          </Row>
        </div>
      </CalendarLayout>

      <ICalUrlsManager modalRef={modalRef} onCancel={closeModal} />
    </>
  )
}

CalendarComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Calendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarComponent)
