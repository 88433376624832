import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Button } from "components"

export const Tooltip = ({
  backProps,
  continuous,
  index,
  closeProps,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
}) => {
  const { t } = useTranslation()

  return (
    <div {...tooltipProps} className={"app-tour-tooltip"}>
      <div className={"app-tour-tooltip__body"}>
        {step.title && <h5>{step.title}</h5>}
        {step.content && <div>{step.content}</div>}
      </div>

      <div className={"app-tour-tooltip__footer"}>
        <div className={"d-flex justify-content-between"}>
          <div className="button-items">
            {!isLastStep && (
              <Button {...skipProps} size="btn-sm" title={"skip"} />
            )}

            {!step?.hideCloseButton && (
              <Button {...closeProps} size="btn-sm" title={"close"} />
            )}
          </div>

          <div className={"d-flex justify-content-between"}>
            {index > 0 && !step?.hideBackButton && (
              <Button {...backProps} size="btn-sm" title={"back"} />
            )}

            {!step?.hideNextButton && (
              <Button {...primaryProps} size="btn-sm" title={"next"} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  backProps: PropTypes.any,
  continuous: PropTypes.bool,
  index: PropTypes.number,
  closeProps: PropTypes.any,
  isLastStep: PropTypes.bool,
  primaryProps: PropTypes.any,
  skipProps: PropTypes.any,
  step: PropTypes.any,
  tooltipProps: PropTypes.any,
}
