import React from "react"
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  generatePath,
} from "react-router-dom"

import { Books } from "./Books"
import { BookEdit } from "./BookEdit"
import { BookAdd } from "./BookAdd"
import { BookTourPanel } from "../components/BookComponents"

export const BookIndex = () => {
  let { path } = useRouteMatch()
  const { id } = useParams()

  return (
    <Switch>
      <Route path={`${path}/:bookId/edit`}>
        <BookEdit />
      </Route>
      <BookTourPanel>
        <Route exact path={path}>
          <Books />
        </Route>
        <Route path={`${path}/add`}>
          <BookAdd />
        </Route>
      </BookTourPanel>
      <Route>
        <Redirect to={generatePath(`${path}`, { id: id })} />
      </Route>
    </Switch>
  )
}
