import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Button } from "components"
import { useFashionModelCreateTourContext } from "features/fashion-model/contexts"

export const AddButton = props => {
  const { t } = useTranslation()

  const { targetsRefs, appTourActive, stepComplete } =
    useFashionModelCreateTourContext()

  const onClick = () => {
    if (appTourActive) {
      setTimeout(() => {
        stepComplete(0)
      }, 0)
    }

    props.onAdd()
  }

  return (
    <Button
      color="secondary"
      leftIcon={"bx bx-plus"}
      title={"btn.add-new-model"}
      onClick={onClick}
      innerRef={targetsRefs.createRef}
    />
  )
}

AddButton.propTypes = {
  onAdd: PropTypes.func,
}
