import { all } from "redux-saga/effects"

import dataSaga from "./data/saga"
import socketSaga from "./socket/sagas"

function* userNavigationSaga() {
  yield all([dataSaga(), socketSaga()])
}

export default userNavigationSaga
