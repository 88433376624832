import { takeEvery, fork, put, all } from "redux-saga/effects"

import { panelGeneral } from "./slice"
import {
  panelLoader,
  loadUserCleanState,
  packageDraftResetData,
  userDepartmentPermissionsData,
} from "store/actions"

function* resetState() {
  // clean preparing panel data
  yield put(panelLoader.cleanState({}))

  // clean fetching user state
  yield put(loadUserCleanState())

  // reset draft package data (form and picked books, videos );
  yield put(packageDraftResetData())

  // reset user department permissions state
  yield put(userDepartmentPermissionsData.cleanState({}))
}

export function* watchResetState() {
  yield takeEvery(panelGeneral.resetState, resetState)
}

function* generalSaga() {
  yield all([fork(watchResetState)])
}

export default generalSaga
