import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Col, Alert, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import {
  Button,
  ControllerHF,
  InputField,
  CheckboxField,
} from "../../../../../components"
import { validationSchema } from "./validation"
import { useFashionModelBookTourContext } from "../../../../../features/fashion-model/contexts"

export const BookForm = props => {
  const { t } = useTranslation()

  const { control, handleSubmit, setError, reset } = useForm({
    defaultValues: {
      title: "",
      description: "",
      published: false,
      ...props.initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const isDisabled = props.picked.length === 0

  const onSubmit = values => {
    const params = {
      ...values,
    }

    params.pages = props.picked.map(item => ({
      photo_id: item.photo?.id,
      is_cover: item.is_cover,
    }))

    props.onSubmit(params, { reset, setError })
  }

  const { targetsRefs, targets } = useFashionModelBookTourContext()

  return (
    <>
      {props.error && <Alert color="danger">{props.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div ref={targetsRefs.bookFormFieldsRef}>
          <Row>
            <Col md={8}>
              <ControllerHF
                name={"title"}
                control={control}
                component={InputField}
                id={"title"}
                label={t("title")}
                placeholder={t("title")}
              />
            </Col>

            {/*<Col md={4}>*/}
            {/*    <ControllerHF*/}
            {/*        name={'published'}*/}
            {/*        control={control}*/}
            {/*        component={CheckboxField}*/}
            {/*        id={'published'}*/}
            {/*        label={t('published')}*/}
            {/*        wrapClass={'mb-3 form-checkbox-control'}*/}
            {/*        size={'lg'}*/}
            {/*    />*/}
            {/*</Col>*/}
          </Row>

          <Row>
            <Col md={8}>
              <ControllerHF
                name={"description"}
                control={control}
                component={InputField}
                id={"description"}
                label={t("description")}
                placeholder={t("description")}
                type={"textarea"}
              />
            </Col>
          </Row>
        </div>

        <div className="mt-3">
          <div className="button-items">
            <Button
              innerRef={targets.bookFormSaveTargetRef}
              submit
              title={"btn.save"}
              disabled={props.loading || isDisabled}
              loading={props.loading}
            />
          </div>
        </div>
      </form>
    </>
  )
}

BookForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  picked: PropTypes.array,

  onSubmit: PropTypes.func,
}
