import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { useRefCallback } from "hooks"

export const useTargets = ({ appTourActive, onStepsStateUpdate }) => {
  const { t } = useTranslation()

  const bookAddTargetRef = useRef(null)
  const bookAddRef = useRefCallback(
    node => {
      bookAddTargetRef.current = node

      if (node !== null && appTourActive) {
        onStepsStateUpdate({ step1: true })
      }
    },
    [appTourActive]
  )

  const bookFormFieldsTargetRef = useRef(null)
  const bookFormFieldsRef = useRefCallback(
    node => {
      bookFormFieldsTargetRef.current = node

      if (node !== null && appTourActive) {
        onStepsStateUpdate({ step2: true })
      }
    },
    [appTourActive]
  )

  const bookFormPhotosTargetRef = useRef(null)
  const bookFormSaveTargetRef = useRef(null)
  // === //

  return {
    targetsRefs: {
      bookAddRef,
      bookFormFieldsRef,
    },
    targets: {
      bookAddTargetRef,
      bookFormFieldsTargetRef,
      bookFormPhotosTargetRef,
      bookFormSaveTargetRef,
    },
  }
}

useTargets.propTypes = {
  appTourActive: PropTypes.bool,
  onStepsStateUpdate: PropTypes.func,
}
