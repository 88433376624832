import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Card, CardBody } from "reactstrap"

import { EventsToolbar } from "./EventsToolbar"
import { FullCalendarControl } from "../FullCalendarControl"
import { EventsCalendarEventContentProvider } from "./EventsCalendarEventContentProvider"
import { EventsCalendarDayHeaderContent } from "./EventsCalendarViews"

export const EventsCalendar = ({
  eventsToolbar = true,
  onEventDidMount = null,
  eventOrder = "start, order",
  ...props
}) => {
  const onEventCategoryDrag = (event, item) => {}

  const onEventCategoryClick = item => {
    const eventData = { type: item.type }
    props.onEventCreate({ eventData })
  }

  const onEventClick = info => {
    let { extendedProps } = info.event

    const values = { eventData: extendedProps }
    props.onEventView(values)
  }

  const onDropped = info => {
    const { date } = info
    const draggedEl = info.draggedEl
    const eventType = draggedEl?.dataset?.eventType

    const values = {
      eventData: { type: eventType, date_start: date, date_end: date },
    }
    props.onEventCreate(values)
  }

  return (
    <div
      className={classNames("calendar events-calendar", {
        [props.className]: props.className,
      })}
    >
      {eventsToolbar && (
        <EventsToolbar
          onEventCategoryDrag={onEventCategoryDrag}
          onEventCategoryClick={onEventCategoryClick}
          eventTypeFilterProps={props.eventTypeFilterProps}
          subBarActionBar={props.subBarActionBar}
          eventsTypesControlsRef={props.eventsTypesControlsRef}
        />
      )}

      <Card className={"calendar-card"}>
        <CardBody className={"bg-light"}>
          <FullCalendarControl
            events={props.events}
            datesSet={props.onDatesSet}
            eventClick={onEventClick}
            drop={onDropped} // dropped eternal event (category) without event data
            eventContent={eventInfo =>
              props.renderEventContent ? (
                props.renderEventContent(eventInfo)
              ) : (
                <EventsCalendarEventContentProvider eventInfo={eventInfo} />
              )
            }
            eventOrder={eventOrder}
            eventDidMount={onEventDidMount}
            views={{
              week: {
                dayHeaderContent: info =>
                  props.renderCalendarDayHeaderContent ? (
                    props.renderCalendarDayHeaderContent(info)
                  ) : (
                    <EventsCalendarDayHeaderContent info={info} />
                  ),
              },
            }}
            // eventAdd={onEventAdd}
            // eventChange={onEventChange} // event changed (updated)
            // eventDrop={onEventDrop} // current calendar event moved (drag&drop) to another date/time
            // eventReceive={onEventReceive} // dropped eternal event (category) with event data
          />
        </CardBody>
      </Card>
    </div>
  )
}

EventsCalendar.propTypes = {
  events: PropTypes.array,
  eventsToolbar: PropTypes.bool,
  className: PropTypes.string,
  onDatesSet: PropTypes.func,
  onEventCreate: PropTypes.func,
  onEventView: PropTypes.func,
  onEventDidMount: PropTypes.func,
  renderEventContent: PropTypes.func,
  eventOrder: PropTypes.any,
  eventTypeFilterProps: PropTypes.object,
  subBarActionBar: PropTypes.any,
  renderCalendarDayHeaderContent: PropTypes.func,
  eventsTypesControlsRef: PropTypes.func,
}
