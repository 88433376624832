import React from "react"
import PropTypes from "prop-types"

import { GeneralEventsCalendar } from "components"
import { EventsCalendarProvider } from "contexts"
import { useEventsCalendar } from "hooks"
import { EventsCalendarTourProvider } from "features/events-calendar/contexts"
import { useEventsCalendarTour } from "features/events-calendar/hooks"
import { GeneralTour } from "features/tour-ui/components/Tour"

export const CalendarContainer = props => {
  const calendar = useEventsCalendar({})

  const eventsCalendarTour = useEventsCalendarTour({})

  return (
    <EventsCalendarTourProvider {...eventsCalendarTour}>
      <EventsCalendarProvider {...calendar}>
        <GeneralEventsCalendar />
      </EventsCalendarProvider>

      <GeneralTour
        {...eventsCalendarTour.tour?.state}
        callback={eventsCalendarTour.callback}
        continuous
      />
    </EventsCalendarTourProvider>
  )
}

CalendarContainer.propTypes = {}
