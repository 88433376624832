import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Table } from "reactstrap"
import moment from "moment"
import PropTypes from "prop-types"

import { billingSubscriptionHelper } from "helpers"
import { SubscriptionStatus } from "./SubscriptionStatus"
import { InfoCard } from "components"

const BillingOverviewInfoComponent = ({ subscription }) => {
  const { t } = useTranslation()

  return (
    <InfoCard title={t("information")}>
      <div className="table-responsive">
        <Table className="table mb-0 table-border-bottom">
          <tbody>
            {subscription && (
              <>
                {/*{subscription.tariff?.name && (*/}
                {/*  <tr>*/}
                {/*    <th scope="row">{t("subscription_plan")}:</th>*/}
                {/*    <td>{subscription.tariff?.name}</td>*/}
                {/*  </tr>*/}
                {/*)}*/}

                <tr>
                  <th scope="row" className={"align-middle"}>
                    {t("subscription_status")}:
                  </th>
                  <td>
                    <SubscriptionStatus status={subscription.status} />
                  </td>
                </tr>

                {subscription.tariff?.period && (
                  <tr>
                    <th scope="row">{t("billed_period")}:</th>
                    <td>
                      {
                        billingSubscriptionHelper.period.getConfig(
                          subscription.tariff.period
                        ).label
                      }
                    </td>
                  </tr>
                )}

                {subscription.current_period_ends_at && (
                  <tr>
                    <th scope="row">
                      {t("subscription.current_period_ends_at")}:
                    </th>
                    <td>
                      {moment(subscription.current_period_ends_at).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                  </tr>
                )}

                {subscription.next_payment_at && (
                  <tr>
                    <th scope="row">{t("subscription.next_payment_date")}:</th>
                    <td>
                      {moment(subscription.next_payment_at).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                  </tr>
                )}

                {subscription.ends_at && (
                  <tr>
                    <th scope="row">{t("subscription.ends_date")}:</th>
                    <td>{moment(subscription.ends_at).format("DD/MM/YYYY")}</td>
                  </tr>
                )}

                {subscription.trial_ends_at && (
                  <tr>
                    <th scope="row">{t("subscription.trial_ends_date")}:</th>
                    <td>
                      {moment(subscription.trial_ends_at).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>
      </div>
    </InfoCard>
  )
}

BillingOverviewInfoComponent.propTypes = {
  subscription: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    subscription: state.user.profile?.data?.subscription,
  }
}

const mapDispatchToProps = {}

export const BillingOverviewInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingOverviewInfoComponent)
