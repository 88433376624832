import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export const TextButton = ({
  type,
  loading,
  disabled,
  title,
  titlePostfix = "",
  leftIcon,
  icon,
  size,
  onClick,
  color = "primary",
  submit = false,
  btnClass,
  children,
  innerRef,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <button
      type={type ? type : submit ? "submit" : "button"}
      className={classNames({
        "text-button": true,
        [`text-button_${color}`]: color,
        [btnClass]: btnClass,
      })}
      disabled={disabled}
      onClick={onClick}
      ref={innerRef}
      {...rest}
    >
      {loading && (
        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2" />
      )}

      {leftIcon && (
        <i
          className={classNames("align-middle mr-2", {
            [leftIcon]: leftIcon,
          })}
        />
      )}

      {children ? children : <>{t(title) + ` ${titlePostfix}`}</>}

      {icon && <i className={icon} />}
    </button>
  )
}

TextButton.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  leftIcon: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  submit: PropTypes.bool,
  btnClass: PropTypes.string,
  titlePostfix: PropTypes.string,
  children: PropTypes.node,
  innerRef: PropTypes.any,
}
