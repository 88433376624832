import {
  PROFILE_EDIT_GENERAL_INFO,
  PROFILE_EDIT_GENERAL_INFO_SUCCESS,
  PROFILE_EDIT_GENERAL_INFO_ERROR,
  PROFILE_EDIT_GENERAL_INFO_CLEAN_STATE,
} from "./actionTypes"

export const profileEditGeneralInfo = params => {
  return {
    type: PROFILE_EDIT_GENERAL_INFO,
    payload: { params },
  }
}

export const profileEditGeneralInfoSuccess = () => {
  return {
    type: PROFILE_EDIT_GENERAL_INFO_SUCCESS,
  }
}

export const profileEditGeneralInfoError = data => {
  return {
    type: PROFILE_EDIT_GENERAL_INFO_ERROR,
    payload: data,
  }
}

export const profileEditGeneralInfoCleanState = () => {
  return { type: PROFILE_EDIT_GENERAL_INFO_CLEAN_STATE }
}
