import React from "react"
import { AppLangPicker } from "components"

const HeaderComponent = () => {
  return (
    <div className={"d-flex px-4"}>
      <div className={"d-flex my-2 w-100 justify-content-end"}>
        <AppLangPicker dropdownMenuProps={{ className: "dropdown-menu-end" }} />
      </div>
    </div>
  )
}

export const Header = HeaderComponent
