import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { MegaTagTitle } from "../../components"
import { ModelsPanelContainer } from "./components"

const ModelsComponent = props => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <MegaTagTitle title={"model"} />

      <div className="page-content">
        <Container fluid>
          <ModelsPanelContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

ModelsComponent.propTypes = {}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export const Models = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelsComponent)
