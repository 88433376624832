import React, { createContext, useContext } from "react"
import PropTypes from "prop-types"

import { TourProvider } from "features/tour-ui/contexts"

const FashionModelCreateTourContext = createContext({
  tour: {},
  targetsRefs: { createRef: null, formRef: null },
  callback: () => {},
  interruptTour: () => {},
  successCompleteTour: data => {},
  appTourActive: false,
  stepComplete: index => {},
  goToStep: index => {},
})

const FashionModelCreateTourProvider = ({ children, tour, ...props }) => {
  return (
    <TourProvider value={{ ...tour }}>
      <FashionModelCreateTourContext.Provider value={{ tour, ...props }}>
        {children}
      </FashionModelCreateTourContext.Provider>
    </TourProvider>
  )
}

FashionModelCreateTourProvider.propTypes = {
  children: PropTypes.any,
  tour: PropTypes.any,
}

const useFashionModelCreateTourContext = () =>
  useContext(FashionModelCreateTourContext)

export { FashionModelCreateTourProvider, useFashionModelCreateTourContext }
