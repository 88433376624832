import { CLIENTS, EMPLOYEES, MODELS, OVERALL } from "consts"
import _ from "lodash"

export const hasTab = (graphs, tabName) => {
  return graphs.some(graph => graph.tab === tabName)
}

export const generateRedirectPath = graphs => {
  // if (hasTab(graphs, OVERALL)) return OVERALL
  // if (hasTab(graphs, MODELS)) return MODELS
  // if (hasTab(graphs, CLIENTS)) return CLIENTS
  // if (hasTab(graphs, EMPLOYEES)) return EMPLOYEES

  return MODELS
}

export const hasGraph = (graphs, graphName) => {
  return graphs.some(graph => graph.alias === graphName)
}

export const getGraphConfig = (graphs, graphName) => {
  return graphs.find(graph => graph.alias === graphName)
}

export const formInitialValues = (departments, permissions) => {
  const result = []
  departments.forEach(department => {
    let fieldItem = { value: false, name: department.name, id: department.id }

    const hasPermission = _.find(permissions, { department_id: department.id })

    if (hasPermission) {
      fieldItem = { ...fieldItem, value: true }
    }

    result.push(fieldItem)
  })

  return result
}
