import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import { compcardCreate } from "store/actions"
import { ConfirmPopup, TextButton } from "components"
import { useConfirmModal } from "hooks"
import { FileUtils } from "utils"

const CreateCompcardComponent = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { modalRef, showModal, closeModal, handleConfirm } = useConfirmModal(
    () => {},
    () => {}
  )

  useEffect(() => {
    return () => {
      dispatch(compcardCreate.cleanState())
    }
  }, [])

  const isValid = useMemo(() => {
    return !!props.selected.length
  }, [props.selected])

  const onCreateSuccess = data => {
    if (data?.url) {
      FileUtils.downloadFileByLink(data?.url)
    }
  }

  const onCreate = () => {
    if (isValid) {
      if (!props.loading) {
        const params = {
          pages: props.selected.map(item => ({
            photo_id: item?.id,
          })),
        }

        dispatch(compcardCreate.create({ params, onSuccess: onCreateSuccess }))
      }
    } else {
      showModal()
    }
  }

  return (
    <React.Fragment>
      <TextButton
        color={"secondary"}
        title={"create-compcard"}
        onClick={onCreate}
        innerRef={props.innerRef}
      />

      <ConfirmPopup
        ref={modalRef}
        onConfirm={handleConfirm}
        onClosed={closeModal}
        questionText={"compcard.create.valid-message"}
        confirmText={"ok"}
      />
    </React.Fragment>
  )
}

CreateCompcardComponent.propTypes = {
  selected: PropTypes.array,
  innerRef: PropTypes.any,

  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading } = state.compcard.create

  return {
    loading,
  }
}

const mapDispatchToProps = {}

export const CreateCompcard = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCompcardComponent)
