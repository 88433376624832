import React from 'react'
import PropTypes from "prop-types"
import { useSelector, useDispatch } from 'react-redux';

import {
    packageDataPickerToggleBook,
    packageDataPickerRemoveBook,
    packageDataPickerToggleVideo,
    packageDataPickerRemoveVideo,
} from '../../../store/actions'

export const usePackageDataPicker = (props) => {
    const dispatch = useDispatch()
    const { books, videos } = useSelector((state) => state.package.dataPicker)

    const onBookToggle = (book) => {
        const data = {
            ...book,
        }

        dispatch(packageDataPickerToggleBook(data))
    };

    const onBookRemove = (book) => {
        dispatch(packageDataPickerRemoveBook(book.id))
    };

    const onVideoToggle = (video) => {
        const data = {
            ...video,
        }

        dispatch(packageDataPickerToggleVideo(data))
    };

    const onVideoRemove = (book) => {
        dispatch(packageDataPickerRemoveVideo(book.id))
    };

    return {
        pickedBooks: books,
        pickedVideos: videos,
        onBookToggle,
        onBookRemove,
        onVideoToggle,
        onVideoRemove,
    };
};

usePackageDataPicker.propTypes = {
}