import { APP_TOUR_ALIAS } from "features/app-tour/consts/alias"

export const getTours = () => {
  return [
    {
      alias: APP_TOUR_ALIAS.FASHION_MODEL_CREATE,
      order: 0,
    },
    {
      alias: APP_TOUR_ALIAS.FASHION_MODEL_UPDATE,
      order: 1,
    },
    {
      alias: APP_TOUR_ALIAS.FASHION_MODEL_PHOTO,
      order: 2,
    },
    {
      alias: APP_TOUR_ALIAS.FASHION_MODEL_BOOK,
      order: 3,
    },
    {
      alias: APP_TOUR_ALIAS.FASHION_MODEL_COMPCARD,
      order: 4,
    },
    {
      alias: APP_TOUR_ALIAS.AGENCY,
      order: 5,
    },
    {
      alias: APP_TOUR_ALIAS.CLIENT,
      order: 6,
    },
    {
      alias: APP_TOUR_ALIAS.CALENDAR_EVENT_JOB,
      order: 7,
    },
  ]
}

export const toursMap = getTours().reduce((acc, curr) => {
  const { alias } = curr

  return acc.set(alias, curr)
}, new Map())

export const getAdjacentTours = alias => {
  const tours = getTours()
  const index = tours.findIndex(item => item.alias === alias)

  const prev = index > 0 ? tours[index - 1] : null
  const next = index < tours.length - 1 ? tours[index + 1] : null
  const current = tours[index] || null

  return {
    prev,
    next,
    current,
  }
}
