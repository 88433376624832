import { combineReducers } from "redux"

import list from "./list/slice"
import data from "./data/slice"
import remove from "./remove/slice"
import submit from "./submit/slice"
import model from "./model/reducers"
import files from "./files/reducers"
import edit from "./edit/reducers"
import expense from "./expense/reducers"
import rate from "./rate/reducers"
import sendEmail from "./sendEmail/slice"
import eventsEmailSend from "./eventsEmailSend/slice"
import close from "./close/slice"
import reopen from "./reopen/slice"
import invoice from "./invoice/reducers"

const calendarEventReducer = combineReducers({
  list,
  remove,
  submit,
  data,
  model,
  files,
  edit,
  expense,
  rate,
  sendEmail,
  eventsEmailSend,
  close,
  reopen,
  invoice,
})

export default calendarEventReducer
