import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  active: false,
  alias: null,
}

const appTourStateSlice = createSlice({
  name: "appTourState",
  initialState,
  reducers: {
    startTour(state, action) {
      state.active = true
      state.alias = action.payload.alias
    },
    finishTour(state) {
      state.active = false
      state.alias = null
    },
  },
})
/**
 * @namespace
 * @property {function} startTour
 * @property {function} finishTour
 */
export const appTourState = appTourStateSlice.actions

export default appTourStateSlice.reducer
