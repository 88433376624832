import React from "react"

import { useFetchData } from "hooks"
import { API } from "api"

export const useAnalyticFetch = () => {
  const { fetchData, loading, error, data, clean } = useFetchData()

  const onFetch = async (alias, params) => {
    const request = API.analytics.getGraphData(alias, params)
    await fetchData({
      request,
    })
  }

  return {
    data,
    loading,
    error,
    onFetch,
  }
}
