import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import "@fullcalendar/bootstrap/main.css"

export const FullCalendarControl = ({
  events = [],
  editable = false,
  droppable = true,
  selectable = false,
  initialView = "dayGridWeek",
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <FullCalendar
      ref={props.calendarRef || null}
      plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
      handleWindowResize={true}
      themeSystem="bootstrap"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek,dayGridDay",
      }}
      buttonText={{
        today: t("today"),
        month: t("month"),
        week: t("week"),
        day: t("day"),
      }}
      dayMaxEventRows={true}
      firstDay={1}
      initialView={initialView}
      events={events}
      editable={editable}
      droppable={droppable}
      selectable={selectable}
      {...props}
    />
  )
}

FullCalendarControl.propTypes = {
  events: PropTypes.array,
  editable: PropTypes.bool,
  droppable: PropTypes.bool,
  selectable: PropTypes.bool,
  initialView: PropTypes.string,
  calendarRef: PropTypes.any,
}
