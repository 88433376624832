import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Label } from "reactstrap"
import classNames from "classnames"
import DatePicker from "react-datepicker"
import moment from "moment"
import MaskedInput from "react-text-mask"

import { FieldError } from "../FieldError"

export const DateInputField = ({
  id,
  label,
  placeholder,
  type,
  defaultValue,
  reference,
  children,
  prependSymbol,
  appendAccessory,
  normalize,
  rightAccessory,
  disabled,
  dateFormat = "dd/MM/yyyy",
  timeFormat = "HH:mm",
  mask,
  value,
  meta = {},
  timeCaption,
  ...props
}) => {
  const { touched, error } = meta

  const { t } = useTranslation()
  const showError = Boolean(error)

  const onChange = date => {
    date = normalize ? normalize(date) : date

    if (props.onChange) {
      props.onChange(date)
    }
  }

  const renderByAccessoryType = accessory => {
    if (accessory) {
      if (typeof accessory === "string") {
        return <i className={accessory} />
      } else return accessory
    } else return <></>
  }

  return (
    <div className="mb-3">
      <Label htmlFor={id}>{label}</Label>
      <div
        className={classNames("input-group", {
          "is-invalid": showError,
        })}
      >
        <DatePicker
          customInput={
            mask ? (
              <MaskedInput mask={mask} keepCharPositions={true} guide={true} />
            ) : null
          }
          selected={value ? moment(value).toDate() : value}
          onChange={event => onChange(event)}
          dateFormat={!props.showTimeSelectOnly ? dateFormat : timeFormat}
          timeFormat={timeFormat}
          showTimeSelect={props.showTimeSelect}
          showTimeSelectOnly={props.showTimeSelectOnly}
          className={classNames("form-control", {
            "form-control-right-accessory": rightAccessory,
            "form-control-border-right-round":
              rightAccessory && !appendAccessory,
            "is-invalid": showError,
          })}
          id={id}
          placeholderText={placeholder}
          disabled={disabled}
          autoComplete="off"
          onBlur={props.onBlur}
          timeCaption={props.timeCaption}
          calendarStartDay={1}
        />

        {rightAccessory && renderByAccessoryType(rightAccessory)}

        {appendAccessory && appendAccessory.map((item, index) => item)}
      </div>

      <FieldError
        error={error}
        translateParams={props.translateParams}
        showError={showError}
      />
    </div>
  )
}

DateInputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  reference: PropTypes.any,
  children: PropTypes.any,
  prependSymbol: PropTypes.any,
  appendAccessory: PropTypes.array,
  normalize: PropTypes.func,
  rightAccessory: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  translateParams: PropTypes.object,
  dateFormat: PropTypes.string,
  mask: PropTypes.any,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  meta: PropTypes.object,
  timeFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeCaption: PropTypes.string,
}
