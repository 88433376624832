import { takeEvery, fork, put, all, select, call } from "redux-saga/effects"

import { socketOnChatMessage } from "./actions"
import { chatConversationMessagesList } from "../../../chat/conversation/messages/list/slice"
import { chatConversationData } from "../../../chat/conversation/data/slice"
import { chatUpdateOrCreate } from "../../../chat/chats/list/saga"
import { readChatSubmit } from "../../../chat/conversation/messages/read/saga"
import { userProfileUpdateUnreadMessages } from "../../../user/actions"
import {
  selectConversationGroupChatId,
  selectConversationParticipantId,
} from "../../../chat/conversation/data/selectors"

export const appWindowFocusState = state => state.app.state.windowFocus
export const participantIdState = state =>
  selectConversationParticipantId(state)
export const groupChatIdState = state => selectConversationGroupChatId(state)

function* onChatMessage({ payload: { data } }) {
  const { message, unread_count } = data
  const { chat, user, ...messageData } = message

  const participantId = yield select(participantIdState)
  const groupChatId = yield select(groupChatIdState)

  const isGroup = chat.is_group

  // if message come from current open conversation
  const isCurrentChat =
    (isGroup && chat.id === groupChatId) ||
    (!isGroup && user.id === participantId)

  if (isCurrentChat) {
    // add message to feed
    yield put(chatConversationMessagesList.addMessage(messageData))

    const appWindowFocus = yield select(appWindowFocusState)
    // only if web app is visible (window in focus)
    if (appWindowFocus) {
      // read chat (seen messages)
      yield call(readChatSubmit, {
        payload: { userId: user.id, chatId: chat.id, isGroup },
      })
    } else {
      yield put(chatConversationData.setBackgroundUnreadMessages(true))
    }
  } else {
    // update count all of unread messages (- badge in side menu)
    yield put(userProfileUpdateUnreadMessages(unread_count))
  }

  // update chat list
  const chatListPayload = {
    message: {
      ...messageData,
      chat_id: chat.id,
    },
    chat: {
      ...chat,
      participant: isGroup ? null : user,
    },
    isCurrentChat,
  }
  yield call(chatUpdateOrCreate, { payload: chatListPayload })
}

export function* watchOnChatMessage() {
  yield takeEvery(socketOnChatMessage, onChatMessage)
}

function* messageSaga() {
  yield all([fork(watchOnChatMessage)])
}

export default messageSaga
