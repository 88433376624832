import React, { useCallback } from "react"
import PropTypes from "prop-types"

export const useRefCallback = (cb, deps = []) => {
  return useCallback(
    (node, ...args) => {
      cb(node, ...args)
    },
    [...deps]
  )
}

useRefCallback.propTypes = {}
