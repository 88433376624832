import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { useFormContext } from "react-hook-form"

import { Col, Row } from "reactstrap"
import { ControllerHF } from "../../../../ReactHookForm"
import { DateInputField, InputField } from "../../../../FormElements"

const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
const timeMask = [/\d/, /\d/, ":", /\d/, /\d/]

export const ArrivalSection = ({ place = true }) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  return (
    <>
      <h4 className={"mb-3"}>{t("arrival")}</h4>

      <Row>
        {place && (
          <Col md={4}>
            <ControllerHF
              name={"arrival_place"}
              control={control}
              component={InputField}
              id={"arrival_place"}
              label={t("place")}
              placeholder={t("place")}
            />
          </Col>
        )}

        <Col md={2}>
          <ControllerHF
            name={"arrival_date"}
            control={control}
            component={DateInputField}
            id={"arrival_date"}
            label={t("date")}
            placeholder={"DD/MM/YYYY"}
            mask={dateMask}
            translateParams={{ date: t("departure_date") }}
          />
        </Col>

        <Col md={2}>
          <ControllerHF
            name={"arrival_time"}
            control={control}
            component={DateInputField}
            id={"arrival_time"}
            label={t("hour")}
            placeholder={t("hour")}
            mask={timeMask}
            showTimeSelect
            showTimeSelectOnly
          />
        </Col>
      </Row>
    </>
  )
}

ArrivalSection.propTypes = {
  place: PropTypes.bool,
}
